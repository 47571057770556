import EastIcon from '@mui/icons-material/East'
import {Skeleton} from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import {paddlePricingAtom} from 'atoms/paddle'
import {partyAtom} from 'atoms/party'
import Text from 'components/Text'
import {getPlatformUrl} from 'helpers/party'
import {usePaywall} from 'hooks/usePaywall'
import useTheme from 'hooks/useTheme'
import {useAtomValue} from 'jotai'
import React from 'react'
import {useHistory} from 'react-router-dom'
import styled from 'styled-components'
import Box from './Box'
import Button from './Button'

interface IProps {}

export interface Tier {
  name: string
  price: string
  features: string[]
  discount: boolean
  buttonText: string
  onClick: () => void
}

const PaywallDialog: React.FC<IProps> = () => {
  const theme = useTheme()
  const {isOpen, closePaywall: closeDialog} = usePaywall()

  const handleClose = () => {
    closeDialog()
  }

  return (
    <>
      <Dialog
        PaperProps={{elevation: 6, sx: {borderRadius: 2}}}
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
      >
        <DialogTitle id="alert-dialog-title">
          <Box pad={3}>
            <Box direction="column" align="center" gap={2} style={{textAlign: 'center'}}>
              <Text size="xxlarge" font={theme.font.headings}>
                Unlock Premium Features Today
              </Text>
              <Text size="msmall" font={theme.font.secondaryFont} light>
                {
                  "Get instant access to advanced tools and personalized support. Whether you're exploring or scaling, we’ve got a solution for you!"
                }
              </Text>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent style={{paddingBottom: 64}}>
          <PricingTierWrapper />
        </DialogContent>
      </Dialog>
    </>
  )
}

const PricingTierWrapper = () => {
  const party = useAtomValue(partyAtom)
  const history = useHistory()
  const {closePaywall} = usePaywall()
  const {data: paddlePricing, loading} = useAtomValue(paddlePricingAtom)

  const tiers: Tier[] = [
    {
      name: 'Basic',
      price: 'Free',
      features: [
        'Full Access all sections of the platform',
        'One Consumption point',
        'One External contract (PPA or Green tariff)',
      ],
      discount: false,
      buttonText: 'Continue',
      onClick: () => {
        closePaywall()
      },
    },
    {
      name: 'Pro',
      price: paddlePricing?.proPrice,
      features: [
        'Full support during onboarding',
        'Up to 10 Consumption point',
        '10 External contract (PPA or Green tariff)',
        'Ideal for 10-50 GWh annual energy consumption',
      ],
      discount: true,
      buttonText: 'Subscribe',
      onClick: () => {
        history.push(`${getPlatformUrl(party)}/company/subscription/add`)
        closePaywall()
      },
    },
    {
      name: 'Enterprise',
      price: paddlePricing?.enterprisePrice,
      features: [
        'Full support during onboarding',
        'Multiple External contract (PPA or Green tariff)',
        'Customised reporting',
        'Special offers depending on contract length',
        'Ideal for 51+ GWh annual energy consumption',
      ],
      discount: true,
      buttonText: 'Subscribe',
      onClick: () => {
        history.push(`${getPlatformUrl(party)}/company/subscription/add`)
        closePaywall()
      },
    },
  ]

  return <PricingTiers tiers={tiers} priceIsLoading={loading} />
}

const PricingTier = styled(Box)<{$tierName?: string}>`
  border: ${props => {
    if (props.$tierName) {
      if (props.$tierName === 'Pro') return '1px solid #82fa7a'
      if (props.$tierName === 'Enterprise') return '1px solid #98a2b3'
    }
    return '1px solid #98a2b3'
  }};
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 350px;
  margin: 0 8px;
  color: ${props => {
    if (props.$tierName) {
      if (props.$tierName === 'Enterprise') return '#fff'
    }
    return 'black'
  }};

  background-color: ${props => {
    if (props.$tierName) {
      if (props.$tierName === 'Pro') return `#82fa7a`
      if (props.$tierName === 'Enterprise') return `#121212`
    }
    return 'white'
  }};
`

export const CustomChip = styled(Box)<{$tierName?: string}>`
  border: ${props => {
    if (props.$tierName) {
      if (props.$tierName === 'Pro') return `1px solid #6b706c`
      if (props.$tierName === 'Enterprise') return `1px solid #82fa7a`
    }
    return 'none'
  }};
  border-radius: 10px;
  width: fit-content;
  line-height: 1.5em;
  min-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
`

const UnderlineOnHoverText = styled(Text)`
  &:hover {
    text-decoration: underline;
  }
`

interface PricingTiersProps {
  tiers: Tier[]
  billingCycle?: boolean
  priceIsLoading?: boolean
}

export const PricingTiers: React.FC<PricingTiersProps> = ({tiers, billingCycle = true, priceIsLoading}) => {
  const theme = useTheme()

  return (
    <Box direction="row" justify="center" gap={2}>
      {tiers.map((tier, index) => (
        <PricingTier key={index} $tierName={tier.name}>
          <Box style={{flex: 1}} direction="column" width="100%">
            <Box pad={{top: 4, left: 1, right: 1}}>
              <Box width="100%" margin={{bottom: 2}} justify="center" height="24px">
                {tier.discount && (
                  <CustomChip $tierName={tier.name}>
                    <Text size="small" font={theme.font.secondaryFont} align="center">
                      Save 20% if billed annually
                    </Text>
                  </CustomChip>
                )}
              </Box>
              <Text size="large" font={theme.font.secondaryFont} margin={{bottom: 2}} align="center">
                {tier.name}
              </Text>
              <Text size="xxxlarge" font={theme.font.headings} margin={{bottom: 3}} align="center">
                {tier.price !== 'Free' ? (
                  <Box justify="center" align="flex-end" direction="row">
                    {priceIsLoading ? (
                      <Skeleton
                        variant="rounded"
                        width="40%"
                        height="50px"
                        sx={{backgroundColor: theme.colors.toned}}
                      />
                    ) : (
                      <>
                        {tier.price}
                        {billingCycle && (
                          <Text size="small" as="span">
                            / month
                          </Text>
                        )}
                      </>
                    )}
                  </Box>
                ) : (
                  tier.price
                )}
              </Text>
            </Box>
            <Box width="100%" height="1px" color={tier.name === 'Enterprise' ? '#98a2b3' : '#1F2D49'} />
            <Box style={{padding: '24px 24px 0px 24px'}}>
              <Box style={{flex: 1}}>
                {tier.features.map((feature, featureIndex) => (
                  <Text key={featureIndex} size="msmall" align="center">
                    - {feature}
                  </Text>
                ))}
              </Box>
            </Box>
          </Box>
          <Box style={{paddingBottom: 24}}>
            <Button
              margin={{top: 3}}
              fullWidth
              style={{backgroundColor: tier.name === 'Pro' && '#fff'}}
              onClick={tier.onClick}
              data-cy={`subscribe-${tier.name}`}
            >
              <UnderlineOnHoverText size="medium" font={theme.font.headings} capitalize>
                <Box align="center" gap={1} margin={{left: 1.5}}>
                  {tier.buttonText}
                  <EastIcon />
                </Box>
              </UnderlineOnHoverText>
            </Button>
          </Box>
        </PricingTier>
      ))}
    </Box>
  )
}

export default PaywallDialog
