import React, {useEffect} from 'react'
import InnerScroll from 'components/InnerScroll'
import Page from 'components/Page'
import PortfolioOverviewAside from 'components/Platform/Portfolio/Overview/PortfolioOverviewAside'
import Tabs from 'components/Tabs'
import Tab from 'components/Tabs/Tab'
import useLocalization from 'hooks/useLocalization'
import ContractedVolume from './ContractedVolume'
import GreenCertificates from './GreenCertificates'
import CoverageOverview from './CoverageOverview'
import PerformanceOverview from './PerformanceOverview'
import {resetPeriodAtom} from 'components/PeriodSelect/periodSelect'
import {useSetAtom} from 'jotai'
import {overviewDateFiltermAtom} from 'atoms/portfolioOverview'
import usePeriodUrlParams from 'hooks/usePeriodUrlParams'

const Overview: React.FC<React.PropsWithChildren> = () => {
  const {translate} = useLocalization()
  const setOverviewDateFilter = useSetAtom(overviewDateFiltermAtom)
  const resetPeriod = useSetAtom(resetPeriodAtom)

  usePeriodUrlParams(setOverviewDateFilter)

  useEffect(() => {
    return () => {
      resetPeriod()
    }
  }, [])

  return (
    <Page
      title={translate('Portfolio overview')}
      description={translate('Key facts, Aggregated overview of contracts')}
      aside={<PortfolioOverviewAside />}
      asideBorder
    >
      <InnerScroll noRightPad>
        <Tabs name="tab.overview">
          <Tab label={translate('Energy attribute certificates')} slug="">
            <GreenCertificates />
          </Tab>
          <Tab label={translate('Contracted volumes')} slug="contracted-volumes">
            <ContractedVolume />
          </Tab>
          <Tab label={translate('Performance')} slug="performance">
            <PerformanceOverview />
          </Tab>
          {/* <Tab label={translate('Coverage')} slug="coverage">
            <CoverageOverview />
          </Tab> */}
        </Tabs>
      </InnerScroll>
    </Page>
  )
}

export default Overview
