import {ResourceId} from 'types'
import {CurrencyCode} from './IPrice'
import {OfferState} from './IOffer'

interface Consumption {
  name: string
  value: number
}

interface Product {
  productId: ResourceId
  productName: string
  timeseries: Consumption[]
}

interface SelectedProduct {
  productId: ResourceId
  quantity: number
}

export enum RoundingType {
  UP = 'UP',
  DOWN = 'DOWN',
}

export enum QuantityKind {
  MAX_ALLOWED = 'MAX_ALLOWED',
}

export enum MatchStatus {
  OK = 'OK',
  NoConsumptionForPeriod = 'NO_CONSUMPTION_FOR_PERIOD',
  AnyRoundMaxAllowedTooLow = 'ANY_ROUND_MAX_ALLOWED_TOO_LOW',
  MatchedQuantityTooLow = 'MATCHED_QUANTITY_TOO_LOW',
  RoundDownMaxAllowedTooLow = 'ROUND_DOWN_MAX_ALLOWED_TOO_LOW',
}

export type QuantityType = number | QuantityKind

export interface IProposalTimeseries {
  missing: Consumption[]
  product: Product
  productsTotal?: Consumption[]
}

export interface IProposalDetailsBody {
  year: number
  month: string
  dayOfMonth?: number
  product: SelectedProduct
}

export interface IOfferedVolumesTimeseries {
  missing: Consumption[]
  consumption: Consumption[]
  maxAllowed: Consumption[]
  purchase: Consumption[]
  productAvailableNoOverlap: Consumption[]
  purchaseFractions: Consumption[]
}

export default interface IProposalDetails {
  matchedQuantity: number
  eacPrice: string
  totalPrice: string
  currency: CurrencyCode
  maxAllowedToBuy: number
  matchStatus: MatchStatus
  shortfallQuantity: number
  newShortfallQuantity: number
  skippedQuantity: number
  fullCoverExcessMwh: number
  product: {
    id: number
    name: string
    description: string
  }
  greenCoverage: {
    hourlyPercent: number
    monthlyPercent: number
    yearlyPercent: number
  }
  timeseries: IOfferedVolumesTimeseries
}

type KwhConsumption = {
  date: string
  valueKwh: number
}

type CertConsupmtion = {
  date: string
  certs: number
}

export interface IRawProposalDetails {
  timeseries: {
    missing: KwhConsumption[]
    consumption: KwhConsumption[]
    maxAllowed: CertConsupmtion[]
    purchase: CertConsupmtion[]
    productAvailableNoOverlap: CertConsupmtion[]
    purchaseFractions: KwhConsumption[]
  }
}
