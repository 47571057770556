import React from 'react'
import {getPlatformUrl} from 'helpers/party'
import useLocalization from 'hooks/useLocalization'
import AccountPage from 'components/Platform/Account/AccountPage'
import AddPartyMemberForm from 'components/Platform/Account/AddPartyMemberForm'
import {useAtomValue} from 'jotai'
import {partyAtom} from 'atoms/party'

const AddPartyMember = () => {
  const {translate} = useLocalization()
  const party = useAtomValue(partyAtom)
  const urlPrefix = getPlatformUrl(party)

  if (!party) {
    return null
  }

  return (
    // an empty div aside to make the AddManagedUserForm take only half of the page
    <AccountPage title={translate('Add user')} description={translate('Add user to company')} aside={<div></div>}>
      <AddPartyMemberForm urlPrefix={urlPrefix}></AddPartyMemberForm>
    </AccountPage>
  )
}

export default AddPartyMember
