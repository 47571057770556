import React from 'react'
import Box from 'components/Box'
import TechnologyIconAndName from 'components/Platform/TechnologyIconAndName'
import useLocalization from 'hooks/useLocalization'
import IProduct from 'domain/IProduct'
import KeyFeature from 'components/Platform/KeyFeature'
import ListItem, {Badge} from 'components/ListItem'
import Text from 'components/Text'
import useServices from 'hooks/useServices'
import {DateFormat, formatDate} from 'helpers/date'
import ProductCertProgress from './ProductCertProgress'
import useTheme from 'hooks/useTheme'
import {formatDecimal} from 'helpers/format'
import ICertListing from 'domain/ICertListing'
import {getStringifiedSiteLocation} from 'helpers/site'
import IAttachment from 'domain/IAttachment'

interface IProductDetailsProps {
  site: ICertListing['site']
  image: IAttachment
  quantity: number
  totalQuantity: number
  soldQuantity: number
  title: string
  description: string
}

const ProductDetails: React.FC<IProductDetailsProps> = ({
  title,
  description,
  site,
  image,
  quantity,
  totalQuantity,
  soldQuantity,
}) => {
  const {translate} = useLocalization()
  const theme = useTheme()
  const {contentService} = useServices()
  const operationsStart = site?.operationsStart
  return (
    <Box>
      <ListItem
        to={''}
        noHover
        noPanel
        noRound
        title={title}
        onClick={e => e.preventDefault()}
        description={getStringifiedSiteLocation(site?.location, translate)}
        defaultCoverColor={theme.colors.technology[site?.productionTechnology]}
        coverContent={
          <>
            <Badge>
              <TechnologyIconAndName technology={site?.productionTechnology} />
            </Badge>
          </>
        }
        coverImage={contentService.getAttachmentURL(image)}
      />
      <Box pad={{left: 2}}>
        <Box direction="row" gap={4} pad={{left: 1, top: 1}}>
          <KeyFeature title={translate('Start of operations')} overflow="visible">
            <Text size="large" font={theme.font.numbers}>
              {operationsStart ? formatDate(operationsStart, DateFormat.MONTH_YEAR) : '-'}
            </Text>
          </KeyFeature>
          <KeyFeature title={translate('Total volume for sale')} overflow="visible">
            <Text font={theme.font.numbers} size="large">
              {formatDecimal(totalQuantity, 0)}
            </Text>
          </KeyFeature>
          <KeyFeature title={translate('Description')} overflow="visible">
            <Text size="mlarge" semibold>
              {description}
            </Text>
          </KeyFeature>
        </Box>
        <Box width="40%">
          <ProductCertProgress quantity={quantity} totalSold={soldQuantity} total={totalQuantity} />
        </Box>
      </Box>
    </Box>
  )
}

export default ProductDetails
