import React from 'react'
import {Link} from 'react-router-dom'
import styled from 'styled-components'
import logoutIcon from '../assets/icons/logout.svg'
import ITheme from '../theme/ITheme'
import useLocalization from '../hooks/useLocalization'
import useServices from 'hooks/useServices'
import {PrimaryButton} from './Button'

export const StyledButton = styled(Link)`
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  border-radius: 24px;
  padding-left: 12px;
  padding-right: 16px;
  color: ${props => (props.theme as ITheme).colors.accent};
  line-height: 48px;

  &:before {
    content: '';
    width: 24px;
    height: 24px;
    margin-right: 8px;
    background-image: url('${logoutIcon}');
  }

  &:hover {
    background-color: ${props => (props.theme as ITheme).colors.light1};
  }
`

export const ReloadingLogoutButton: React.FC<React.PropsWithChildren<{className?: string}>> = ({
  children,
  className,
}) => {
  const {authenticationService} = useServices()
  const {translate} = useLocalization()

  function logout() {
    authenticationService
      .signOut()
      .catch(() => null)
      .then(() => window.location.reload())
  }

  return (
    <PrimaryButton className={className} onClick={logout}>
      {children || translate('Log out and reload page')}
    </PrimaryButton>
  )
}

const LogoutButton: React.FC<React.PropsWithChildren<{to?: string}>> = ({to = '/logout', children, ...rest}) => {
  const {translate} = useLocalization()

  return (
    <StyledButton to={to} {...rest}>
      {children || translate('Logout')}
    </StyledButton>
  )
}

export default LogoutButton
