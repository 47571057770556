import {Props as ReactSelectProps, components} from 'react-select'
import {withLabel} from './Label'
import React, {useState} from 'react'
import {ReactSelect} from './MultiSelect'
import useLocalization from '../hooks/useLocalization'
import styled from 'styled-components'
import ITheme from 'theme/ITheme'
import Box from 'components/Box'

export interface IProps extends React.PropsWithChildren, ReactSelectProps {
  error?: string
  customError?: string
  suggestAt?: number
  color?: string
  onInputChange?: (term: string) => void
  isSearch?: boolean
}

const ErrorMessage = styled.div`
  margin-top: -16px;
  display: block;
  padding: 0 4px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  box-sizing: border-box;
  font-family: Poppins, sans-serif;
  color: ${props => (props.theme as ITheme).colors.error};
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
`

function getValue(value, options, isSearch, placeholder, translate) {
  if (!value && placeholder) {
    return
  }

  if (!value && !placeholder) {
    return {label: translate(isSearch ? 'Search..' : 'Select...'), value: ''}
  }

  return (options as any).find(o => o.value.toString() === value.toString())
}

// React-select bug, which sets input value opacity to 0 on value select, this prevents that
const CustomInput = props => <components.Input {...props} isHidden={false} />

const AutocompleteSelect: React.FC<IProps> = React.forwardRef(
  (
    {
      value,
      options,
      onChange,
      suggestAt = 3,
      onInputChange,
      isSearch,
      error,
      customError,
      placeholder,
      defaultInputValue,
      ...props
    },
    ref: React.Ref<any>,
  ) => {
    const [menuOpen, setMenuOpen] = useState<boolean>(false)
    const {translate} = useLocalization()

    return (
      <>
        <ReactSelect
          hideIndicators
          innerRef={ref}
          placeholder={placeholder && placeholder}
          value={getValue(value, options, isSearch, placeholder, translate)}
          onChange={(newValue: any, action) => {
            onChange && onChange(newValue.value, action)
          }}
          menuIsOpen={suggestAt > 0 ? menuOpen : undefined}
          inputValue={isSearch ? defaultInputValue : undefined}
          options={options}
          onFocus={() => {
            if (isSearch && options?.length > 0) {
              setMenuOpen(true)
            }
          }}
          onInputChange={(term, action) => {
            if (action.action === 'input-change') {
              onInputChange && onInputChange(term)
              setMenuOpen(term.length >= suggestAt)
            }

            if (action.action === 'menu-close') {
              setMenuOpen(false)
            }
          }}
          controlShouldRenderValue={false}
          components={{Input: CustomInput}}
          error={error}
          {...props}
        />
        <Box direction="column" gap={2}>
          {isSearch && error && <ErrorMessage data-cy="input-error">{error}</ErrorMessage>}
          {isSearch && customError && <ErrorMessage data-cy="input-error">{customError}</ErrorMessage>}
        </Box>
      </>
    )
  },
)

AutocompleteSelect.displayName = 'AutocompleteSelect'

export default withLabel<IProps>(AutocompleteSelect)
