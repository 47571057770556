import {IBaseContract, ISellerSignedContract} from 'domain/IContract'
import {SiteProductionTechnology} from 'domain/ISite'

export function getContractsVolumeMwhByTechnology(contracts: ISellerSignedContract[]): {
  [key in SiteProductionTechnology]?: number
} {
  const result: {[key in SiteProductionTechnology]?: number} = {}

  contracts?.forEach(contract => {
    const productionTechnology = contract?.site?.productionTechnology

    if (!productionTechnology) {
      return
    }

    if (!result[productionTechnology]) {
      result[productionTechnology] = 0
    }

    result[productionTechnology] += contract.volumeMwh
  })

  return result
}

export function generateContractTitleValue(contract: IBaseContract): string {
  if (contract.name) {
    return `#${contract.id} - ${contract.name}`
  }

  return `#${contract.id}`
}
