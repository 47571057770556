import EditIcon from '@mui/icons-material/EditOutlined'
import {IconButton, Skeleton} from '@mui/material'
import {partyAtom} from 'atoms/party'
import {kpiAtom, overviewDateFiltermAtom} from 'atoms/portfolioOverview'
import Button from 'components/Button'
import Heading from 'components/Heading'
import Text from 'components/Text'
import {ISubscriptionPlan} from 'domain/IParty'
import {IUnit} from 'domain/IPrice'
import {convertMwh, EnergyUnit} from 'helpers/conversion'
import {getCurrentYear} from 'helpers/date'
import {formatDecimal} from 'helpers/format'
import useTheme from 'hooks/useTheme'
import {useAtomValue} from 'jotai'
import {isNil} from 'lodash-es'
import React, {useState} from 'react'
import styled from 'styled-components'
import useLocalization from '../../../../hooks/useLocalization'
import Box from '../../../Box'
import EpsilonIcon from './EpsilonIcon'
import GoalsModal from './GoalsModal'
import IntervalModal from './IntervalModal'
import SummaryRow, {Description, StyledRow, Title, Value} from './SummaryRow'
import {usePaywall} from 'hooks/usePaywall'
import {getCarbonErrorMessage} from 'domain/IKPI'

const Wrapper = styled.div`
  z-index: 1;
`

export enum EditModalType {
  GOALS = 'goals',
  INTERVAL = 'interval',
}

const PortfolioKPISummary: React.FC<React.PropsWithChildren> = () => {
  const {data, loading} = useAtomValue(kpiAtom)
  const [modalType, setModalType] = useState<EditModalType>(undefined)
  const interval = useAtomValue(overviewDateFiltermAtom)
  const {translate} = useLocalization()
  const theme = useTheme()
  const party = useAtomValue(partyAtom)
  const {openPaywall: openDialog} = usePaywall()

  const basicSubscription = party?.subscriptionPlan === ISubscriptionPlan.BASIC

  return (
    <>
      <Wrapper>
        <Box
          direction="column"
          height="64px"
          pad={2}
          color={theme.colors.primary}
          hoverColor={theme.colors.primaryDark}
        >
          <Box direction="row" justify="space-between" width="100%" $maxHeight="21px">
            <Heading color={theme.colors.secondary}>{translate('KPIs')}</Heading>
            {!basicSubscription && (
              <IconButton
                size="small"
                sx={{color: theme.colors.info}}
                onClick={() => setModalType(EditModalType.INTERVAL)}
                data-cy="kpi-interval"
              >
                <EditIcon fontSize="medium" />
              </IconButton>
            )}
          </Box>
          <Text size="small" uppercase color={theme.colors.secondary} data-cy="kpi-interval-text">
            {translate('Performance facts for %s', `${interval.month} ${interval.year}`)}
          </Text>
        </Box>
        <SummaryRow
          loading={loading}
          title={translate('Matching score')}
          firstText={translate('Hourly')}
          secondText={translate('Monthly')}
          thirdText={translate('Yearly')}
          firstVal={formatDecimal(data?.coverage?.hourlyPercent, 0)}
          secondVal={formatDecimal(data?.coverage?.monthlyPercent, 0)}
          thirdVal={formatDecimal(data?.coverage?.yearlyPercent, 0)}
          circularProgress
        />
        <SummaryRow
          editIcon
          loading={loading}
          onEdit={() => setModalType(EditModalType.GOALS)}
          title={translate('Target achievement rate %')}
          firstText={translate('Hourly')}
          secondText={translate('Monthly')}
          thirdText={translate('Yearly')}
          firstVal={formatDecimal(data?.targetAchievingRates?.hourly, 0)}
          secondVal={formatDecimal(data?.targetAchievingRates?.monthly, 0)}
          thirdVal={formatDecimal(data?.targetAchievingRates?.yearly, 0)}
          circularProgress
          showSuccessIcon
          blur={basicSubscription}
        />
        <SummaryRow
          columns={2}
          loading={loading}
          title={translate('Emissions')}
          firstText={translate('Total')}
          secondText={translate('Saved')}
          firstVal={formatDecimal(data?.carbon?.monthEmissions) || '-'}
          secondVal={formatDecimal(data?.carbon?.monthSavedEmissions) || '-'}
          missingText={getCarbonErrorMessage(data?.carbonStatus)}
          unit=" tCO2"
          blur={basicSubscription}
        />
        <Box
          pad={2}
          color={theme.colors.primary}
          hoverColor={theme.colors.primaryDark}
          direction="row"
          justify="space-between"
          align="center"
        >
          <Title columns={2}>{translate('%s totals', getCurrentYear())}</Title>
          <EpsilonIcon />
        </Box>
        <SummaryRow
          columns={2}
          loading={loading}
          title={translate('Emissions')}
          firstText={translate('Total')}
          secondText={translate('Saved')}
          firstVal={formatDecimal(data?.carbon?.yearEmissions) || '-'}
          secondVal={formatDecimal(data?.carbon?.yearSavedEmissions) || '-'}
          unit={` ${IUnit[data?.carbon?.unit] || 'tCO2'}`}
          missingText={getCarbonErrorMessage(data?.carbonStatus)}
          blur={basicSubscription}
        />
        <StyledRow>
          <Box direction="column" pad={{horizontal: 2, top: 2, bottom: 1}}>
            <Box direction="row" justify="space-between">
              <Box>
                {loading ? (
                  <Skeleton variant="rounded" height={21} />
                ) : (
                  <Value>
                    {!isNil(data?.annualConsumptionMwh)
                      ? `${formatDecimal(convertMwh(data?.annualConsumptionMwh, EnergyUnit.GWh))} GWh/y`
                      : '-'}
                  </Value>
                )}
                <Description>{translate('Consumption volume')}</Description>
              </Box>
              <Box>
                {basicSubscription && (
                  <Button small onClick={() => openDialog()} data-cy="subscribe-button">
                    <Text size="small" font={theme.font.secondaryFont} capitalize>
                      {translate('Subscribe to unlock rest')}
                    </Text>
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
        </StyledRow>
      </Wrapper>
      {modalType === EditModalType.INTERVAL && <IntervalModal setModalType={setModalType} />}
      {modalType === EditModalType.GOALS && <GoalsModal setModalType={setModalType} />}
    </>
  )
}

export default PortfolioKPISummary
