import {CertificateTech} from 'domain/IRegoCertificate'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import utc from 'dayjs/plugin/utc'
import {DateFormat, formatDate} from './date'

dayjs.extend(customParseFormat)
dayjs.extend(utc)

export enum CertError {
  INVALID_START_DATE = 'INVALID_START_DATE',
  INVALID_END_DATE = 'INVALID_END_DATE',
  INVALID_CERT_ID = 'INVALID_CERT_ID',
  INVALID_ACC_NUM = 'INVALID_ACC_NUM',
  INVALID_END_DATE_BEFORE_START_DATE = 'INVALID_END_DATE_BEFORE_START_DATE',
  INVALID_LENGTH = 'INVALID_LENGTH',
  INVALID_ACC_NUM_MATCH = 'INVALID_ACC_NUM_MATCH',
}

export const parseCertId = (cert: string): number => {
  return parseInt(cert)
}

export const parseStartDate = (dateInput: string): string => {
  if (dateInput.length !== 6) return '-'

  const year = 2000 + parseInt(dateInput.substring(4, 6))
  const date = dayjs(`${dateInput.substring(0, 2)}/${dateInput.substring(2, 4)}/${year}`, 'DD/MM/YYYY').toDate()

  return formatDate(date, DateFormat.MONTH_DAY_YEAR)
}

export const parseEndDate = (dateInput: string): string => {
  if (dateInput.length !== 6) return '-'

  const year = 2000 + parseInt(dateInput.substring(4, 6))
  const date = dayjs(`${dateInput.substring(0, 2)}/${dateInput.substring(2, 4)}/${year}`, 'DD/MM/YYYY').toDate()

  return formatDate(date, DateFormat.MONTH_DAY_YEAR)
}

export const getCertTechKind = (certificate: string): CertificateTech => {
  if (certificate === null) {
    return null
  }

  const techGroup = certificate.substring(6, 10)

  switch (techGroup) {
    case 'NWSC':
      return CertificateTech.WIND
    case 'PVEN':
      return CertificateTech.SOLAR
    case 'HYDR':
      return CertificateTech.HYDRO
    default:
      return null
  }
}

export const getAccreditationNum = (cert: string): string => {
  return cert.substring(0, 10)
}

export const getId = (cert: string): number => {
  return parseCertId(cert.substring(10, 20))
}

export const getDateAndSuffix = (cert: string): string => {
  return cert.substring(20, 35)
}

export const isValidCertificate = (cert: string): boolean => {
  return getValidationErrMsg(cert, s => s) === null
}

export const getNumCertificates = (firstCertificate: string | null, lastCertificate: string | null): number | null => {
  if (!firstCertificate || !lastCertificate) {
    return null
  }

  if (getValidationErrMsg(firstCertificate, s => s) !== null || getValidationErrMsg(lastCertificate, s => s) !== null) {
    return null
  }

  const first = parseInt(firstCertificate.substring(10, 20))
  const last = parseInt(lastCertificate.substring(10, 20))

  return last - first + 1
}

export const getGeneralNumCertificates = (
  startCertificate: string | null,
  endCertificate: string | null,
): {value: bigint; error: string} => {
  if (startCertificate?.length < 15 || endCertificate?.length < 15) {
    return {value: null, error: 'Invalid certificate'}
  }

  if (startCertificate?.length > 35 || endCertificate?.length > 35) {
    return {value: null, error: 'Invalid certificate'}
  }

  if (!startCertificate || !endCertificate) {
    return {value: null, error: 'Missing certificate'}
  }

  if (endCertificate?.length !== startCertificate?.length) {
    return {value: null, error: 'Invalid certificate'}
  }

  const start = BigInt(startCertificate)
  const end = BigInt(endCertificate)

  const result = end - start + BigInt(1)

  if (result < 0) {
    return {value: null, error: 'Start certificate can not be greater than end certificate'}
  }

  return {value: BigInt(result), error: null}
}

export const getValidationErrMsg = (
  value: string,
  translate: (s: string) => string,
): {message: string; type: CertError} | null => {
  // Example value:
  //  G02635NWSC0000003980011022311022GEN
  //
  //  G02635NWSC                  0000003980                      011022          311022        GEN
  //  ^^^^^^^^^^                  ^^^^^^^^^^                      ^^^^^^          ^^^^^^        ^^^
  //  accreditation num           certificate id                  start(ddmmyy)   end(ddmmyy)   static suffix
  //  10 chars                    10 digits                       6 digits        6 digits
  //                              padded with 0s on the left      01.10.2022      11.10.2022
  //                              3980 in the example
  //
  //  NWSC - WIND
  //  PVEN - SOLAR
  //  HYDR - hydro
  if (value.length < 35) {
    return {message: translate('Certificate number cannot be less than 35 characters'), type: CertError.INVALID_LENGTH}
  }

  if (value.length > 35) {
    return {message: translate('Certificate number cannot be more than 35 characters'), type: CertError.INVALID_LENGTH}
  }
  // 10 chars
  const accreditationNum = getAccreditationNum(value)

  // last 4 chars of accreditationNum
  const certTech = accreditationNum.substring(6, 10)
  const allowedTechnologies = ['NWSC', 'PVEN', 'HYDR']
  if (!allowedTechnologies.includes(certTech)) {
    return {
      message: translate(
        `The technology group "${certTech}" is invalid. Allowed values are: ${allowedTechnologies.join(', ')}`,
      ),
      type: CertError.INVALID_ACC_NUM,
    }
  }

  // 10 chars
  const certificateId = value.substring(10, 20)
  if (!/^\d+$/.test(certificateId)) {
    return {
      message: translate(`The certificate id "${certificateId}" is invalid. It must containt only digits`),
      type: CertError.INVALID_CERT_ID,
    }
  }

  const cert = parseInt(certificateId)

  if (cert < 1) {
    return {
      message: translate(`The certificate id "${certificateId}" is invalid. It cannot be a negative number or null`),
      type: CertError.INVALID_CERT_ID,
    }
  }

  const startDateInput = value.substring(20, 26)
  if (!/^\d+$/.test(startDateInput)) {
    return {
      message: translate(`The start date "${startDateInput}" is invalid. It must contain only digits`),
      type: CertError.INVALID_START_DATE,
    }
  }

  const startYear = 2000 + parseInt(startDateInput.substring(4, 6))
  const startDate = dayjs.utc(
    `${startDateInput.substring(0, 2)}/${startDateInput.substring(2, 4)}/${startYear}`,
    'DD/MM/YYYY',
  )
  if (!startDate.isValid()) {
    return {
      message: translate(
        `The start date "${startDateInput}" is invalid. It must be in the format DDMMYY(day, month, year)`,
      ),
      type: CertError.INVALID_START_DATE,
    }
  }

  const endDateInput = value.substring(26, 32)

  if (!/^\d+$/.test(endDateInput)) {
    return {
      message: translate(`The end date "${endDateInput}" is invalid. It must contain only digits`),
      type: CertError.INVALID_END_DATE,
    }
  }

  const endYear = 2000 + parseInt(endDateInput.substring(4, 6))
  const endDate = dayjs.utc(`${endDateInput.substring(0, 2)}/${endDateInput.substring(2, 4)}/${endYear}`, 'DD/MM/YYYY')
  if (!endDate.isValid()) {
    return {
      message: translate(
        `The end date "${endDateInput}" is invalid. It must be in the format DDMMYY(day, month, year)`,
      ),
      type: CertError.INVALID_END_DATE,
    }
  }

  if (startDate.isAfter(endDate)) {
    return {
      message: translate(
        `The start date(${formatDate(
          startDate.toDate(),
          DateFormat.MONTH_DAY_YEAR,
        )}) must be before the end date(${formatDate(endDate.toDate(), DateFormat.MONTH_DAY_YEAR)})`,
      ),
      type: CertError.INVALID_END_DATE_BEFORE_START_DATE,
    }
  }

  const suffix = value.substring(32, 35)

  return null
}
