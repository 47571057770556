import React, {useEffect, useRef, useState} from 'react'
import Page from 'components/Page'
import useLocalization from 'hooks/useLocalization'
import {useAtom, useAtomValue} from 'jotai'
import {partyAtom} from 'atoms/party'
import SellerOnboardingAside from './Seller/SellerOnboardingAside'
import VerticalStack from 'components/VerticalStack'
import {Heading, SubHeading} from './Heading'
import styled from 'styled-components'
import onboardingHeroImg from 'assets/images/onboarding-hero-img.webp'
import {Redirect, Route, Switch, useHistory} from 'react-router-dom'
import NotFound from 'components/NotFound'
import SetupSitesForm from './Seller/SetupSitesForm'
import CreateProductForm from './Seller/CreateProductForm'
import ReviewProduct from './Seller/ReviewProduct'
import {OnboardingStatus} from 'domain/IParty'
import InnerScroll from 'components/InnerScroll'
import {PADDING_HEIGHT, TOP_NAVBAR_HEIGHT} from 'components/Layout'
import Box from 'components/Box'
import OnboardingWelcome from './OnboardingWelcome'
import Loader from 'components/Loader'
import useTheme from 'hooks/useTheme'
import {rootServiceAtom} from 'atoms/general'
import ConfirmationDialog from 'components/ConfirmationDialog'
import useAlerts from 'hooks/useAlerts'
import {getPlatformUrl} from 'helpers/party'

export interface IProps extends React.PropsWithChildren {
  title: string
  description?: string
  disabled?: boolean
  ref: any
}

export const HeroImageContainer = styled.div`
  background-image: url(${onboardingHeroImg});
  background-position: 0 0;
  background-size: cover;
  display: flex;
  flex-direction: row;
  justify-content: end;
`

export const OnboardingContainer = styled(Box)`
  width: 50%;
  min-width: 600px;
  max-width: 1150;
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);
  background-image: linear-gradient(rgba(120, 249, 111, 0.77), rgba(120, 249, 111, 0.77));
  padding-top: ${PADDING_HEIGHT / 2}px;
  padding-bottom: ${PADDING_HEIGHT / 2}px;
`

const SellerOnboardingWizard: React.FC<IProps> = ({title, description}) => {
  const {translate} = useLocalization()
  const [skipOpen, setSkipOpen] = useState(false)
  const theme = useTheme()
  const [party, setParty] = useAtom(partyAtom)
  const rootService = useAtomValue(rootServiceAtom)
  const [contentHeight, setContentHeight] = useState(0)
  const elementRef = useRef(null)
  const history = useHistory()
  const {addError} = useAlerts()
  const {v2ContentService} = rootService
  const routePrefix = `/broker/${party?.id}/onboarding`

  const updateContentHeight = () => {
    if (elementRef?.current) {
      const {clientHeight} = elementRef.current
      setContentHeight(clientHeight + TOP_NAVBAR_HEIGHT + PADDING_HEIGHT)
    }
  }

  const onSkip = async () => {
    try {
      await v2ContentService.skipSellerOnboarding(party.id)
      const updated = {
        ...party,
        onboardingStatus: OnboardingStatus.SELLER_SKIPPED,
      }
      setParty(updated)
      history.push(getPlatformUrl(party))
    } catch (e) {
      addError(translate('Failed to skip onboarding'), e?.correlationId, e?.message)
    }
  }

  const openSkipDialog = () => setSkipOpen(true)

  useEffect(() => {
    updateContentHeight()
  }, [party])

  useEffect(() => {
    window.addEventListener('resize', updateContentHeight)
  }, [])

  if (!party) {
    return <Loader />
  }

  return (
    <Page
      title={title}
      description={description}
      aside={<SellerOnboardingAside />}
      asideColor={theme.colors.globalBackground}
      gridTemplateColStyle="auto 325px"
      mainPad={{top: 0, right: 0, bottom: 0, left: 0}}
      noHeader
    >
      <VerticalStack>
        <div ref={elementRef}>
          <VerticalStack style={{padding: 16, height: 64}}>
            <Heading>{translate('Onboarding wizard')}</Heading>
            <SubHeading>{translate('Follow the steps and get going in no time')}</SubHeading>
          </VerticalStack>
        </div>
        <HeroImageContainer>
          <OnboardingContainer>
            <InnerScroll noRightPad>
              <Box height={`calc(100vh - ${contentHeight}px)`} pad={{horizontal: 6}}>
                <Switch>
                  <Route
                    exact
                    path={routePrefix}
                    component={() => {
                      switch (party.onboardingStatus) {
                        case OnboardingStatus.NOT_STARTED:
                          return <Redirect to={`${routePrefix}/welcome`} />

                        case OnboardingStatus.SELLER_STARTED:
                          return <Redirect to={`${routePrefix}/setup-site`} />

                        case OnboardingStatus.SELLER_SITE_CREATED:
                          return <Redirect to={`${routePrefix}/create-product`} />

                        case OnboardingStatus.SELLER_PRODUCT_CREATED:
                          return <Redirect to={`${routePrefix}/review`} />

                        case OnboardingStatus.COMPLETED:
                        case OnboardingStatus.SELLER_SKIPPED:
                          console.warn('Completed onboarding, but still in onboarding flow')
                          return <Redirect to={getPlatformUrl(party)} />
                      }
                    }}
                  />
                  <Route
                    exact
                    path={`${routePrefix}/welcome`}
                    render={() => {
                      if (party?.onboardingStatus === OnboardingStatus.NOT_STARTED) {
                        return <OnboardingWelcome openSkipDialog={openSkipDialog} />
                      }
                      return <Redirect to={`${routePrefix}`} />
                    }}
                  />
                  <Route
                    exact
                    path={`${routePrefix}/setup-site`}
                    render={() => {
                      if (party?.onboardingStatus === OnboardingStatus.SELLER_STARTED) {
                        return <SetupSitesForm openSkipDialog={openSkipDialog} />
                      }
                      return <Redirect to={`${routePrefix}`} />
                    }}
                  />
                  <Route
                    exact
                    path={`${routePrefix}/create-product`}
                    render={() => {
                      if (party?.onboardingStatus === OnboardingStatus.SELLER_SITE_CREATED) {
                        return <CreateProductForm openSkipDialog={openSkipDialog} />
                      }
                      return <Redirect to={`${routePrefix}`} />
                    }}
                  />
                  <Route
                    exact
                    path={`${routePrefix}/review`}
                    render={() => {
                      if (party?.onboardingStatus === OnboardingStatus.SELLER_PRODUCT_CREATED) {
                        return <ReviewProduct openSkipDialog={openSkipDialog} />
                      }
                      return <Redirect to={`${routePrefix}`} />
                    }}
                  />
                  <Route path="*" component={NotFound} />
                </Switch>
              </Box>
            </InnerScroll>
          </OnboardingContainer>
        </HeroImageContainer>
      </VerticalStack>
      <ConfirmationDialog
        open={skipOpen}
        setOpen={setSkipOpen}
        title={translate('Skip onboarding')}
        acceptText={translate('Skip')}
        declineText={translate('Cancel')}
        onAccept={onSkip}
        text={translate(
          'Skip the onboarding sequence if you are interested in tender participation only. By pressing skip, you will skip the guided onboarding sequence, however you will be able to create products later through Marketplace listing menu.',
        )}
      />
    </Page>
  )
}

export default SellerOnboardingWizard
