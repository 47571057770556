import React, {useMemo, useState} from 'react'
import Text from 'components/Text'
import Box from 'components/Box'
import Table, {TableValue} from 'components/Table'
import {DateFormat, formatDate} from 'helpers/date'
import useLocalization from 'hooks/useLocalization'
import HelpIcon from '@mui/icons-material/HelpOutlineOutlined'
import {useAtomValue} from 'jotai'
import {rawProposalDetailsAtom} from 'atoms/certificateDesigner'
import {LinearProgress, Tooltip} from '@mui/material'
import {formatDecimal} from 'helpers/format'
import useTheme from 'hooks/useTheme'

const HeadingWithTooltip = ({title, tooltip}: {title: string; tooltip: string}) => {
  return (
    <Box direction="row" gap={1}>
      <Text size="medium" bold>
        {title}
      </Text>
      <Tooltip title={tooltip}>
        <HelpIcon sx={{fontSize: '18px', mt: 0.3}} />
      </Tooltip>
    </Box>
  )
}

const TableMwhValue = ({value}) => {
  const theme = useTheme()

  return (
    <Box direction="row" gap={0.5} align="center">
      <Text nowrap font={theme.font.numbers}>
        {value / 1000}
      </Text>

      <Text size="small" font={theme.font.secondaryFont} color={theme.colors.accent} uppercase>
        MWh
      </Text>
    </Box>
  )
}

const RawVolumesTable = () => {
  const {translate} = useLocalization()
  const {data, error, loading} = useAtomValue(rawProposalDetailsAtom)
  const {missing, maxAllowed, purchase, productAvailableNoOverlap, purchaseFractions, consumption} =
    data?.timeseries || {}
  const transformedData = useMemo(() => {
    return consumption?.map((item, index) => {
      return {
        consumption: {
          date: item.date,
          value: item.valueKwh,
        },
        missing: {
          date: missing[index].date,
          value: missing[index].valueKwh,
        },
        maxAllowed: {
          date: maxAllowed[index].date,
          value: maxAllowed[index].certs,
        },
        purchase: {
          date: purchase[index].date,
          value: purchase[index].certs,
        },
        productAvailableNoOverlap: {
          date: productAvailableNoOverlap[index].date,
          value: productAvailableNoOverlap[index].certs,
        },
        purchaseFractions: {
          date: purchaseFractions[index].date,
          value: purchaseFractions[index].valueKwh,
        },
      }
    })
  }, [consumption])

  if (loading) return <LinearProgress />

  return (
    <Box>
      <Table
        tableName={translate('Certificates breakdown')}
        data={transformedData}
        sortable={false}
        columns={[
          {
            title: translate('Time'),
            accessor: report => report.consumption.date,
            render: (date: string) => (
              <Text size="msmall" nowrap>
                {formatDate(date, DateFormat.MONTH_DAY_HOURS_MINUTES)}
              </Text>
            ),
          },
          {
            title: (
              <HeadingWithTooltip title={translate('Consumption')} tooltip={translate('Missing and already covered')} />
            ),
            accessor: report => report.consumption.value,
            render: (value: string) => <TableMwhValue value={value} />,
          },
          {
            title: translate('Missing'),
            accessor: report => report.missing.value,
            render: (value: string) => <TableMwhValue value={value} />,
          },
          {
            title: (
              <HeadingWithTooltip
                title={translate('Product available')}
                tooltip={translate('What can be sold regardless of the overlap')}
              />
            ),
            accessor: report => report.productAvailableNoOverlap.value,
            render: (value: string) => <TableValue rounded value={value} unit="EACs" />,
          },
          {
            title: (
              <HeadingWithTooltip
                title={translate('Max allowed')}
                tooltip={translate('Max amount that can be bought from this product, changes based on matching mode')}
              />
            ),
            accessor: report => report.maxAllowed.value,
            render: (value: string) => <TableValue rounded value={value} unit="EACs" />,
          },
          {
            title: (
              <HeadingWithTooltip
                title={translate('Purchase')}
                tooltip={translate('What the system can offer based on the quantity and matching mode')}
              />
            ),
            accessor: report => report.purchase.value,
            render: (value: string) => <TableValue rounded value={value} unit="EACs" />,
          },
          {
            title: (
              <HeadingWithTooltip
                title={translate('Purchase fractions')}
                tooltip={translate('What was added/removed(based on the matching mode) to get a whole number')}
              />
            ),
            accessor: report => report.purchaseFractions.value,
            render: (value: string) => <TableMwhValue value={value} />,
          },
        ]}
      />
    </Box>
  )
}

export default RawVolumesTable
