import {OfferState} from '../domain/IOffer'
import ITranslate from '../interfaces/ITranslate'
import {isNil, meanBy, mean, sum} from 'lodash-es'

export function getPriceRange(minPrice: number, maxPrice: number): string {
  const getPrice = (price: number) => (isNil(price) ? '...' : price.toFixed(2))
  return `${getPrice(minPrice)} - ${getPrice(maxPrice)}`
}

export function brokerOfferStateToReadableName(state: OfferState, translate: ITranslate): string {
  switch (state) {
    case OfferState.CONTRACT_SENT:
      return translate('Contract sent')

    case OfferState.CONTRACT_SIGNED:
      return translate('Contract signed')

    case OfferState.CONFIG_SENT_FOR_SELLER_REVIEW:
      return translate('Purchase in review')

    case OfferState.CONFIG_IN_PROGRESS:
      return translate('Purchase in draft')

    case OfferState.SELLER_DECLINED_CONFIG:
      return translate('Purchase declined')
  }

  return null
}

export function customerOfferStateToReadableName(state: OfferState, translate: ITranslate): string {
  switch (state) {
    case OfferState.CONTRACT_SENT:
      return translate('Contract draft')

    case OfferState.CONTRACT_SIGNED:
      return translate('Contract signed')

    case OfferState.CONFIG_SENT_FOR_SELLER_REVIEW:
      return translate('Purchase in review')

    case OfferState.CONFIG_IN_PROGRESS:
      return translate('Purchase in draft')

    case OfferState.SELLER_DECLINED_CONFIG:
      return translate('Purchase declined')
  }

  return null
}
