import React, {useEffect, useRef} from 'react'
import ActionBox from 'components/Platform/ActionBox'
import useLocalization from 'hooks/useLocalization'
import Heading from 'components/Heading'
import Box from 'components/Box'
import useTheme from 'hooks/useTheme'
import OfferProduct from 'components/Platform/Portfolio/Offer/OfferDesignerCertificate/OfferProduct'
import ContractDownloadLinks from 'components/Platform/Portfolio/ContractDownloadLinks'
import ContractHtmlPreview from 'components/Platform/Portfolio/ContractHtmlPreview'
import useScreenSize, {ScreenBreakpoint} from 'hooks/useScreenSize'
import ProposalDetailsInfo from 'components/Platform/Buy/CertificateDesigner/ProposalDetailsInfo'
import ProposalDetailsChart from 'components/Platform/Buy/CertificateDesigner/ProposalDetailsChart'
import Page from 'components/Page'
import InnerScroll from 'components/InnerScroll'
import Text from 'components/Text'
import ResponsiveGrid from 'components/ResponsiveGrid'
import Label from 'components/Label'
import ValueWithUnit from 'components/ValueWithUnit'
import {Accordion, AccordionDetails, AccordionSummary} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import MatchedCertsTable from 'components/Platform/Portfolio/CertificateContractPage/MatchedCertsTable'
import {useAtomValue, useSetAtom} from 'jotai'
import {contractIdAtom, sellerContractAtom} from 'atoms/contracts'
import Error from 'components/Error'

interface IProps extends React.PropsWithChildren {
  id: number
}

const SignedAgreements: React.FC<IProps> = ({id}) => {
  const {translate} = useLocalization()
  const docRef = useRef<HTMLDivElement>(null)
  const screenSize = useScreenSize()
  const theme = useTheme()
  const {data: contract, loading, error} = useAtomValue(sellerContractAtom)
  const setContractId = useSetAtom(contractIdAtom)
  const {name, purchaseInterval, product, purchaseVolume, offer, purchaseTimeseries, matchedCerts} = contract || {}
  const {currency, totalPrice, eacPrice} = offer || {}

  useEffect(() => {
    if (id) {
      setContractId(id)
    }
  }, [id])

  return (
    <Page
      error={error && <Error title={translate('Failed to load contract')} />}
      isLoading={loading}
      noHeader
      mainPad={{top: 2, right: 2, bottom: 2, left: 2}}
    >
      <InnerScroll noRightPad>
        <Box direction="column" gap={2}>
          <ResponsiveGrid
            gap={2}
            templateColumns={{[ScreenBreakpoint.Medium]: '1fr', [ScreenBreakpoint.Large]: '1fr 3fr'}}
          >
            <ActionBox
              title={translate('Contract summary')}
              description={translate('Overview of your contract key facts')}
              fullWidth
            >
              <Box gap={2} direction="column">
                <Label text={translate('Name')}>
                  <Text size="xlarge" semibold lineHeight="small" data-cy="contract-name">
                    {name}
                  </Text>
                </Label>
                <Label text={translate('Purchase volume')}>
                  <ValueWithUnit value={purchaseVolume} unit="CERTIFICATES" size="mlarge" />
                </Label>

                {purchaseInterval && (
                  <Label text={translate('Purchase interval')}>
                    <Box direction="row" gap={1} pad={{bottom: 2}}>
                      <ValueWithUnit value={purchaseInterval.month} size="mlarge" />
                      <ValueWithUnit value={purchaseInterval.year} size="mlarge" />
                    </Box>
                  </Label>
                )}
              </Box>
            </ActionBox>
            <ActionBox title={translate('Contract details')} fullWidth>
              <Box direction="column" gap={3} width="100%">
                <ProposalDetailsInfo
                  totalPrice={+totalPrice}
                  averagePrice={+eacPrice}
                  currency={currency}
                  noGreenCoverage
                />
                <ProposalDetailsChart
                  title={product?.name}
                  technology={product?.technology}
                  volumes={purchaseTimeseries}
                />
              </Box>
            </ActionBox>
          </ResponsiveGrid>

          <Box direction="column">
            <OfferProduct
              product={product}
              currency={currency}
              quantity={product?.quantity}
              averagePrice={product?.certificatePrice}
            />

            <Accordion
              sx={{
                padding: 1,
                mt: 0,
                mb: 2,
                border: `1px solid ${theme.colors.lightGrey}`,
                boxShadow: 'none',
                borderRadius: 2,
              }}
              slotProps={{transition: {timeout: 100}}}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Heading>{translate('Matched EACs')}</Heading>
              </AccordionSummary>
              <AccordionDetails>
                <MatchedCertsTable data={matchedCerts} />
              </AccordionDetails>
            </Accordion>

            <Box pad={2} border round style={screenSize >= ScreenBreakpoint.Large ? {gridColumn: 'span 2'} : null}>
              <Heading margin={{bottom: 2}}>{translate('Agreement')}</Heading>

              <ContractDownloadLinks contract={{id: contract?.id, state: contract?.state}} docRef={docRef} />
              <ContractHtmlPreview id={contract?.id} docRef={docRef} buyer={false} />
            </Box>
          </Box>
        </Box>
      </InnerScroll>
    </Page>
  )
}

export default SignedAgreements
