import React from 'react'
import useLocalization from '../../hooks/useLocalization'
import Box from '../Box'
import Text from '../Text'
import Loader from '../Loader'
import styled from 'styled-components'
import ITheme from '../../theme/ITheme'
import icon1 from './icon1.svg'
import icon2 from './icon2.svg'
import useTheme from '../../hooks/useTheme'

interface IProps extends React.PropsWithChildren {
  title?: string
  name?: string
  noText?: boolean
  icon: string
  url?: string
  saveAs?: string
  onClick?: () => void
  isLoading?: boolean
  iconHeight?: number
}

const StyledLink = styled.a`
  color: ${props => (props.theme as ITheme).colors.secondary};
  display: block;
  padding-right: 25px;

  &:hover {
    filter: contrast(63%);
  }
`

const DownloadLink: React.FC<IProps> = ({
  title,
  name,
  icon,
  url,
  saveAs,
  onClick,
  isLoading,
  noText,
  iconHeight = 5,
}) => {
  const {translate} = useLocalization()
  const theme = useTheme()

  return (
    <StyledLink download={saveAs} href={url} onClick={onClick}>
      <Box align="center">
        <Box margin={{right: 2}}>
          <img src={icon} alt="" height={theme.tileSize * iconHeight} />
        </Box>
        {!noText && (
          <div>
            <Text size="small" uppercase>
              {translate('Download')}
            </Text>
            <Text semibold>{title}</Text>
            <Text>{name}</Text>
          </div>
        )}
        {isLoading && <Loader margin={{left: 1}} />}
      </Box>
    </StyledLink>
  )
}

export const DownloadLink1: React.FC<Omit<IProps, 'icon'>> = props => <DownloadLink icon={icon1} {...props} />

export const DownloadLink2: React.FC<Omit<IProps, 'icon'>> = props => <DownloadLink icon={icon2} {...props} />

export default DownloadLink
