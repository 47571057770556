import React from 'react'
import Box from 'components/Box'
import Label from 'components/Label'
import useLocalization from 'hooks/useLocalization'
import Input from 'components/Input'
import Select from 'components/Select'
import {brokerOfferStateToReadableName} from 'helpers/offer'
import ICertificateOffer from 'domain/ICertificateOffer'
import {getMonths, getPastYears} from 'helpers/date'

interface IProps extends React.PropsWithChildren {
  offer: ICertificateOffer
}

export const EMPTY_WEEKDAY = 'None'

const OfferProposalDetails: React.FC<IProps> = ({offer}) => {
  const {translate} = useLocalization()
  const months = getMonths()
  const years = getPastYears(4)

  return (
    <>
      <Input
        label={translate('Offer State')}
        name="state"
        value={brokerOfferStateToReadableName(offer.state, translate)}
        disabled
      />
      <Input label={translate('Proposal title')} name="title" value={offer.name} disabled />
      <Input label={translate('customer')} name="customer" value={offer?.party?.name} disabled />
      <Input
        label={translate('Purchase volume')}
        suffix={translate('CERTIFICATES')}
        value={offer?.estimatedVolume}
        disabled
      />
      <Label text={translate('Purchase interval')}>
        <Box direction="row" gap={1}>
          <Select
            defaultValue={offer.purchaseInterval.month}
            options={months.map(month => ({value: month, label: month}))}
            disabled
          />
          <Select
            defaultValue={offer.purchaseInterval.year}
            options={years.map(year => ({value: year, label: `${year}`}))}
            disabled
          />
        </Box>
      </Label>
    </>
  )
}

export default OfferProposalDetails
