import React from 'react'
import styled, {keyframes} from 'styled-components'
import Link from '../Link'

export interface IProps extends React.PropsWithChildren {
  lat: number
  lng: number
  image: string
  linkTo?: string
  hasMouseOver?: boolean
  onMouseOver?: () => void
  onMouseOut?: () => void
  onClick?: () => void
}

const fadeIn = keyframes`
  0% {
    opacity: 0;
    margin-bottom: -12px;
  }

  100% {
    opacity: 1;
    margin-bottom: 0;
  }
`

const Panel = styled.div`
  display: none;
  background-color: #fff;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translate(-50%, -12px);
  box-shadow: 1px 1px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 12px;
  border-radius: 4px;
  animation: ${fadeIn} 0.2s;

  &:after {
    content: '';
    background: #fff;
    height: 16px;
    width: 16px;
    left: 50%;
    position: absolute;
    bottom: 0;
    transform: translate(-50%, 4px) rotate(-45deg);
    box-shadow: -4px 4px 4px 0 rgba(0, 0, 0, 0.1);
  }
`

const StyledMarker = styled.img<{hasMouseOver?: boolean}>`
  cursor: pointer;
  transition: transform 0.2s;

  &:hover,
  &.hover {
    transform: translateY(-5%) scale(1.1);
  }
`

const Container = styled(Link)<{hasMouseOver?: boolean}>`
  outline: none;
  position: absolute;
  transform: translate(-50%, -100%);

  &:hover,
  &.hover {
    z-index: 2;
    & ${Panel} {
      display: block;
    }

    & ${StyledMarker} {
      transform: translateY(-5%) scale(1.1);
    }
  }
`

export const Marker: React.FC<IProps> = ({image, linkTo, children, hasMouseOver, onMouseOver, onMouseOut, onClick}) => {
  return (
    <Container
      tabIndex={0}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      onClick={onClick}
      className={hasMouseOver && 'hover'}
      to={linkTo}
      data-cy="Marker"
    >
      <StyledMarker src={image} className={hasMouseOver && 'hover'} alt="" />
      {children && <Panel>{children}</Panel>}
    </Container>
  )
}

export default Marker
