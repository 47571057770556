import React from 'react'
import Input from 'components/Input'
import {getChangeEventValue} from 'helpers/misc'
import dayjs from 'dayjs'
import {getMonthNumber} from 'helpers/date'

const CustomDay = ({setValue, value}) => {
  const maxDate = dayjs().subtract(2, 'day').format('YYYY-MM-DD')
  const monthNumber = getMonthNumber(value?.startMonth) + 1

  const handleChange = e => {
    const value = getChangeEventValue(e)
    const date = dayjs(value)

    setValue({
      startDay: date.date(),
      startMonth: date.format('MMMM'),
      startYear: date.year(),
      endDay: date.date(),
      endMonth: date.format('MMMM'),
      endYear: date.year(),
    })
  }

  return (
    <Input
      small
      type="date"
      value={
        value?.startDay
          ? `${value.startYear}-${monthNumber.toString().padStart(2, '0')}-${value.startDay
              .toString()
              .padStart(2, '0')}`
          : ''
      }
      onChange={handleChange}
      openOnClick
      max={maxDate}
    />
  )
}

export default CustomDay
