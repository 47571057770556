import React, {memo, useMemo} from 'react'
import Box from '../../../Box'
import styled from 'styled-components'
import Text from 'components/Text'
import useTheme from 'hooks/useTheme'
import Grid from 'components/Grid'
import EditIcon from '@mui/icons-material/EditOutlined'
import {IconButton, Skeleton, Tooltip} from '@mui/material'
import {isNil} from 'lodash-es'
import CircularProgress, {getColorByValue} from 'components/CircularProgress'
import TrophyIcon from '@mui/icons-material/EmojiEvents'
import LockIcon from '@mui/icons-material/Lock'
import MissingIcon from '@mui/icons-material/NewReleasesOutlined'

export const Title = styled(Text)<{columns: number}>`
  color: ${({theme}) => theme.colors.secondary};
  grid-column: span ${({columns}) => columns};
  font-family: ${({theme}) => theme.font.numbers};
  font-size: 11px;
  line-height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-height: 11px;
`

export const Value = styled(Text)<{isBlurred?: boolean}>`
  color: ${({theme}) => theme.colors.secondary};
  font-family: ${({theme}) => theme.font.numbers};
  font-size: 16px;
  line-height: 21px;
  ${({isBlurred}) =>
    isBlurred &&
    `
    filter: blur(4px);
    transition: filter 0.3s ease;
  `}
`

export const Description = styled(Text)`
  color: ${({theme}) => theme.colors.accent};
  font-family: ${({theme}) => theme.font.secondaryFont};
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;
`

export const StyledRow = styled(Box)`
  border-bottom: 1px solid ${({theme}) => theme.colors.outline};
  &:hover {
    background-color: ${({theme}) => theme.colors.outline};
  }
`

const ValueComponent = ({missingText, value, unit, blur}) => {
  const theme = useTheme()

  if (missingText) {
    return (
      <Tooltip title={missingText}>
        <div>
          <MissingIcon sx={{color: theme.colors.warning}} />
        </div>
      </Tooltip>
    )
  } else {
    return <Value isBlurred={blur}>{isNil(value) ? '-' : `${value}${unit}`}</Value>
  }
}

const TextValue = ({value, unit, text, loading, blur, missingText}) => {
  if (!text) return null

  return (
    <Box direction="column">
      {loading ? (
        <Skeleton variant="rounded" height={21} />
      ) : (
        <ValueComponent value={value} unit={unit} blur={blur} missingText={missingText} />
      )}
      <Description>{text}</Description>
    </Box>
  )
}

const CircularValue = ({value, text, loading, withIcon, blur}) => {
  const theme = useTheme()
  const parsedValue = isNil(value) ? null : +value
  const showIcon = withIcon && parsedValue >= 100

  if (!text) return null

  return (
    <Box align="center" direction="column">
      <CircularProgress
        trailColor={theme.colors.light1}
        value={loading || blur ? 0 : parsedValue}
        color={getColorByValue(parsedValue, theme)}
        width={6}
        height={6.5}
      >
        <CircularContent loading={loading} blur={blur} showIcon={showIcon} parsedValue={parsedValue} theme={theme} />
      </CircularProgress>
      <Description>{text}</Description>
    </Box>
  )
}

const CircularContent = ({loading, blur, showIcon, parsedValue, theme}) => {
  if (loading) {
    return <Skeleton variant="circular" width={30} height={30} />
  }

  if (blur) {
    return <LockIcon />
  }

  if (showIcon) {
    return <TrophyIcon sx={{color: getColorByValue(parsedValue, theme)}} />
  }

  return (
    <Text size="mlarge" semibold color={getColorByValue(parsedValue, theme)} font={theme.font.numbers} pad={{top: 0.7}}>
      {isNil(parsedValue) ? '-' : parsedValue}
    </Text>
  )
}

interface IProps {
  editIcon?: boolean
  onEdit?: () => void
  title: string
  firstVal: string
  secondVal?: string
  thirdVal?: string
  firstText: string
  secondText?: string
  thirdText?: string
  missingText?: string
  unit?: string
  loading?: boolean
  columns?: number
  circularProgress?: boolean
  showSuccessIcon?: boolean
  blur?: boolean
}

const SummaryRow: React.FC<IProps> = ({
  title,
  firstVal,
  secondVal,
  thirdVal,
  firstText,
  secondText,
  thirdText,
  missingText,
  unit,
  editIcon,
  onEdit,
  loading,
  columns = 3,
  circularProgress,
  showSuccessIcon,
  blur,
}) => {
  const theme = useTheme()

  const ValueComponent = circularProgress ? CircularValue : TextValue

  return (
    <StyledRow>
      <Grid columns={columns} gap={1.5} pad={{horizontal: 2, top: 2, bottom: 1}}>
        <Title columns={columns}>
          <Title columns={columns}>{title}</Title>
          {editIcon && (
            <IconButton size="small" sx={{color: theme.colors.info}} onClick={onEdit} data-cy="kpi-goals">
              <EditIcon fontSize="small" />
            </IconButton>
          )}
        </Title>
        <ValueComponent
          value={firstVal}
          unit={unit}
          text={firstText}
          loading={loading}
          withIcon={showSuccessIcon}
          blur={blur}
          missingText={missingText}
        />
        <ValueComponent
          value={secondVal}
          unit={unit}
          text={secondText}
          loading={loading}
          withIcon={showSuccessIcon}
          blur={blur}
          missingText={missingText}
        />
        <ValueComponent
          value={thirdVal}
          unit={unit}
          text={thirdText}
          loading={loading}
          withIcon={showSuccessIcon}
          blur={blur}
          missingText={missingText}
        />
      </Grid>
    </StyledRow>
  )
}

export default SummaryRow
