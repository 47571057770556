import React from 'react'
import {getPlatformUrl} from 'helpers/party'
import {useHistory} from 'react-router-dom'
import {partyMembersAtom, selectedPartyMemberAtom, transitionAtom} from 'atoms/party'
import {useAtomValue, useSetAtom} from 'jotai'
import useProfileDetails from 'hooks/useProfileDetails'

const usePartyActions = () => {
  const {loadProfile} = useProfileDetails()
  const setSelectedPartyMember = useSetAtom(selectedPartyMemberAtom)
  const setTransition = useSetAtom(transitionAtom)
  const partyMembers = useAtomValue(partyMembersAtom)
  const history = useHistory()

  const switchParty = (partyId: number, destination?: string) => {
    setTransition(true)
    const newPartyMember = partyMembers.find(pm => pm.party.id === partyId)
    setSelectedPartyMember(newPartyMember)
    localStorage.setItem('currentPartyMemberId', `${newPartyMember.id}`)
    history.push(`${getPlatformUrl(newPartyMember?.party)}/${destination ? destination : ''}`)

    setTimeout(() => {
      setTransition(false)
    }, 0) // That way it will trigger after unmount
  }

  const refreshParties = async (returnTo?: string) => {
    return await loadProfile(returnTo)
  }

  return {
    switchParty,
    refreshParties,
  }
}

export default usePartyActions
