import React from 'react'
import {Redirect, useLocation, useParams} from 'react-router-dom'
import useProfile from 'hooks/useProfile'
import {getPartyLink, getPlatformUrl} from 'helpers/party'
import {useAtomValue} from 'jotai'
import {partyMembersAtom} from 'atoms/party'
import PartyNotFound from 'views/PartyNotFound'

const AuthGuard: React.FC<React.PropsWithChildren> = ({children}) => {
  const {pathname} = useLocation()
  const {partyId} = useParams<{partyId: string}>()
  const partyRoute = pathname.split('/')[1]
  const partyMembers = useAtomValue(partyMembersAtom)
  const {user, isLoaded, party} = useProfile()
  const existingParty = partyMembers.find(pm => pm.party.id === +partyId)
  const hasPartyId = isFinite(+partyId)

  if (!user && isLoaded) {
    return <Redirect to={`/?return_to=${encodeURIComponent(pathname)}`} />
  }

  if (user && !party && isLoaded) {
    return <Redirect to="/no-associated" />
  }

  // TODO: remove later when we migrate fully to partyid in the url
  if (isLoaded && !hasPartyId) {
    return <Redirect to={pathname.replace(`/${getPartyLink(party?.partyRole)}`, getPlatformUrl(party))} />
  }

  if (isLoaded && !existingParty) {
    return <PartyNotFound partyId={partyId} />
  }

  // TODO: remove later when we migrate fully to partyid in the url
  if (user && party && partyRoute !== getPartyLink(party.partyRole)) {
    return <Redirect to={getPlatformUrl(party)} />
  }

  return <>{children}</>
}

export default AuthGuard
