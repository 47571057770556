import {rootServiceAtom} from 'atoms/general'
import {useAtomValue, useSetAtom} from 'jotai'
import {userAtom, partyMembersAtom, selectedPartyMemberAtom, appLoadedAtom, phoneVerifiedModalAtom} from 'atoms/party'
import {initializeHelpCrunchUser} from 'helpers/user'
import {matchPath} from 'react-router-dom'

const useProfileDetails = () => {
  const rootService = useAtomValue(rootServiceAtom)
  const setUser = useSetAtom(userAtom)
  const setPartyMembers = useSetAtom(partyMembersAtom)
  const setSelectedPartyMember = useSetAtom(selectedPartyMemberAtom)
  const setPhoneVerifiedModal = useSetAtom(phoneVerifiedModalAtom)
  const setAppLoaded = useSetAtom(appLoadedAtom)

  const loadProfile = async (returnTo?: string) => {
    let party

    try {
      const token = await rootService.authenticationService.getToken()
      const {params} =
        matchPath<{partyId?: string}>(returnTo || window.location.pathname, '/(consumer|broker)/:partyId') || {}
      const partyId = params?.partyId

      if (token) {
        const {profile, memberships: partyMembers, themes} = await rootService.v2ContentService.getDetailedProfile()

        partyMembers.forEach(partyMember => {
          const theme = themes.find(theme => theme?.id === partyMember?.party?.themeId)
          partyMember.party.theme = theme
        })

        setUser(profile)
        setPartyMembers(partyMembers)
        setPhoneVerifiedModal(!profile?.phoneNumberVerified)

        const currentPartyMemberId = localStorage.getItem('currentPartyMemberId')
        const partiesWithRoles = partyMembers.filter(partyMember => partyMember.party?.partyRole)
        const currentPartyMember = partyMembers?.find(partyMember => partyMember.id === +currentPartyMemberId)
        const matchedParty = partyMembers?.find(partyMember => partyMember.party?.id === +partyId)

        if (partyId && matchedParty) {
          setSelectedPartyMember(matchedParty)
          party = matchedParty?.party
        } else if (currentPartyMemberId && currentPartyMember) {
          setSelectedPartyMember(currentPartyMember)
          party = currentPartyMember?.party
        } else {
          const firstPartyMember = partiesWithRoles[0] || partyMembers[0]
          setSelectedPartyMember(firstPartyMember)
          party = firstPartyMember?.party
        }

        if (party) {
          initializeHelpCrunchUser(profile, party)
        }
      }
    } finally {
      setAppLoaded(true)
    }

    return party
  }

  return {
    loadProfile,
  }
}

export default useProfileDetails
