import React from 'react'
import styled from 'styled-components'
import spacing, {ISpacingProps} from './styleProps/spacing'
import ITheme from '../theme/ITheme'

type ISize = number | 'medium' | 'small'

export interface ILoaderProps extends ISpacingProps {
  size?: ISize
  color?: string
}

const sizes = {
  medium: 0.5,
  small: 0.3,
}

const getSize = (size: ISize, type: 'outer' | 'inner' | 'border'): number => {
  const multiplier = {
    outer: 180,
    inner: 100,
  }

  if (typeof size === 'number') {
    return (size / multiplier.outer) * multiplier[type]
  }

  return sizes[size] * multiplier[type]
}

const Wrap = styled.div<ILoaderProps>`
  position: inline-block;
  width: ${({size}) => getSize(size, 'outer')}px;
  height: ${({size}) => getSize(size, 'outer')}px;
  ${spacing}

  .cls-1 {
    fill: ${props => props.color || (props.theme as ITheme).colors.primaryDark};
  }

  @keyframes spin {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  #main-blade {
    animation: spin 4s linear infinite;

    transform-origin: 44.8% 37.8%;
  }
`

const Loader: React.FC<ILoaderProps> = ({size = 'small', ...props}) => (
  <Wrap size={size} {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 87.77 110.21">
      <defs></defs>
      <g id="turbine" data-name="Layer 2">
        <g id="blade" data-name="Layer 2">
          <g id="main-blade">
            <polygon className="cls-1" points="48.6 39.8 55.43 36.21 86.51 41.19 87.77 46.15 48.34 43.14 48.6 39.8" />
            <polygon className="cls-1" points="32.19 46.4 29.9 53.77 4.39 72.22 0 69.62 30.01 43.86 32.19 46.4" />
            <polygon className="cls-1" points="32.22 34.33 25.11 31.32 9.27 4.12 12.3 0 34.96 32.41 32.22 34.33" />
            <path className="cls-1" d="M45.53,41.62a6.24,6.24,0,1,1-6.24-6.24A6.23,6.23,0,0,1,45.53,41.62Z" />
            <path
              className="cls-1"
              d="M45.08,39.31c0,.1,0,.2,0,.3A6.81,6.81,0,0,1,34,44.89a6.23,6.23,0,0,0,11.54-3.27A6.1,6.1,0,0,0,45.08,39.31Z"
            />
          </g>
          <polygon className="cls-1" points="44.01 49.98 46.44 110.21 33.97 110.21 37.01 50.59 44.01 49.98" />
        </g>
      </g>
    </svg>
  </Wrap>
)

export default Loader
