import IParty, {OnboardingStatus, PartyRole} from 'domain/IParty'

export function getPlatformUrl(party: IParty): string {
  const ROUTES = {
    [PartyRole.BUYER]: `/consumer/${party?.id}`,
    [PartyRole.BROKER]: `/broker/${party?.id}`,
  }

  return ROUTES[party?.partyRole] || '/incompatible-party'
}

export function getPartyLink(role): string {
  if (role === PartyRole.BUYER) {
    return 'consumer'
  } else if (role === PartyRole.BROKER) {
    return 'broker'
  } else {
    return role ? role.toLowerCase() : 'incompatible-party'
  }
}

export const needsOnboarding = (party: IParty) => {
  if (!party) {
    return false
  }

  switch (party.onboardingStatus) {
    case OnboardingStatus.NOT_STARTED:
    case OnboardingStatus.SELLER_STARTED:
    case OnboardingStatus.SELLER_SITE_CREATED:
    case OnboardingStatus.SELLER_PRODUCT_CREATED:
    case OnboardingStatus.BUYER_STARTED:
    case OnboardingStatus.BUYER_SITE_CREATED:
    case OnboardingStatus.BUYER_PROFILE_UPLOADED:
      return true
    case OnboardingStatus.COMPLETED:
    case OnboardingStatus.SELLER_SKIPPED:
      return false
  }
}
