import React from 'react'
import bioenergy from './icons/bioenergy.svg'
import bioenergyColor from './icons/bioenergy-color.svg'
import geothermal from './icons/geothermal.svg'
import geothermalColor from './icons/geothermal-color.svg'
import hydro from './icons/hydro.svg'
import hydroColor from './icons/hydro-color.svg'
import hydrogen from './icons/hydrogen.svg'
import hydrogenColor from './icons/hydrogen-color.svg'
import solar from './icons/solar.svg'
import solarColor from './icons/solar-color.svg'
import wave from './icons/wave.svg'
import waveColor from './icons/wave-color.svg'
import wind from './icons/wind.svg'
import windColor from './icons/wind-color.svg'
import gas from './icons/gas.svg'
import gasColor from './icons/gas-color.svg'
import battery from './icons/battery.svg'
import batteryColor from './icons/battery-color.svg'
import {SiteProductionTechnology} from '../../../domain/ISite'

type IIcons = {[key in SiteProductionTechnology]: string}

const PLAIN_TECHNOLOGY_ICONS: IIcons = {
  BIOENERGY: bioenergy,
  GEOTHERMAL: geothermal,
  HYDRO: hydro,
  HYDROGEN: hydrogen,
  SOLAR: solar,
  WAVE: wave,
  WIND: wind,
  GAS: gas,
  BATTERY: battery,
}

const COLORED_TECHNOLOGY_ICONS: IIcons = {
  BIOENERGY: bioenergyColor,
  GEOTHERMAL: geothermalColor,
  HYDRO: hydroColor,
  HYDROGEN: hydrogenColor,
  SOLAR: solarColor,
  WAVE: waveColor,
  WIND: windColor,
  GAS: gasColor,
  BATTERY: batteryColor,
}

interface IProps extends React.PropsWithChildren {
  technology: SiteProductionTechnology
  plain?: boolean
  size?: 'medium' | 'small'
}

const sizeMap = {
  small: 16,
  medium: 24,
}

const TechnologyIcon: React.FC<IProps> = ({technology, plain, size = 'medium'}) => {
  const icon = plain ? PLAIN_TECHNOLOGY_ICONS[technology] : COLORED_TECHNOLOGY_ICONS[technology]

  if (!icon) {
    return null
  }

  return <img height={sizeMap[size]} src={icon} alt="" />
}

export default TechnologyIcon
