import {useLocation} from 'react-router'
import {queryStringToNestedObject} from '../helpers/misc'

const useQueryObject = <T extends Record<string, any>>(): T => {
  const location = useLocation()

  return queryStringToNestedObject(location.search)
}

export default useQueryObject
