import {atom, PrimitiveAtom} from 'jotai'
import {atomWithCleanup, loadable} from './utils'
import {partyAtom} from './party'
import {rootServiceAtom} from './general'
import {atomWithRefresh} from 'jotai/utils'

const DEFAULT_PAGE_SIZE = 25
const DEFAULT_PAGE = 1

export const pageAtom = atomWithCleanup(DEFAULT_PAGE, true)
export const pageSizeAtom = atomWithCleanup(DEFAULT_PAGE_SIZE, true)
export const contractIdAtom = atomWithCleanup(null)

export const buyerContractsAtom = loadable(
  atomWithRefresh(async get => {
    const services = get(rootServiceAtom)
    const party = get(partyAtom)
    const page = get(pageAtom)
    const pageSize = get(pageSizeAtom)

    return await services.v2ContentService.getBuyerContracts(party.id, page, pageSize)
  }),
)

export const offerIdAtom = atomWithCleanup(null)

export const buyerContractAtom = loadable(
  atomWithRefresh(async get => {
    const services = get(rootServiceAtom)
    const party = get(partyAtom)
    const offerId = get(offerIdAtom)

    if (!offerId) {
      return null
    }

    return await services.v2ContentService.getSnapshottedCertificatePurchaseOffer(party.id, offerId)
  }),
  {nullIsLoading: true},
)

export const sellerContractAtom = loadable(
  atomWithRefresh(async get => {
    const services = get(rootServiceAtom)
    const party = get(partyAtom)
    const contractId = get(contractIdAtom)

    if (contractId === null) return null

    const offer = await services.v2ContentService.getSellerSignedSnapshottedContract(party.id, contractId)

    return offer
  }),
  {nullIsLoading: true},
)

export const sellerContractsAtom = loadable(
  atomWithRefresh(async get => {
    const services = get(rootServiceAtom)
    const party = get(partyAtom)
    const page = get(pageAtom)
    const pageSize = get(pageSizeAtom)

    return await services.v2ContentService.getSalesContracts(party.id, page, pageSize)
  }),
)
