import ITheme, {OfferPriceRange} from '../ITheme'
import logo from './logo.svg'
import greenLogo from './green-logo.webp'
import favicon from './favicon-32x32.png'
import {Country} from '../../domain/ILocation'
import {PriceUnit} from '../../domain/IPrice'
import buyIcon from './buy-energy.svg'
import calculateIcon from './calculate.svg'
import contractIcon from './contract.svg'
import costIcon from './cost.svg'

const baseTheme: ITheme = {
  wrapHeader: false,
  tileSize: 8,
  colors: {
    primary: '#77f96f',
    primaryLight: '#77f96f80',
    primaryDark: '#32df28',
    secondary: '#000',
    common: {
      black: '#000',
      white: '#fff',
    },
    accent: '#7e95ac',
    outline: '#f2f6fa',
    toned: '#e0e5eb',
    lightGrey: '#c5d0db',
    surface: '#fdfdfd',
    clay: '#606561',
    error: '#dc3210',
    lightError: '#f2726a',
    info: '#2196f3',
    success: '#269071',
    warning: '#f0a30d',
    limeGreen: '#32df28',
    green0: '#77f96f',
    green1: '#55e879',
    green2: '#31d680',
    green3: '#00c383',
    green4: '#00b184',
    green5: '#009e81',
    green6: '#008c7b',
    green7: '#007972',
    yellow0: '#ffef55',
    yellow1: '#ffdb3d',
    yellow2: '#ffc625',
    yellow3: '#ffb00b',
    yellow4: '#ff9a00',
    yellow5: '#ff8200',
    yellow6: '#ff6700',
    yellow7: '#ff480e',
    blue0: '#81e4ff',
    blue1: '#6acdef',
    blue2: '#54b7e0',
    blue3: '#40a1cf',
    blue4: '#2d8bbe',
    blue5: '#1b76ad',
    blue6: '#0b619b',
    blue7: '#004c88',
    grey0: '#d0f1ee',
    grey1: '#c0e1df',
    grey2: '#b0d0d0',
    grey3: '#a1c0c1',
    grey4: '#92b1b3',
    grey5: '#83a1a4',
    grey6: '#759296',
    grey7: '#678388',
    nGrey0: '#e2e2e2',
    nGrey1: '#d0d0d0',
    nGrey2: '#bfbfbf',
    nGrey3: '#aeaeae',
    nGrey4: '#9d9d9d',
    nGrey5: '#8d8d8d',
    nGrey6: '#8d8d8d',
    nGrey7: '#7d7d7d',
    globalBackground: '#f5f5f3',
    contrastBackground: '#ffffff80',
    light1: '#e7e7ed',
    light2: '#7b8395',
    light3: '#f1f2f5',
    light4: '#a7acb8',
    technology: {
      BIOENERGY: '#E1BB34',
      GEOTHERMAL: '#B95000',
      HYDRO: '#004AD7',
      HYDROGEN: '#A4DBFF',
      SOLAR: '#f5e74b',
      WAVE: '#6D91B6',
      WIND: '#B0E2FF',
      GAS: '#FF5F5F',
      BATTERY: '#26557B',
    },
    siteShape: {
      COMMERCIAL: '#A4DBFF',
      MINING: '#BB5C39',
      INDUSTRIAL: '#B0C4D1',
      DATA_WAREHOUSE: '#6D91B6',
      PORT: '#006EF8',
      REAL_ESTATE: '#E1BB34',
    },
  },
  title: 'Renewabl',
  loginText: {
    heading1: 'The Easiest Way To Secure Renewable Energy Guarantees Of Origin',
    heading2: 'Make a Direct, Positive, Environmental Impact',
    heading3:
      'While buying renewable energy guarantees of origin directly from renewable energy generators, your company will be directly contributing to the development of more renewable energy projects & grid scale battery systems.',
    iconTitle1: 'Buy 24/7 hourly matched guarantees of origin instantly to secure your needs',
    iconText1:
      'Securing guarantees of origin that are correctly matched to your energy consumption usually takes months or more. Now it can be done instantly!',
    icon1: buyIcon,
    iconTitle2: 'Buy only how much you need',
    iconText2:
      'By providing access to a unique 24/7 matched guarantees of origin marketplace, we can offer each buyer the flexibility of only having to buy the amount of certificates that they need at the price that suits them best.',
    icon2: calculateIcon,
    iconTitle3: 'Buy gradually with progressive procurement',
    iconText3:
      'Renewabl provides the flexibility to purchase flexible strips of 24/7 hourly matched guarantees of origin and to buy more progressively over time.',
    icon3: contractIcon,
    iconTitle4: 'No upfront costs or platform fees',
    iconText4:
      "Your company doesn't make any commitments before being ready to buy renewable energy from a particular generator.",
    icon4: costIcon,
  },
  renewablLogo: logo,
  logo: {
    logo,
    greenLogo,
    spacing: {
      margin: 0,
    },
    height: 2.625,
  },
  favicon,
  icon: {
    width: 10,
    height: 8,
    borderSize: 0,
  },
  font: {
    primaryFont: 'Poppins',
    secondaryFont: 'DMSans',
    headings: 'Ppformula',
    numbers: 'Ppformula, sans-serif',
  },
  topNavigationFontSize: 14,
  colorBindings: {
    elementsAccent: 'primaryDark',
    textOnBrandedBackground: 'secondary',
    userBadgeBackground: 'primary',
    globalBackground: 'globalBackground',
  },
  roundness: 1,
  colorBindingsByRole: {},
  countries: Object.keys(Country) as Country[],
  priceUnit: PriceUnit.GBP_MWH,
  numberDecimals: 2,
  offer: {
    hasRiskAndOperationalFocus: false,
    hasConfigurablePriceParameters: false,
    showPriceInCentsPerKwh: false,
    showPreviewGreenIndex: false,
    showPreviewTermsAndConditions: false,
  },
  product: {
    hidePriceInList: false,
  },
  sites: {
    showAddOfferButton: false,
    showAddCustomerButton: false,
  },
  charts: {
    showActualConsumptionVolumes: true,
    height: 256,
  },
  map: {
    defaultCenter: [53.509865, -1.918092],
    defaultZoom: 6,
    maxZoom: 10,
  },
  button: {
    border: true,
    hover: null,
  },
  languageSelect: ['en'],
  offerPriceRange: OfferPriceRange.RANGE,
  showPriceInOffer: false,
  emissionFactorDefault: 0.468,
}

export default baseTheme
