import React, {useState} from 'react'
import Form from 'components/Form'
import Text from 'components/Text'
import useLocalization from 'hooks/useLocalization'
import HorizStack from 'components/HorizStack'
import Box from 'components/Box'
import useTheme from 'hooks/useTheme'
import {capitalize} from 'lodash-es'
import {Container, FormContainer} from '../Layout'
import useServices from 'hooks/useServices'
import {useAtom, useAtomValue} from 'jotai'
import {partyAtom} from 'atoms/party'
import {useHistory} from 'react-router-dom'
import {OnboardingStatus} from 'domain/IParty'
import {Heading} from '../Heading'
import {sellerOnboardingAtom} from 'atoms/onboarding'
import {getOnboardProduct, getOnboardSite} from 'domain/ISellerOnboardState'
import {CurrencyCode} from 'domain/IPrice'
import {StyledFormButton, StyledFormInput} from '../Layout/FormComponents'
import {getCurrencyByCountry} from 'helpers/price'
import {getAccreditationNum, getCertTechKind, getNumCertificates} from 'helpers/certificates'
import FormCertInput from 'components/Form/FormCertInput'
import Button from 'components/Button'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import {rootServiceAtom} from 'atoms/general'
import useFeatureFlags from 'hooks/useFeatureFlags'
import {FeatureFlags} from 'components/FeatureFlagProvider'
import CenteredLoader from 'components/CenteredLoader'
import useAlerts from 'hooks/useAlerts'
import {getPlatformUrl} from 'helpers/party'

const CreateProductForm: React.FC<{openSkipDialog: () => void}> = ({openSkipDialog}) => {
  const [disabledCerts, setDisabledCerts] = useState(false)
  const theme = useTheme()
  const {getFeatureFlag} = useFeatureFlags()
  const {translate} = useLocalization()
  const history = useHistory()
  const [party, setParty] = useAtom(partyAtom)
  const rootService = useAtomValue(rootServiceAtom)
  const {addError} = useAlerts()
  const {data: onboardResult, loading: onbLoading, error: onbError} = useAtomValue(sellerOnboardingAtom)

  const {v2ContentService} = useServices()
  const [firstCertificate, setFirstCertificate] = useState(null)
  const [lastCertificate, setLastCertificate] = useState(null)
  const numCertificates = getNumCertificates(firstCertificate, lastCertificate)
  // dont show the errors if there are other errors that need to be fixed first
  const validStartEndCerts = numCertificates !== null
  const accredNum = validStartEndCerts ? getAccreditationNum(firstCertificate) : null
  const certTechnology = getCertTechKind(accredNum)
  const allowGenerateCert = getFeatureFlag(FeatureFlags.ENABLE_CERT_GENERATION)

  const handleSubmit = async formData => {
    try {
      const res = await v2ContentService.createSellerOnbProduct(party.id, formData)

      const updatedParty = {
        ...party,
        onboardingStatus: res.onboardingStatus,
      }
      setParty(updatedParty)

      if (res.onboardingStatus === OnboardingStatus.SELLER_PRODUCT_CREATED) {
        history.push(`${getPlatformUrl(party)}/onboarding/review`)
      } else {
        console.error(`Invalid onboarding state ${res}`)
        addError(translate('Something went wrong'))
      }
    } catch (ex) {
      addError('Failed to save product', ex?.correlationId, ex?.message)
    }
  }

  if (onbError) {
    return (
      <Container>
        <Heading size="large" color={theme.colors.common.white}>
          {translate('Something went wrong')}
        </Heading>
      </Container>
    )
  }

  if (onbLoading) {
    return (
      <Container>
        <CenteredLoader size="medium" color={theme.colors.common.white} />
      </Container>
    )
  }

  const product = getOnboardProduct(onboardResult)
  const site = getOnboardSite(onboardResult)
  //TODO: this must be in the theme
  const defaultCurrency = CurrencyCode.EUR
  const currency = getCurrencyByCountry(site?.location?.addressCountry, defaultCurrency)
  const siteTech = site?.technology
  const siteGenerationVol = site.generatedCertificates
  const isEdit = product !== null

  const isInvalidQantity = validStartEndCerts && numCertificates !== siteGenerationVol
  const isInvalidAccredNum =
    validStartEndCerts && getAccreditationNum(firstCertificate) !== getAccreditationNum(lastCertificate)
  const isTechnologyMismatch = validStartEndCerts && siteTech !== certTechnology

  const handleGenerateCertificates = async () => {
    try {
      const certs = await rootService.v2ContentService.generateOnboardingTestCertificates(party.id)
      setDisabledCerts(true)
      setFirstCertificate(certs.start)
      setLastCertificate(certs.end)
    } catch (ex) {
      addError('Failed to generate test certificates', ex?.correlationId, ex?.message)
    }
  }

  return (
    <Box fillHeight={true}>
      <FormContainer>
        <Form
          validationMode="onChange"
          onSubmit={handleSubmit}
          fillWidth={true}
          submissionFeedback={null}
          defaultValues={product}
        >
          <Text size="xxxlarge" color={theme.colors.common.black} margin={{bottom: 2}}>
            2. Create a product
          </Text>
          <StyledFormInput
            $small
            name="name"
            label={translate('Product name')}
            placeholder={translate('Insert product name')}
            required
            data-cy="name"
          />

          <StyledFormInput
            $small
            name="description"
            label={translate('Product description')}
            placeholder={translate('Insert product description')}
            required
            data-cy="description"
          />

          <StyledFormInput
            $small
            name="price"
            label={translate(`Set price, ${currency}/MWh(currency based on site's location)`)}
            type="number"
            placeholder={translate('Insert price as number with max two decimals')}
            required
            data-cy="price"
          />
          {allowGenerateCert && (
            <Box justify="end" margin={{top: 2, bottom: -4}}>
              <Button
                style={{zIndex: 99}}
                small
                type="button"
                variant="outlined"
                onClick={handleGenerateCertificates}
                data-cy="generate"
              >
                <Box direction="row" gap={1}>
                  <AutoAwesomeIcon />
                  <Text>{translate('Generate test certificates')}</Text>
                  <AutoAwesomeIcon />
                </Box>
              </Button>
            </Box>
          )}
          <FormCertInput
            onChange={val => setFirstCertificate(val)}
            contrast
            staticValue={firstCertificate}
            disabled={disabledCerts}
            name="firstCertificate"
            label={translate('Start certificate number')}
            required
          />
          <FormCertInput
            onChange={val => setLastCertificate(val)}
            staticValue={lastCertificate}
            contrast
            disabled={disabledCerts}
            name="lastCertificate"
            label={translate('End certificate number')}
            required
          />
          <HorizStack justify="space-between" margin={{top: 3}}>
            <Box>
              <Text>{`Uploaded site's generation`}</Text>
              <Text size="large" semibold>
                {siteGenerationVol} mWh
              </Text>
            </Box>
            <Box>
              <Text>Number of certificates</Text>
              <Text size="large" semibold>
                {numCertificates === null ? '-' : `${numCertificates} EACs`}
              </Text>
            </Box>
          </HorizStack>
          {validStartEndCerts && isInvalidQantity && (
            <Text size="mlarge" color={theme.colors.error}>
              {translate('The number of certificates does not match the site generation volume')}
            </Text>
          )}
          {validStartEndCerts && isInvalidAccredNum && (
            <Text size="mlarge" color={theme.colors.error}>
              {translate('The accreditation number(first 10 characters) of start and end do not match')}
            </Text>
          )}
          {validStartEndCerts && isTechnologyMismatch && (
            <Text size="mlarge" color={theme.colors.error}>
              {translate(
                `The uploaded site's technology(${capitalize(siteTech)}) and the certificate technology(${capitalize(
                  certTechnology,
                )}) cannot be different`,
              )}
            </Text>
          )}
          <HorizStack justify="space-between" margin={{top: 4}}>
            <Button large variant="outlined" onClick={openSkipDialog} contrast data-cy="skip">
              <Text color={theme.colors.common.black} semibold uppercase font={theme.font.secondaryFont}>
                {translate('Skip')}
              </Text>
            </Button>
            <StyledFormButton
              disabled={isInvalidQantity || isInvalidAccredNum || isTechnologyMismatch}
              data-cy="continue"
            >
              {translate(isEdit ? 'Update' : 'Continue')}
            </StyledFormButton>
          </HorizStack>
        </Form>
      </FormContainer>
    </Box>
  )
}

export default CreateProductForm
