import IProduct, {ProductState} from 'domain/IProduct'
import ITranslate from 'interfaces/ITranslate'
import {getStringifiedSiteLocation} from './site'

export function getHumanReadableState(productOrState: IProduct | ProductState, translate: ITranslate): string {
  const state: ProductState = typeof productOrState === 'string' ? productOrState : productOrState.state
  switch (state) {
    case ProductState.DRAFT:
      return translate('Upcoming')

    case ProductState.IN_OPERATION:
      return translate('Operational')

    case ProductState.RETIRED:
      return translate('Retired')
  }

  return null
}

export function getStringifiedLocation(product: IProduct, translate: ITranslate): string {
  if (!product?.site) {
    return null
  }

  return getStringifiedSiteLocation(product.site?.location, translate)
}

export function getHoverKey(product: IProduct) {
  return `PRODUCT_${product.id}`
}
