import React from 'react'
import Chart, {ChartType, getMaxValue, getMinValue, ILine} from 'components/Chart'
import {autoCeilNumber, autoFloorNumber} from 'helpers/misc'
import useTheme from 'hooks/useTheme'
import Box from 'components/Box'
import {Skeleton} from '@mui/material'

interface IProps extends React.PropsWithChildren {
  volumes: {
    name: string
    value: number
  }[]
  technology?: string
  loading?: boolean
  title: string
}

const ProposalDetailsChart: React.FC<IProps> = ({volumes, technology, loading, title}) => {
  const theme = useTheme()

  if (loading) {
    return (
      <Box direction="column" gap={3}>
        <Box direction="row" height="256px" gap={3}>
          <Box width="100%">
            <Skeleton height={256} variant="rectangular"></Skeleton>
          </Box>
        </Box>
        <Box width="100%" gap={1} pad={{bottom: 1.5}}>
          <Skeleton height={40} width={120} variant="rectangular"></Skeleton>
          <Skeleton height={40} width={160} variant="rectangular"></Skeleton>
        </Box>
      </Box>
    )
  }

  const labels = volumes?.map(({name}) => name) || []
  const productColor = technology && theme.colors.technology[technology]

  const lines: ILine[] = []

  lines.push({
    key: title,
    label: title,
    color: productColor || theme.colors.blue7,
    data: volumes?.map(({value}) => value),
    type: ChartType.LINE,
  })

  return (
    <Chart
      toolbar={{
        vertical: false,
        timescale: {hideYearly: true, hideMonthly: true},
      }}
      labels={labels}
      lines={lines}
      dark
      maxValue={autoCeilNumber(getMaxValue(lines))}
      minValue={autoFloorNumber(getMinValue(lines))}
    />
  )
}

export default ProposalDetailsChart
