import React from 'react'
import Skeleton from '@mui/material/Skeleton'
import Box from 'components/Box'
import styled from 'styled-components'
import TableSkeleton from 'components/TableSkeleton'

const StyledBox = styled(Box)`
  width: 10%;
  min-width: 100px;
  max-width: 140px;
`

const DashboardLoadingSkeleton: React.FC<React.PropsWithChildren> = () => {
  return (
    <>
      <Box direction="row" height="256px">
        <Box width="calc(100% - 320px)">
          <Skeleton height={256} variant="rectangular"></Skeleton>
        </Box>
      </Box>
      <Box width="calc(100% - 320px)">
        <TableSkeleton />
      </Box>
    </>
  )
}

export default DashboardLoadingSkeleton
