import React, {useEffect, useMemo, useState} from 'react'
import InnerScroll from 'components/InnerScroll'
import useLocalization from 'hooks/useLocalization'
import ProductDetails from 'components/Platform/Buy/CertificateDesigner/ProductDetails'
import Box from 'components/Box'
import ActionBox from 'components/Platform/ActionBox'
import ProposalDetailsInfo from 'components/Platform/Buy/CertificateDesigner/ProposalDetailsInfo'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Button from 'components/Button'
import Text from 'components/Text'
import {Link, useHistory} from 'react-router-dom'
import {useSetAtom, useAtomValue} from 'jotai'
import {rootServiceAtom} from 'atoms/general'
import {
  listingIdAtom,
  proposalDetailsAtom,
  resetDesignerState,
  getStatusMessage,
  listingAtom,
  quantityAtom,
  matchingModeAtom,
} from 'atoms/certificateDesigner'
import theme from 'theme'
import PageBase from 'components/Platform/Buy/CertificateDesigner/PageBase'
import {buyerProposalsAtom} from 'atoms/proposals'
import {Accordion, AccordionDetails, AccordionSummary, Alert, LinearProgress} from '@mui/material'
import useAlerts from 'hooks/useAlerts'
import {getPlatformUrl} from 'helpers/party'
import ConfirmationDialog from 'components/ConfirmationDialog'
import Heading from 'components/Heading'
import RawVolumesTable from './RawVolumesTable'
import {isAdmin} from 'helpers/user'
import useProfile from 'hooks/useProfile'
import GeneralFilter from './GeneralFilter'
import CertDesignerChart from './CertDesignerChart'
import {MatchStatus} from 'domain/IProposalDetails'
import SiteMap from 'components/Platform/Map/SiteMap'
import ErrorInfo from 'components/ErrorInfo'

const CertDesignerListing: React.FC<{id: string}> = ({id}) => {
  const [requestDialogOpen, setRequestDialogOpen] = useState<boolean>(false)
  const {translate} = useLocalization()
  const history = useHistory()
  const {addSuccess, addError} = useAlerts()

  const setListingId = useSetAtom(listingIdAtom)
  const quantity = useAtomValue(quantityAtom)
  const matchingMode = useAtomValue(matchingModeAtom)
  const resetState = useSetAtom(resetDesignerState)
  const {v2ContentService} = useAtomValue(rootServiceAtom)
  const {party, user} = useProfile()
  const {data: listing, loading: listingLoading, error: listingError} = useAtomValue(listingAtom)
  const refetchBuyerProposals = useSetAtom(buyerProposalsAtom)
  const {data: proposalDetails, error, loading: detailsLoading} = useAtomValue(proposalDetailsAtom) || {}

  const currentPartyCountry = party?.location?.addressCountry
  const noConsumption = proposalDetails?.matchStatus === MatchStatus.NoConsumptionForPeriod
  const mapSites = useMemo(() => [listing?.site].filter(Boolean), [listing])
  const offerPath = `${getPlatformUrl(party)}/buy/certificate-designer/offer`
  const isLoading = listingLoading || detailsLoading
  const restrictedCountry = party && currentPartyCountry !== listing?.site?.location?.addressCountry
  const hasError = getStatusMessage(proposalDetails?.matchStatus, restrictedCountry)
  const canRequest = proposalDetails?.matchStatus === MatchStatus.OK && !restrictedCountry

  useEffect(() => {
    return () => {
      resetState()
    }
  }, [])

  useEffect(() => {
    if (id) {
      setListingId(id)
    }
  }, [id])

  const handleRequest = async () => {
    try {
      const offer = await v2ContentService.saveCertificateOffer(party.id, {
        listingId: listing?.id,
        availableQuantitySnapshot: listing?.availableQuantity,
        matchedQuantitySnapshot: proposalDetails?.matchedQuantity,
        shortfallQuantitySnapshot: proposalDetails?.shortfallQuantity,
        requestedQuantity: quantity || proposalDetails?.maxAllowedToBuy,
        rounding: matchingMode,
      })
      await v2ContentService.sendToSellerReview(party.id, offer.id)
      history.push(`${offerPath}/${offer.id}/listing`)
      refetchBuyerProposals()
      addSuccess(translate('Successfully sent request'))
    } catch (e) {
      addError(translate('Failed to send request'), e?.correlationId, e?.message)
    }
  }

  // const handleSave = async () => {
  //   try {
  //     // const offer = await v2ContentService.saveCertificateOffer(party.id, filterPayload)
  //     // if (offer) {
  //     //   addSuccess(translate('Successfully saved %s', ''))
  //     //   history.push(`${offerPath}/${offer.id}/product`)
  //     // }
  //   } catch (e) {
  //     addError(translate('Failed to save %s', ''), e?.correlationId, e?.message)
  //   }
  // }

  return (
    <PageBase
      description={translate('Review certificate mix, key terms and confirm the solution')}
      error={
        (listingError || error) && (
          <ErrorInfo error={listingError || error} title={translate('Failed to load listing')} />
        )
      }
      corner={
        <Box gap={2}>
          {/* {hasMinConsumption && hasConsumption && !restrictedToBuy && (
            <Button onClick={handleSave} variant="secondary">
              {translate('Save')}
            </Button>
          )} */}
          {canRequest && (
            <Button type="button" onClick={() => setRequestDialogOpen(true)}>
              {translate('Request')}
            </Button>
          )}
          {noConsumption && (
            <Link to={`${getPlatformUrl(party)}/portfolio/sites`}>
              <Button variant="secondary">{translate('Upload consumption')}</Button>
            </Link>
          )}
        </Box>
      }
    >
      <ConfirmationDialog
        title={translate('Send purchase configuration for review?')}
        acceptText={translate('Send')}
        declineText={translate('Cancel')}
        onAccept={handleRequest}
        open={requestDialogOpen}
        setOpen={setRequestDialogOpen}
      />
      <Box>
        {hasError && (
          <Alert severity="warning" variant="outlined" sx={{mb: 2}}>
            {translate(getStatusMessage(proposalDetails?.matchStatus, restrictedCountry))}
          </Alert>
        )}
      </Box>
      <InnerScroll noRightPad>
        <Box direction="column" pad={{bottom: 2}}>
          <Box direction="row" gap={2}>
            <Box width="23%" border style={{borderRadius: '8px 8px 0 0', borderBottom: 'none'}}>
              <ActionBox title={translate('General')} fullWidth noBorder>
                <GeneralFilter
                  editable
                  status={proposalDetails?.matchStatus}
                  loading={isLoading}
                  initialLoading={proposalDetails ? false : isLoading}
                  maxQuantity={proposalDetails?.maxAllowedToBuy}
                  shortfall={proposalDetails?.shortfallQuantity}
                  matchedQuantity={proposalDetails?.matchedQuantity}
                  skippedQuantity={proposalDetails?.skippedQuantity}
                  fullCoverExcessMwh={proposalDetails?.fullCoverExcessMwh}
                  newShortfallQuantity={proposalDetails?.newShortfallQuantity}
                  month={listing?.purchaseInterval?.month}
                  year={listing?.purchaseInterval?.year}
                />
              </ActionBox>
            </Box>
            <Box
              width="77%"
              border
              style={{
                minHeight: 540,
                borderRadius: '8px 8px 0 0',
                borderBottom: 'none',
              }}
            >
              <ActionBox
                title={
                  <Box direction="row" justify="space-between" gap={2} $maxHeight="28px">
                    <Text size="mlarge" nowrap>
                      {translate('Offer details')}
                    </Text>
                  </Box>
                }
                fullWidth
                noMargin
                noBorder
              >
                <Box direction="column" gap={1.5}>
                  <ProposalDetailsInfo
                    draft
                    averagePrice={+proposalDetails?.eacPrice}
                    totalPrice={+proposalDetails?.totalPrice}
                    currency={proposalDetails?.currency}
                    greenCoverage={proposalDetails?.greenCoverage}
                    loading={proposalDetails ? false : detailsLoading}
                  />
                  <Box>
                    <CertDesignerChart
                      technology={listing?.site.productionTechnology}
                      timeseries={proposalDetails?.timeseries}
                      loading={proposalDetails ? false : detailsLoading}
                      productName={proposalDetails?.product.name}
                    />
                  </Box>
                </Box>
                <Box height={0.5}>{detailsLoading && <LinearProgress />}</Box>
              </ActionBox>
            </Box>
          </Box>

          <Box width="100%" direction="row" style={{minHeight: 295}} gap={2}>
            <Box width="23%" title={translate('Add products')} border style={{borderRadius: '0px 0px 8px 8px'}}>
              <SiteMap sites={mapSites} />
            </Box>

            <Box width="77%" border style={{borderRadius: '0 0 8px 8px'}}>
              <InnerScroll noRightPad>
                <Box>
                  <ProductDetails
                    site={listing?.site}
                    image={listing?.image}
                    totalQuantity={listing?.totalQuantity}
                    soldQuantity={listing?.soldQuantity}
                    title={listing?.product?.name}
                    description={listing?.product?.description}
                    quantity={quantity || proposalDetails?.maxAllowedToBuy}
                  />
                </Box>
              </InnerScroll>
            </Box>
          </Box>
          {isAdmin(user) && (
            <Box width="100%" margin={{top: 2}}>
              <Accordion
                sx={{
                  padding: 1,
                  mt: 0,
                  border: `1px solid ${theme.colors.lightGrey}`,
                  boxShadow: 'none',
                  borderRadius: 2,
                }}
                slotProps={{transition: {unmountOnExit: true, timeout: 100}}}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Heading>{translate('Raw volumes')}</Heading>
                </AccordionSummary>
                <AccordionDetails>
                  <RawVolumesTable />
                </AccordionDetails>
              </Accordion>
            </Box>
          )}
        </Box>
      </InnerScroll>
    </PageBase>
  )
}

export default CertDesignerListing
