import React, {ComponentType} from 'react'
import {components, OptionProps, StylesConfig} from 'react-select'
import Link from '../Link'

export interface ISmartOption {
  label: string
  value: string
  to?: string
  onClick?: (() => void) | ((e) => void) | ((e) => Promise<void>)
  color?: string
  styles?: StylesConfig<any, boolean>
}

const SmartOption: ComponentType<OptionProps<ISmartOption, boolean>> = ({...props}) => {
  const {onClick, to} = props.data

  if (to) {
    return (
      <Link to={to}>
        <components.Option {...props} />
      </Link>
    )
  }

  if (onClick) {
    return (
      <span onClick={onClick}>
        <components.Option {...props} />
      </span>
    )
  }

  return <components.Option {...props} />
}

export default SmartOption
