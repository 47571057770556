import useLocalization from '../../../../hooks/useLocalization'
import Page from '../../../Page'
import React, {useEffect, useState} from 'react'
import Button from '../../../Button'
import InnerScroll from '../../../InnerScroll'
import Link from '../../../Link'
import ProductMap from '../../Map/ProductMap'
import {useAtom, useAtomValue} from 'jotai'
import {pageAtom, pageSizeAtom, productsAtom} from 'atoms/portfolioProducts'
import {PaginateWithSelection} from 'components/Paginate/Paginate'
import LoadingSkeleton from 'components/Platform/Buy/Marketplace/LoadingSkeleton'
import {Grid, useMediaQuery} from '@mui/material'
import Box from 'components/Box'
import SimpleProductItem from './SimpleProductItem'
import {getPlatformUrl} from 'helpers/party'
import {partyAtom} from 'atoms/party'

interface IProps extends React.PropsWithChildren {
  urlPrefix: string
}
const RESULT_PER_PAGE_OPTIONS = [25, 50]

const ProductListPage: React.FC<IProps> = ({urlPrefix}) => {
  const largeScreen = useMediaQuery('(min-width:1725px)')
  const mediumScreen = useMediaQuery('(min-width:1375px)')
  const {translate} = useLocalization()
  const party = useAtomValue(partyAtom)
  const [page, setPage] = useAtom(pageAtom)
  const [pageSize, setPageSize] = useAtom(pageSizeAtom)
  const [totalPages, setTotalPages] = useState(1)
  const {data: paginatedProducts, loading, error: error} = useAtomValue(productsAtom)
  const products = paginatedProducts?.data || []

  useEffect(() => {
    if (!loading) {
      setTotalPages(paginatedProducts?.totalPages || 1)
    }
  }, [loading])

  return (
    <Page
      title={translate('Products')}
      description={translate('Create and manage products')}
      corner={
        <Link to={`${getPlatformUrl(party)}/sell/marketplace-listings/add`}>
          <Button>{translate('Add product')}</Button>
        </Link>
      }
      aside={
        <ProductMap
          urlPrefix={urlPrefix}
          title={translate('Products')}
          description={translate('Overview of products locations')}
          products={products || []}
        />
      }
    >
      <InnerScroll noRightPad>
        {loading ? (
          <LoadingSkeleton withoutFilters />
        ) : (
          <Grid container spacing={2} pb={1} pt={2}>
            {products.map(product => (
              <Grid item xl={largeScreen ? 4 : 6} xs={mediumScreen ? 6 : 12} key={product.id}>
                <SimpleProductItem key={product.id} product={product} to={`${urlPrefix}/${product.id}`} />
              </Grid>
            ))}
          </Grid>
        )}
      </InnerScroll>
      <Box align="flex-end" justify="center">
        <PaginateWithSelection
          activePage={page}
          resultsPerPage={pageSize}
          resultsPerPageOptions={RESULT_PER_PAGE_OPTIONS}
          totalPages={totalPages}
          handlePageChange={value => setPage(value)}
          handleResultsPerPageChange={value => {
            setPageSize(value)
            setPage(1)
          }}
        />
      </Box>
    </Page>
  )
}

export default ProductListPage
