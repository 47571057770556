import React from 'react'
import useLocalization from '../../../../hooks/useLocalization'
import useTheme from '../../../../hooks/useTheme'
import ResourceSummary from '../Summary/ResourceSummary'
import ResourceSummaryItem from '../Summary/ResourceSummaryItem'
import {getCurrencyByCountry, getCurrencySymbol, getPriceUnitSymbol, getSlashEnergyUnit} from 'helpers/price'
import {useAtomValue} from 'jotai'
import {partyAtom} from 'atoms/party'
import {CurrencyCode} from 'domain/IPrice'

interface IProps extends React.PropsWithChildren {
  avgVolumeMwh: number
  pricePerMwh: number
  totalVolumeMwh: number
}

const OffersSummary: React.FC<IProps> = ({avgVolumeMwh, pricePerMwh, totalVolumeMwh}) => {
  const party = useAtomValue(partyAtom)
  const {translate} = useLocalization()
  const theme = useTheme()

  return (
    <ResourceSummary>
      <ResourceSummaryItem
        title={translate('Total volume')}
        value={totalVolumeMwh}
        color={theme.colors.light2}
        unit={'EAC'}
        max={totalVolumeMwh}
      />
      <ResourceSummaryItem
        title={translate('Average volume')}
        value={avgVolumeMwh}
        max={avgVolumeMwh}
        unit={'EAC'}
        color={theme.colors.primary}
      />
    </ResourceSummary>
  )
}

export default OffersSummary
