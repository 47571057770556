import ITranslate from '../interfaces/ITranslate'
import {Country} from '../domain/ILocation'

export function getHumanReadableCountries(translate: ITranslate): {[key in Country]: string} {
  return {
    [Country.ALB]: translate('Albania'),
    [Country.AUT]: translate('Austria'),
    [Country.BEL]: translate('Belgium'),
    [Country.BIH]: translate('Bosnia and Herzegovina'),
    [Country.BGR]: translate('Bulgaria'),
    [Country.CHE]: translate('Switzerland'),
    [Country.CYP]: translate('Cyprus'),
    [Country.CZE]: translate('Czech Republic'),
    [Country.HRV]: translate('Croatia'),
    [Country.DEU]: translate('Germany'),
    [Country.DNK]: translate('Denmark'),
    [Country.ESP]: translate('Spain'),
    [Country.EST]: translate('Estonia'),
    [Country.FIN]: translate('Finland'),
    [Country.FRA]: translate('France'),
    [Country.GEO]: translate('Georgia'),
    [Country.GRC]: translate('Greece'),
    [Country.HUN]: translate('Hungary'),
    [Country.IRL]: translate('Ireland'),
    [Country.ISL]: translate('Iceland'),
    [Country.ITA]: translate('Italy'),
    [Country.LTU]: translate('Lithuania'),
    [Country.LVA]: translate('Latvia'),
    [Country.LUX]: translate('Luxembourg'),
    [Country.MNE]: translate('Montenegro'),
    [Country.NLD]: translate('Netherlands'),
    [Country.NOR]: translate('Norway'),
    [Country.PRT]: translate('Portugal'),
    [Country.ROU]: translate('Romania'),
    [Country.SRB]: translate('Serbia'),
    [Country.SVN]: translate('Slovenia'),
    [Country.SVK]: translate('Slovakia'),
    [Country.SWE]: translate('Sweden'),
    [Country.UKR]: translate('Ukraine'),
    [Country.GBR]: translate('United Kingdom'),
  }
}

export const getAllowedCountries = () => [
  Country.ESP,
  Country.DEU,
  Country.FRA,
  Country.BGR,
  Country.PRT,
  Country.GBR,
  Country.ROU,
  Country.CZE,
  Country.EST,
  Country.AUT,
  Country.IRL,
  Country.SVK,
  Country.NLD,
  Country.BEL,
  Country.CHE,
]

export function getCountryVatRegex(country: Country): RegExp {
  return new RegExp('^(' + getCountryVatPattern(country) + ')$', 'i')
}

// The list of countries and their vat regex is taken from https://stackoverflow.com/questions/33625770/check-vat-number-for-syntactical-correctness-with-regex-possible/33627030#33627030
export function getCountryVatPattern(country: Country): string {
  switch (country) {
    case Country.AUT:
      return '(AT)?U[0-9]{8}|'
    case Country.BEL:
      return '(BE)?[0-1][0-9]{9}|'
    case Country.BGR:
      return '(BG)?[0-9]{9,10}|'
    case Country.CHE:
      return '(CHE)?[-]?[0-9]{3}[.]?[0-9]{3}[.]?[0-9]{3}[ ]?(MWST|TVA|IVA)|'
    case Country.HRV:
      return '(HR)?[0-9]{11}|'
    case Country.CYP:
      return '(CY)?[0-9]{8}[A-Z]|'
    case Country.CZE:
      return '(CZ)?[0-9]{8,10}|'
    case Country.DEU:
      return '(DE)?[0-9]{9}|'
    case Country.DNK:
      return '(DK)?[0-9]{8}|'
    case Country.EST:
      return '(EE)?[0-9]{9}|'
    case Country.GRC:
      return '(EL)?[0-9]{9}|'
    case Country.ESP:
      return '(ES)?[A-Z][0-9]{7}(?:[0-9]|[A-Z])|'
    case Country.FIN:
      return '(FI)?[0-9]{8}|'
    case Country.FRA:
      return '(FR)?[0-9A-Z]{2}[0-9]{9}|'
    case Country.GBR:
      return '(GB)?[0-9]{9,12}|'
    case Country.HUN:
      return '(HU)?[0-9]{8}|'
    case Country.IRL:
      return '(IE)?([0-9]{7}[A-Z]{1,2}|[0-9][A-Z][0-9]{5}[A-Z])|'
    case Country.ITA:
      return '(IT)?[0-9]{11}|'
    case Country.LTU:
      return '(LT)?([0-9]{9}|[0-9]{12})|'
    case Country.LUX:
      return '(LU)?[0-9]{8}|'
    case Country.LVA:
      return '(LV)?[0-9]{11}|'
    case Country.NLD:
      return '(NL)?[0-9]{9}B[0-9]{2}|'
    case Country.PRT:
      return '(PT)?[0-9]{9}|'
    case Country.ROU:
      return '(RO)?[0-9]{2,10}|'
    case Country.SWE:
      return '(SE)?[0-9]{12}|'
    case Country.SVN:
      return '(SI)?[0-9]{8}|'
    case Country.SVK:
      return '(SK)?[0-9]{10}|'
    default:
      return ''
  }
}

export function getCountryVatErrorMessage(country: Country): string {
  switch (country) {
    case Country.AUT:
      return "Must be a valid VAT for Austria: 'ATU12345678' or 'U12345678' (prefix 'AT' is optional, followed by 'U' and 8 digits)."
    case Country.BEL:
      return "Must be a valid VAT for Belgium: 'BE0123456789' or '0123456789' (prefix 'BE' is optional, followed by 10 digits)."
    case Country.BGR:
      return "Must be a valid VAT for Bulgaria: 'BG123456789' or 'BG1234567890' (prefix 'BG' is optional, followed by 9 or 10 digits)."
    case Country.CHE:
      return "Must be a valid VAT for Switzerland: 'CHE-123456789MWST' or '123456789TVA' (prefix 'CHE' is optional, followed by 9 digits and 'MWST' or 'TVA' or 'IVA')."
    case Country.HRV:
      return "Must be a valid VAT for Croatia: 'HR12345678901' (prefix 'HR' is optional, followed by 11 digits)."
    case Country.CYP:
      return "Must be a valid VAT for Cyprus: 'CY12345678X' or '12345678X' (prefix 'CY' is optional, followed by 8 digits and 1 letter)."
    case Country.CZE:
      return "Must be a valid VAT for Czech Republic: 'CZ12345678', 'CZ123456789', or 'CZ1234567890' (prefix 'CZ' is optional, followed by 8-10 digits)."
    case Country.DEU:
      return "Must be a valid VAT for Germany: 'DE123456789' or '123456789' (prefix 'DE' is optional, followed by 9 digits)."
    case Country.DNK:
      return "Must be a valid VAT for Denmark: 'DK12345678' or '12345678' (prefix 'DK' is optional, followed by 8 digits)."
    case Country.EST:
      return "Must be a valid VAT for Estonia: 'EE123456789' or '123456789' (prefix 'EE' is optional, followed by 9 digits)."
    case Country.GRC:
      return "Must be a valid VAT for Greece: 'EL123456789' or '123456789' (prefix 'EL' is optional, followed by 9 digits)."
    case Country.ESP:
      return "Must be a valid VAT for Spain: 'ESA12345678' or 'A12345678' (prefix 'ES' is optional, followed by 1 letter, 7 digits, and 1 digit or letter)."
    case Country.FIN:
      return "Must be a valid VAT for Finland: 'FI12345678' or '12345678' (prefix 'FI' is optional, followed by 8 digits)."
    case Country.FRA:
      return "Must be a valid VAT for France: 'FRXX123456789' or 'XX123456789' (prefix 'FR' is optional, followed by 2 alphanumeric characters and 9 digits)."
    case Country.GBR:
      return "Must be a valid VAT for United Kingdom: 'GB123456789', 'GB123456789012', or '123456789' (prefix 'GB' is optional, followed by 9 or 12 digits)."
    case Country.HUN:
      return "Must be a valid VAT for Hungary: 'HU12345678' or '12345678' (prefix 'HU' is optional, followed by 8 digits)."
    case Country.IRL:
      return "Must be a valid VAT for Ireland: 'IE1234567X' or '1234567X' (prefix 'IE' is optional, followed by 7 digits and 1-2 letters)."
    case Country.ITA:
      return "Must be a valid VAT for Italy: 'IT12345678901' or '12345678901' (prefix 'IT' is optional, followed by 11 digits)."
    case Country.LTU:
      return "Must be a valid VAT for Lithuania: 'LT123456789' or 'LT123456789012' (prefix 'LT' is optional, followed by 9 or 12 digits)."
    case Country.LUX:
      return "Must be a valid VAT for Luxembourg: 'LU12345678' or '12345678' (prefix 'LU' is optional, followed by 8 digits)."
    case Country.LVA:
      return "Must be a valid VAT for Latvia: 'LV12345678901' or '12345678901' (prefix 'LV' is optional, followed by 11 digits)."
    case Country.NLD:
      return "Must be a valid VAT for Netherlands: 'NL123456789B01' or '123456789B01' (prefix 'NL' is optional, followed by 9 digits, 'B', and 2 digits)."
    case Country.PRT:
      return "Must be a valid VAT for Portugal: 'PT123456789' or '123456789' (prefix 'PT' is optional, followed by 9 digits)."
    case Country.ROU:
      return "Must be a valid VAT for Romania: 'RO123456789' (prefix 'RO' is optional, followed by 2 to 10 digits)."
    case Country.SWE:
      return "Must be a valid VAT for Sweden: 'SE123456789012' or '123456789012' (prefix 'SE' is optional, followed by 12 digits)."
    case Country.SVN:
      return "Must be a valid VAT for Slovenia: 'SI12345678' or '12345678' (prefix 'SI' is optional, followed by 8 digits)."
    case Country.SVK:
      return "Must be a valid VAT for Slovakia: 'SK1234567890' or '1234567890' (prefix 'SK' is optional, followed by 10 digits)."
    default:
      return `${country} is not supported.`
  }
}

export function getHumanReadableCountry(country: Country, translate: ITranslate) {
  const countries = getHumanReadableCountries(translate)

  return countries[country] || country
}

export function getProductCountry(product, translate: ITranslate) {
  const country = product?.site?.location?.addressCountry
  if (country) {
    return getHumanReadableCountry(country, translate)
  }

  return getHumanReadableCountry(Country.GBR, translate)
}
