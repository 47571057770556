import {buyerContractsAtom} from 'atoms/contracts'
import {partyAtom} from 'atoms/party'
import CardListSkeleton from 'components/CardListSkeleton'
import {useAtomValue} from 'jotai'
import React from 'react'
import {Redirect} from 'react-router-dom'
import {getPlatformUrl} from 'helpers/party'
import InnerScroll from 'components/InnerScroll'
import Page, {IProps as IPageProps} from 'components/Page'
import ContractList from './ContractList'
import NoSignedContract from './NoContract/NoSignedContract'

type IProps = IPageProps

const ContractListPage: React.FC<IProps> = ({...pageProps}) => {
  const party = useAtomValue(partyAtom)

  const {data: buyerSignedContracts, loading} = useAtomValue(buyerContractsAtom)

  if (loading) {
    return (
      <Page {...pageProps}>
        <InnerScroll padded>
          <CardListSkeleton />
        </InnerScroll>
      </Page>
    )
  }

  if (buyerSignedContracts?.data.length === 1) {
    return <Redirect to={`${getPlatformUrl(party)}/portfolio/current-contracts/${buyerSignedContracts?.data[0].id}`} />
  }

  if (buyerSignedContracts.data.length <= 0) {
    return <NoSignedContract />
  }

  return (
    <Page {...pageProps}>
      <InnerScroll padded>
        <ContractList contracts={buyerSignedContracts?.data} />
      </InnerScroll>
    </Page>
  )
}

export default ContractListPage
