import {rootServiceAtom} from 'atoms/general'
import {
  basicProductsAtom,
  certificateLocationAtom,
  marketListingsAtom,
  selectProductAtom,
  uploadGenerationAtom,
} from 'atoms/marketListings'
import {partyAtom} from 'atoms/party'
import Box from 'components/Box'
import Form from 'components/Form'
import Heading from 'components/Heading'
import {
  ICertificateFormData,
  IListingDetails,
  INewQuickProduct,
  MarketListingKind,
  QuickProduct,
} from 'domain/IMarketListing'
import {TimeseriesKind} from 'domain/ISite'
import {DateFormat, formatDate} from 'helpers/date'
import useAlerts from 'hooks/useAlerts'
import useLocalization from 'hooks/useLocalization'
import {useAtomValue, useSetAtom} from 'jotai'
import React from 'react'
import {useHistory} from 'react-router-dom'
import {BaseUserKind, MeterRefKind, ProfileUploadKind} from 'services/V2ApiErrorMapper'
import {UkCertificate} from './Certificates/UkCertificate'
import {GeneralCertificate} from './Certificates/GeneralCertificate'
import {Country} from 'domain/ILocation'
import {getPlatformUrl} from 'helpers/party'

interface CertificateProps {
  newProduct: boolean
  uploadType: TimeseriesKind
  volume: number
  formRef: React.RefObject<HTMLFormElement>
  newSite: boolean
}

export const Certificate = ({newProduct, uploadType, volume, formRef, newSite}: CertificateProps) => {
  const {addError, addSuccess} = useAlerts()
  const refetchListings = useSetAtom(marketListingsAtom)
  const refreshProducts = useSetAtom(basicProductsAtom)
  const certificateLocation = useAtomValue(certificateLocationAtom)

  const history = useHistory()

  const {translate} = useLocalization()

  const selectedProduct = useAtomValue(selectProductAtom)
  const uploadGeneration = useAtomValue(uploadGenerationAtom)

  const {interval, generation} = uploadGeneration || {}

  const {id: siteId, operationsStart, lifetimeYears, prodCapacityMwac} = selectedProduct?.site || {}
  const {
    id: productId,
    name: productName,
    description: productDescription,
    imgAttachment,
  } = selectedProduct?.product || {}

  const rootService = useAtomValue(rootServiceAtom)
  const party = useAtomValue(partyAtom)

  const handleSubmit = async (certificateData: ICertificateFormData) => {
    try {
      const {builder, profileAttachment, volumePercent} = generation || {}

      const listingPayload: IListingDetails = {
        interval: {
          month: interval.month,
          year: +interval.year,
        },
        timeseries: {
          kind: uploadType,
          payload: {
            hourlyVolumes: uploadType === TimeseriesKind.BUILDER ? builder?.hourlyVolumes : undefined,
            attachmentId: uploadType === TimeseriesKind.FILE_UPLOAD ? profileAttachment?.id : undefined,
          },
        },
        price: +certificateData?.listing?.price,
        firstCertificate: certificateData?.listing?.firstCertificate,
        lastCertificate: certificateData?.listing?.lastCertificate,
        volumePercent: +volumePercent,
      }

      const sitePayload = newSite
        ? {
            kind: MarketListingKind.NEW,
            payload: {
              ...selectedProduct?.site,
              operationsStart: formatDate(operationsStart, DateFormat.YEAR_MONTH_DAY),
              lifetimeYears: +lifetimeYears,
              prodCapacityMwac: +prodCapacityMwac,
              placeId: certificateLocation.placeId,
            },
          }
        : {kind: MarketListingKind.EXISTING, payload: {id: +siteId}}

      const productPayload: QuickProduct = newProduct
        ? {
            kind: MarketListingKind.NEW,
            payload: {
              name: productName,
              description: productDescription,
              imgAttachmentId: imgAttachment?.id,
              site: sitePayload,
            } as INewQuickProduct,
          }
        : {kind: MarketListingKind.EXISTING, payload: {id: +productId}}

      await rootService.v2ContentService.createMarketListing(party.id, {
        product: productPayload,
        listingDetails: listingPayload,
      })
      refetchListings()
      refreshProducts()
      addSuccess(translate('Successfully added %s', 'marketplace listing'))
      history.push(`${getPlatformUrl(party)}/sell/marketplace-listings`)
    } catch (e) {
      const kind = e?.error?.kind
      if (kind in ProfileUploadKind) {
        addError(e.message)
      } else if (kind in MeterRefKind) {
        addError(e.message)
      } else if (kind in BaseUserKind) {
        addError(e.message)
      } else {
        addError(translate('Failed to save marketplace listing'), e?.correlationId, e?.message)
      }
    }
  }

  return (
    <Form onSubmit={handleSubmit} ref={formRef} submissionFeedback={null}>
      <Box pad={2} round border>
        <Heading margin={{bottom: 2}} size="msmall">
          {translate('3. Add certificates and price')}
        </Heading>
        {certificateLocation?.addressCountry === Country.GBR ? (
          <UkCertificate uploadType={uploadType} newProduct={newProduct} volume={volume} />
        ) : (
          <GeneralCertificate uploadType={uploadType} newProduct={newProduct} />
        )}
      </Box>
    </Form>
  )
}
