import React from 'react'
import useLocalization from 'hooks/useLocalization'
import Form from 'components/Form'
import {useHistory} from 'react-router'
import Page from '../../../Page'
import FormSubmitButton from 'components/Form/FormSubmitButton'
import InnerScroll from 'components/InnerScroll'
import {useAtomValue, useSetAtom} from 'jotai'
import {rootServiceAtom} from 'atoms/general'
import {partyAtom} from 'atoms/party'
import {DateFormat, formatDate, getCurrentYear} from 'helpers/date'
import {tenderRespondsAtom} from 'atoms/tenderResponds'
import TenderRespondForm from './TenderRespondForm'
import useAlerts from 'hooks/useAlerts'
import {getPlatformUrl} from 'helpers/party'

interface IProps extends React.PropsWithChildren {}

const AddTenderRespondPage: React.FC<IProps> = () => {
  const {translate} = useLocalization()
  const {addError, addSuccess} = useAlerts()
  const refetchResponds = useSetAtom(tenderRespondsAtom)
  const party = useAtomValue(partyAtom)
  const history = useHistory()
  const rootService = useAtomValue(rootServiceAtom)

  const handleSubmit = async (data, {setError}) => {
    const {attachment, capacityMwac, operationsStart, ...rest} = data
    try {
      await rootService.v2ContentService.createTenderRespond(party.id, {
        ...rest,
        attachmentId: attachment.id,
        capacityMwac: +capacityMwac,
        operationsStart: formatDate(operationsStart, DateFormat.YEAR_MONTH_DAY),
        year: getCurrentYear() + 1,
      })
      refetchResponds()
      addSuccess(translate('Successfully added %s', 'tender response'))
      history.push(`${getPlatformUrl(party)}/sell/tender-responses`)
    } catch (e) {
      addError(translate('Failed to save tender response'), e?.correlationId, e?.message)
    }
  }

  return (
    <Form onSubmit={handleSubmit} fillHeight submissionFeedback={null}>
      <Page
        title={translate('Add tender response')}
        description={translate('Manage information about the tender response')}
        corner={<FormSubmitButton data-cy="save-button">{translate('Save')}</FormSubmitButton>}
      >
        <InnerScroll noRightPad>
          <TenderRespondForm />
        </InnerScroll>
      </Page>
    </Form>
  )
}

export default AddTenderRespondPage
