import {PartyMemberRole} from 'domain/PartyMemberRole'
import {mockBrokerParty, mockBuyerParty1} from './mockParties'
import locations from './mockLocations'
import {CurrencyCode, PriceUnit} from 'domain/IPrice'
import IProposalDetails, {IRawProposalDetails, MatchStatus, RoundingType} from 'domain/IProposalDetails'
import {IDetailedProfile} from 'domain/IDetailedProfile'
import {RepresentationBasis} from 'domain/IPartyMember'
import {IPaginatedExtendedResult} from 'domain/IPaginateResult'
import ISite, {SiteProductionTechnology, SiteState} from 'domain/ISite'
import {Country} from 'domain/ILocation'
import ITimeseriesItem, {Consumption} from 'domain/INewTimeseriesItem'
import IProduct, {ProductState} from 'domain/IProduct'
import ICertificateOffer, {CertSchemeType} from 'domain/ICertificateOffer'
import {INewOffer, OfferKind, OfferState} from 'domain/IOffer'
import {ContractState, IBuyerContract, ISellerSignedContract, ISellerSnapshottedContract} from 'domain/IContract'
import ISellExcessRequest, {ExcessStatus} from 'domain/ISellExcessRequest'
import IExternalContract from 'domain/IExternalContract'
import {IFinancialPerformance} from 'domain/Portfolio'
import {IMarketListing, IMarketListingReport} from 'domain/IMarketListing'
import {ITenderRespond} from 'domain/ITenderRespond'
import {ISubscriptionPlan} from 'domain/IParty'
import ISubscription, {BillingMode, SubscriptionStatus} from 'domain/ISubscription'
import {ICompanyInformation} from 'domain/ICompanyInformation'
import {AuthorityType} from 'domain/AuthorityType'
import {IDetailedPartyMemberWithUsers} from 'domain/IDetailedPartyMemberWithUsers'
import ICertListing from 'domain/ICertListing'

export const detailedProfile: IDetailedProfile = {
  profile: {
    id: '1',
    login: 'test',
    firstName: 'First',
    lastName: 'Last',
    email: 'my@email.com',
    phoneNumber: '+12345678',
    phoneNumberVerified: true,
    activated: true,
    createdBy: 'testCreate',
    buyerGuideCompletedOn: '2024-01-01',
    roles: [AuthorityType.ROLE_USER, AuthorityType.ROLE_ADMIN],
  },
  memberships: [
    {
      id: 11,
      representationBasis: RepresentationBasis.MEMBER_OF_BOARD,
      party: {...mockBuyerParty1, location: locations[2]},
      partyMemberRoles: [PartyMemberRole.MANAGER, PartyMemberRole.EMPLOYEE],
    },
    {
      id: 22,
      representationBasis: RepresentationBasis.MEMBER_OF_BOARD,
      party: {...mockBrokerParty, location: locations[1]},
      partyMemberRoles: [PartyMemberRole.MANAGER, PartyMemberRole.EMPLOYEE],
    },
  ],
  themes: [],
}

export const mockSubscriptions: ISubscription[] = [
  {
    id: 1001,
    externalId: 'sub_12345abcde',
    status: SubscriptionStatus.Active,
    paymentProvider: 'Paddle',
    paymentMethod: 'CreditCard',
    partyId: 'user_789xyz',
    plan: ISubscriptionPlan.PRO,
    createdOn: '2023-05-15T10:30:00Z',
    startsOn: '2023-05-15T10:30:00Z',
    endsOn: null,
    canceledOn: null,
    pausedOn: null,
    firstBillOn: '2023-05-15T10:30:00Z',
    nextBillOn: '2023-06-15T10:30:00Z',
    amount: '49.99',
    billingMode: BillingMode.MONTHLY,
    isAutoCollect: true,
    currencyCode: CurrencyCode.USD,
  },
  {
    id: 1002,
    externalId: 'sub_67890fghij',
    status: SubscriptionStatus.PastDue,
    paymentProvider: 'Stripe',
    paymentMethod: 'PayPal',
    partyId: 'user_456uvw',
    plan: ISubscriptionPlan.ENTERPRISE,
    createdOn: '2023-01-01T00:00:00Z',
    startsOn: '2023-01-01T00:00:00Z',
    endsOn: '2024-01-01T00:00:00Z',
    canceledOn: null,
    pausedOn: null,
    firstBillOn: '2023-01-01T00:00:00Z',
    nextBillOn: '2024-01-01T00:00:00Z',
    amount: '9999.00',
    billingMode: BillingMode.YEARLY,
    isAutoCollect: false,
    currencyCode: CurrencyCode.EUR,
  },
]

export const proposalDetails: IProposalDetails = {
  matchedQuantity: 106,
  eacPrice: '2.00',
  totalPrice: '212.00',
  currency: CurrencyCode.EUR,
  matchStatus: MatchStatus.OK,
  shortfallQuantity: 134.7,
  maxAllowedToBuy: 106,
  fullCoverExcessMwh: null,
  newShortfallQuantity: 28.7,
  skippedQuantity: 0,
  greenCoverage: {
    hourlyPercent: 44.9,
    monthlyPercent: 78.69,
    yearlyPercent: 6.56,
  },
  product: {
    description: 'description',
    id: 1,
    name: 'name',
  },
  timeseries: {
    missing: [
      {
        name: '0',
        value: 13.9,
      },
      {
        name: '1',
        value: 13.67,
      },
      {
        name: '2',
        value: 14.34,
      },
      {
        name: '3',
        value: 14.12,
      },
      {
        name: '4',
        value: 14.12,
      },
      {
        name: '5',
        value: 7.84,
      },
      {
        name: '6',
        value: 1.57,
      },
      {
        name: '7',
        value: 0.45,
      },
      {
        name: '8',
        value: 0.22,
      },
      {
        name: '9',
        value: 0,
      },
      {
        name: '10',
        value: 0,
      },
      {
        name: '11',
        value: 0,
      },
      {
        name: '12',
        value: 0,
      },
      {
        name: '13',
        value: 0,
      },
      {
        name: '14',
        value: 0,
      },
      {
        name: '15',
        value: 0,
      },
      {
        name: '16',
        value: 0,
      },
      {
        name: '17',
        value: 0,
      },
      {
        name: '18',
        value: 0,
      },
      {
        name: '19',
        value: 0.9,
      },
      {
        name: '20',
        value: 13.22,
      },
      {
        name: '21',
        value: 13.45,
      },
      {
        name: '22',
        value: 13,
      },
      {
        name: '23',
        value: 13.9,
      },
    ],
    consumption: [
      {
        name: '0',
        value: 13.9,
      },
      {
        name: '1',
        value: 13.67,
      },
      {
        name: '2',
        value: 14.34,
      },
      {
        name: '3',
        value: 14.12,
      },
      {
        name: '4',
        value: 14.12,
      },
      {
        name: '5',
        value: 7.84,
      },
      {
        name: '6',
        value: 1.57,
      },
      {
        name: '7',
        value: 0.45,
      },
      {
        name: '8',
        value: 0.22,
      },
      {
        name: '9',
        value: 0,
      },
      {
        name: '10',
        value: 0,
      },
      {
        name: '11',
        value: 0,
      },
      {
        name: '12',
        value: 0,
      },
      {
        name: '13',
        value: 0,
      },
      {
        name: '14',
        value: 0,
      },
      {
        name: '15',
        value: 0,
      },
      {
        name: '16',
        value: 0,
      },
      {
        name: '17',
        value: 0,
      },
      {
        name: '18',
        value: 0,
      },
      {
        name: '19',
        value: 0.9,
      },
      {
        name: '20',
        value: 13.22,
      },
      {
        name: '21',
        value: 13.45,
      },
      {
        name: '22',
        value: 13,
      },
      {
        name: '23',
        value: 13.9,
      },
    ],
    maxAllowed: [
      {
        name: '0',
        value: 11,
      },
      {
        name: '1',
        value: 11,
      },
      {
        name: '2',
        value: 9,
      },
      {
        name: '3',
        value: 11,
      },
      {
        name: '4',
        value: 11,
      },
      {
        name: '5',
        value: 8,
      },
      {
        name: '6',
        value: 3,
      },
      {
        name: '7',
        value: 1,
      },
      {
        name: '8',
        value: 0,
      },
      {
        name: '9',
        value: 0,
      },
      {
        name: '10',
        value: 0,
      },
      {
        name: '11',
        value: 0,
      },
      {
        name: '12',
        value: 0,
      },
      {
        name: '13',
        value: 0,
      },
      {
        name: '14',
        value: 0,
      },
      {
        name: '15',
        value: 0,
      },
      {
        name: '16',
        value: 0,
      },
      {
        name: '17',
        value: 0,
      },
      {
        name: '18',
        value: 0,
      },
      {
        name: '19',
        value: 1,
      },
      {
        name: '20',
        value: 9,
      },
      {
        name: '21',
        value: 10,
      },
      {
        name: '22',
        value: 12,
      },
      {
        name: '23',
        value: 9,
      },
    ],
    purchase: [
      {
        name: '0',
        value: 11,
      },
      {
        name: '1',
        value: 11,
      },
      {
        name: '2',
        value: 9,
      },
      {
        name: '3',
        value: 11,
      },
      {
        name: '4',
        value: 11,
      },
      {
        name: '5',
        value: 8,
      },
      {
        name: '6',
        value: 3,
      },
      {
        name: '7',
        value: 1,
      },
      {
        name: '8',
        value: 0,
      },
      {
        name: '9',
        value: 0,
      },
      {
        name: '10',
        value: 0,
      },
      {
        name: '11',
        value: 0,
      },
      {
        name: '12',
        value: 0,
      },
      {
        name: '13',
        value: 0,
      },
      {
        name: '14',
        value: 0,
      },
      {
        name: '15',
        value: 0,
      },
      {
        name: '16',
        value: 0,
      },
      {
        name: '17',
        value: 0,
      },
      {
        name: '18',
        value: 0,
      },
      {
        name: '19',
        value: 1,
      },
      {
        name: '20',
        value: 9,
      },
      {
        name: '21',
        value: 10,
      },
      {
        name: '22',
        value: 12,
      },
      {
        name: '23',
        value: 9,
      },
    ],
    productAvailableNoOverlap: [
      {
        name: '0',
        value: 11,
      },
      {
        name: '1',
        value: 11,
      },
      {
        name: '2',
        value: 9,
      },
      {
        name: '3',
        value: 11,
      },
      {
        name: '4',
        value: 11,
      },
      {
        name: '5',
        value: 9,
      },
      {
        name: '6',
        value: 11,
      },
      {
        name: '7',
        value: 11,
      },
      {
        name: '8',
        value: 9,
      },
      {
        name: '9',
        value: 11,
      },
      {
        name: '10',
        value: 11,
      },
      {
        name: '11',
        value: 8,
      },
      {
        name: '12',
        value: 11,
      },
      {
        name: '13',
        value: 11,
      },
      {
        name: '14',
        value: 9,
      },
      {
        name: '15',
        value: 11,
      },
      {
        name: '16',
        value: 11,
      },
      {
        name: '17',
        value: 9,
      },
      {
        name: '18',
        value: 11,
      },
      {
        name: '19',
        value: 11,
      },
      {
        name: '20',
        value: 9,
      },
      {
        name: '21',
        value: 10,
      },
      {
        name: '22',
        value: 12,
      },
      {
        name: '23',
        value: 9,
      },
    ],
    purchaseFractions: [
      {
        name: '0',
        value: 6.07,
      },
      {
        name: '1',
        value: 6.07,
      },
      {
        name: '2',
        value: 4.97,
      },
      {
        name: '3',
        value: 5.84,
      },
      {
        name: '4',
        value: 6.07,
      },
      {
        name: '5',
        value: 5.98,
      },
      {
        name: '6',
        value: 1.88,
      },
      {
        name: '7',
        value: 0.55,
      },
      {
        name: '8',
        value: 0,
      },
      {
        name: '9',
        value: 0,
      },
      {
        name: '10',
        value: 0,
      },
      {
        name: '11',
        value: 0,
      },
      {
        name: '12',
        value: 0,
      },
      {
        name: '13',
        value: 0,
      },
      {
        name: '14',
        value: 0,
      },
      {
        name: '15',
        value: 0,
      },
      {
        name: '16',
        value: 0,
      },
      {
        name: '17',
        value: 0,
      },
      {
        name: '18',
        value: 0,
      },
      {
        name: '19',
        value: 0.78,
      },
      {
        name: '20',
        value: 5.86,
      },
      {
        name: '21',
        value: 6.19,
      },
      {
        name: '22',
        value: 7.07,
      },
      {
        name: '23',
        value: 4.97,
      },
    ],
  },
}

export const contractedVolumes = {
  summary: {
    consumptionMwh: 373.26,
    electricityMwh: 0.0,
    certificatesQuantity: 0,
  },
  contracts: [],
  consumption: [
    {
      name: '0',
      value: 4.8,
    },
    {
      name: '1',
      value: 3.54,
    },
    {
      name: '2',
      value: 2.94,
    },
    {
      name: '3',
      value: 2.83,
    },
    {
      name: '4',
      value: 3.03,
    },
    {
      name: '5',
      value: 4.69,
    },
    {
      name: '6',
      value: 8.3,
    },
    {
      name: '7',
      value: 10.46,
    },
    {
      name: '8',
      value: 15.43,
    },
    {
      name: '9',
      value: 19.99,
    },
    {
      name: '10',
      value: 22.66,
    },
    {
      name: '11',
      value: 24.83,
    },
    {
      name: '12',
      value: 26.95,
    },
    {
      name: '13',
      value: 26.22,
    },
    {
      name: '14',
      value: 26.88,
    },
    {
      name: '15',
      value: 27.58,
    },
    {
      name: '16',
      value: 28.09,
    },
    {
      name: '17',
      value: 24.47,
    },
    {
      name: '18',
      value: 23.72,
    },
    {
      name: '19',
      value: 20.53,
    },
    {
      name: '20',
      value: 17.68,
    },
    {
      name: '21',
      value: 12.53,
    },
    {
      name: '22',
      value: 8.98,
    },
    {
      name: '23',
      value: 6.11,
    },
  ],
  contractsTimeseries: [],
  externalContractsTimeseries: [],
  externalContracts: [],
}

export const coverageOverview = contractedVolumes

export const portfolioSites: IPaginatedExtendedResult<ISite> = {
  data: [
    {
      id: 162900,
      name: 'testName',
      location: {
        id: 162850,
        addressStreet: null,
        addressCity: 'Tallinn',
        addressPostCode: null,
        addressCountry: Country.EST,
        coordinatesLat: 59.43696079999999,
        coordinatesLon: 24.7535746,
        addressSubdivision: 'Harju County',
        details: 'test',
        placeId: 'ChIJvxZW35mUkkYRcGL8GG2zAAQ',
      },
      meterId: '12312334',
    },
    {
      id: 163800,
      name: 'MyNewTestName',
      location: {
        id: 164500,
        addressStreet: null,
        addressCity: 'Tallinn',
        addressPostCode: null,
        addressCountry: Country.EST,
        coordinatesLat: 59.43696079999999,
        coordinatesLon: 24.7535746,
        addressSubdivision: 'Harju County',
        details: 'Tallin is ma home',
        placeId: 'ChIJvxZW35mUkkYRcGL8GG2zAAQ',
      },
      meterId: '5151asda',
    },
    {
      id: 166450,
      name: 'TestLeoBuilder',
      location: {
        id: 166400,
        addressStreet: null,
        addressCity: 'Tallinn',
        addressPostCode: null,
        addressCountry: Country.EST,
        coordinatesLat: 59.43696079999999,
        coordinatesLon: 24.7535746,
        addressSubdivision: 'Harju County',
        details: null,
        placeId: 'ChIJvxZW35mUkkYRcGL8GG2zAAQ',
      },
      meterId: 'asd123a',
    },
  ],
  page: 1,
  pageSize: 25,
  totalElements: 3,
  totalPages: 1,
}

export const portfolioSitesDashboard = {
  summary: {
    partyTotal: [
      {
        name: '0',
        value: 36.14,
      },
      {
        name: '1',
        value: 29.75,
      },
      {
        name: '2',
        value: 27.67,
      },
      {
        name: '3',
        value: 27.76,
      },
      {
        name: '4',
        value: 35.61,
      },
      {
        name: '5',
        value: 55.7,
      },
      {
        name: '6',
        value: 75.47,
      },
      {
        name: '7',
        value: 102.26,
      },
      {
        name: '8',
        value: 137.31,
      },
      {
        name: '9',
        value: 163.44,
      },
      {
        name: '10',
        value: 183.28,
      },
      {
        name: '11',
        value: 200.62,
      },
      {
        name: '12',
        value: 202.27,
      },
      {
        name: '13',
        value: 202.06,
      },
      {
        name: '14',
        value: 207.7,
      },
      {
        name: '15',
        value: 213.18,
      },
      {
        name: '16',
        value: 198.36,
      },
      {
        name: '17',
        value: 184.56,
      },
      {
        name: '18',
        value: 163.85,
      },
      {
        name: '19',
        value: 139.88,
      },
      {
        name: '20',
        value: 108.18,
      },
      {
        name: '21',
        value: 80.94,
      },
      {
        name: '22',
        value: 59.09,
      },
      {
        name: '23',
        value: 43.89,
      },
    ],
    sitesTotal: [
      {
        name: '0',
        value: 36.14,
      },
      {
        name: '1',
        value: 29.75,
      },
      {
        name: '2',
        value: 27.67,
      },
      {
        name: '3',
        value: 27.76,
      },
      {
        name: '4',
        value: 35.61,
      },
      {
        name: '5',
        value: 55.7,
      },
      {
        name: '6',
        value: 75.47,
      },
      {
        name: '7',
        value: 102.26,
      },
      {
        name: '8',
        value: 137.31,
      },
      {
        name: '9',
        value: 163.44,
      },
      {
        name: '10',
        value: 183.28,
      },
      {
        name: '11',
        value: 200.62,
      },
      {
        name: '12',
        value: 202.27,
      },
      {
        name: '13',
        value: 202.06,
      },
      {
        name: '14',
        value: 207.7,
      },
      {
        name: '15',
        value: 213.18,
      },
      {
        name: '16',
        value: 198.36,
      },
      {
        name: '17',
        value: 184.56,
      },
      {
        name: '18',
        value: 163.85,
      },
      {
        name: '19',
        value: 139.88,
      },
      {
        name: '20',
        value: 108.18,
      },
      {
        name: '21',
        value: 80.94,
      },
      {
        name: '22',
        value: 59.09,
      },
      {
        name: '23',
        value: 43.89,
      },
    ],
  },
  sites: [
    {
      siteId: 162900,
      siteName: 'testName',
      timeseries: [
        {
          name: '0',
          value: 0.72,
        },
        {
          name: '1',
          value: 0.62,
        },
        {
          name: '2',
          value: 0.54,
        },
        {
          name: '3',
          value: 0.48,
        },
        {
          name: '4',
          value: 1.27,
        },
        {
          name: '5',
          value: 2.73,
        },
        {
          name: '6',
          value: 3.51,
        },
        {
          name: '7',
          value: 5.1,
        },
        {
          name: '8',
          value: 7.2,
        },
        {
          name: '9',
          value: 8.52,
        },
        {
          name: '10',
          value: 10.23,
        },
        {
          name: '11',
          value: 11.56,
        },
        {
          name: '12',
          value: 10.87,
        },
        {
          name: '13',
          value: 11.03,
        },
        {
          name: '14',
          value: 11.31,
        },
        {
          name: '15',
          value: 11.95,
        },
        {
          name: '16',
          value: 9.96,
        },
        {
          name: '17',
          value: 9.88,
        },
        {
          name: '18',
          value: 6.93,
        },
        {
          name: '19',
          value: 4.98,
        },
        {
          name: '20',
          value: 2.59,
        },
        {
          name: '21',
          value: 2.19,
        },
        {
          name: '22',
          value: 1.49,
        },
        {
          name: '23',
          value: 0.82,
        },
      ],
    },
    {
      siteId: 163000,
      siteName: 'testName',
      timeseries: [
        {
          name: '0',
          value: 0.72,
        },
        {
          name: '1',
          value: 0.62,
        },
        {
          name: '2',
          value: 0.54,
        },
        {
          name: '3',
          value: 0.48,
        },
        {
          name: '4',
          value: 1.27,
        },
        {
          name: '5',
          value: 2.73,
        },
        {
          name: '6',
          value: 3.51,
        },
        {
          name: '7',
          value: 5.1,
        },
        {
          name: '8',
          value: 7.2,
        },
        {
          name: '9',
          value: 8.52,
        },
        {
          name: '10',
          value: 10.23,
        },
        {
          name: '11',
          value: 11.56,
        },
        {
          name: '12',
          value: 10.87,
        },
        {
          name: '13',
          value: 11.03,
        },
        {
          name: '14',
          value: 11.31,
        },
        {
          name: '15',
          value: 11.95,
        },
        {
          name: '16',
          value: 9.96,
        },
        {
          name: '17',
          value: 9.88,
        },
        {
          name: '18',
          value: 6.93,
        },
        {
          name: '19',
          value: 4.98,
        },
        {
          name: '20',
          value: 2.59,
        },
        {
          name: '21',
          value: 2.19,
        },
        {
          name: '22',
          value: 1.49,
        },
        {
          name: '23',
          value: 0.82,
        },
      ],
    },
    {
      siteId: 105350,
      siteName: 'Site of meter ID Site SPP4',
      timeseries: [
        {
          name: '0',
          value: 3.08,
        },
        {
          name: '1',
          value: 2.24,
        },
        {
          name: '2',
          value: 1.67,
        },
        {
          name: '3',
          value: 1.95,
        },
        {
          name: '4',
          value: 3.21,
        },
        {
          name: '5',
          value: 5.97,
        },
        {
          name: '6',
          value: 7.69,
        },
        {
          name: '7',
          value: 8.81,
        },
        {
          name: '8',
          value: 12.4,
        },
        {
          name: '9',
          value: 14.36,
        },
        {
          name: '10',
          value: 15.31,
        },
        {
          name: '11',
          value: 18.45,
        },
        {
          name: '12',
          value: 19.34,
        },
        {
          name: '13',
          value: 20.35,
        },
        {
          name: '14',
          value: 22.13,
        },
        {
          name: '15',
          value: 22.6,
        },
        {
          name: '16',
          value: 20.57,
        },
        {
          name: '17',
          value: 18.62,
        },
        {
          name: '18',
          value: 20.22,
        },
        {
          name: '19',
          value: 22.43,
        },
        {
          name: '20',
          value: 20.22,
        },
        {
          name: '21',
          value: 13.18,
        },
        {
          name: '22',
          value: 6.89,
        },
        {
          name: '23',
          value: 4.18,
        },
      ],
    },
    {
      siteId: 105450,
      siteName: 'Site of meter ID Site SPP5',
      timeseries: [
        {
          name: '0',
          value: 4.54,
        },
        {
          name: '1',
          value: 2.65,
        },
        {
          name: '2',
          value: 2.98,
        },
        {
          name: '3',
          value: 4.11,
        },
        {
          name: '4',
          value: 6.25,
        },
        {
          name: '5',
          value: 11.01,
        },
        {
          name: '6',
          value: 15.67,
        },
        {
          name: '7',
          value: 21.47,
        },
        {
          name: '8',
          value: 28.79,
        },
        {
          name: '9',
          value: 33.53,
        },
        {
          name: '10',
          value: 34.68,
        },
        {
          name: '11',
          value: 34.46,
        },
        {
          name: '12',
          value: 34.82,
        },
        {
          name: '13',
          value: 33.35,
        },
        {
          name: '14',
          value: 34.46,
        },
        {
          name: '15',
          value: 35.26,
        },
        {
          name: '16',
          value: 33.08,
        },
        {
          name: '17',
          value: 30.22,
        },
        {
          name: '18',
          value: 30.07,
        },
        {
          name: '19',
          value: 28.21,
        },
        {
          name: '20',
          value: 20.82,
        },
        {
          name: '21',
          value: 13.14,
        },
        {
          name: '22',
          value: 8.84,
        },
        {
          name: '23',
          value: 6.57,
        },
      ],
    },
    {
      siteId: 105550,
      siteName: 'Site of meter ID Site SPP1',
      timeseries: [
        {
          name: '0',
          value: 5.15,
        },
        {
          name: '1',
          value: 4.52,
        },
        {
          name: '2',
          value: 4.15,
        },
        {
          name: '3',
          value: 3.45,
        },
        {
          name: '4',
          value: 5.39,
        },
        {
          name: '5',
          value: 12.07,
        },
        {
          name: '6',
          value: 19.44,
        },
        {
          name: '7',
          value: 27.63,
        },
        {
          name: '8',
          value: 39.36,
        },
        {
          name: '9',
          value: 50.44,
        },
        {
          name: '10',
          value: 60.25,
        },
        {
          name: '11',
          value: 69.15,
        },
        {
          name: '12',
          value: 69.62,
        },
        {
          name: '13',
          value: 68.09,
        },
        {
          name: '14',
          value: 70.46,
        },
        {
          name: '15',
          value: 73.48,
        },
        {
          name: '16',
          value: 69.9,
        },
        {
          name: '17',
          value: 63.61,
        },
        {
          name: '18',
          value: 53.42,
        },
        {
          name: '19',
          value: 37.75,
        },
        {
          name: '20',
          value: 24.32,
        },
        {
          name: '21',
          value: 16.42,
        },
        {
          name: '22',
          value: 12.44,
        },
        {
          name: '23',
          value: 7.23,
        },
      ],
    },
    {
      siteId: 105650,
      siteName: 'Site of meter ID Site SPP3',
      timeseries: [
        {
          name: '0',
          value: 11.04,
        },
        {
          name: '1',
          value: 9.19,
        },
        {
          name: '2',
          value: 7.82,
        },
        {
          name: '3',
          value: 7.09,
        },
        {
          name: '4',
          value: 6.24,
        },
        {
          name: '5',
          value: 5.52,
        },
        {
          name: '6',
          value: 5.93,
        },
        {
          name: '7',
          value: 8.16,
        },
        {
          name: '8',
          value: 11.56,
        },
        {
          name: '9',
          value: 13.76,
        },
        {
          name: '10',
          value: 13.58,
        },
        {
          name: '11',
          value: 13.26,
        },
        {
          name: '12',
          value: 13.57,
        },
        {
          name: '13',
          value: 13.97,
        },
        {
          name: '14',
          value: 13.71,
        },
        {
          name: '15',
          value: 13.61,
        },
        {
          name: '16',
          value: 13.73,
        },
        {
          name: '17',
          value: 13.64,
        },
        {
          name: '18',
          value: 13.75,
        },
        {
          name: '19',
          value: 14.01,
        },
        {
          name: '20',
          value: 14.36,
        },
        {
          name: '21',
          value: 14.59,
        },
        {
          name: '22',
          value: 13.84,
        },
        {
          name: '23',
          value: 12.3,
        },
      ],
    },
    {
      siteId: 105250,
      siteName: 'Site of meter ID Site SPP2',
      timeseries: [
        {
          name: '0',
          value: 1.85,
        },
        {
          name: '1',
          value: 0.96,
        },
        {
          name: '2',
          value: 1.11,
        },
        {
          name: '3',
          value: 1.39,
        },
        {
          name: '4',
          value: 2.39,
        },
        {
          name: '5',
          value: 4.63,
        },
        {
          name: '6',
          value: 7.88,
        },
        {
          name: '7',
          value: 12.56,
        },
        {
          name: '8',
          value: 15.28,
        },
        {
          name: '9',
          value: 17.45,
        },
        {
          name: '10',
          value: 20.43,
        },
        {
          name: '11',
          value: 22.3,
        },
        {
          name: '12',
          value: 23.97,
        },
        {
          name: '13',
          value: 24.88,
        },
        {
          name: '14',
          value: 24.68,
        },
        {
          name: '15',
          value: 24.05,
        },
        {
          name: '16',
          value: 22.87,
        },
        {
          name: '17',
          value: 20.51,
        },
        {
          name: '18',
          value: 17.29,
        },
        {
          name: '19',
          value: 14.21,
        },
        {
          name: '20',
          value: 12.36,
        },
        {
          name: '21',
          value: 8.7,
        },
        {
          name: '22',
          value: 4.28,
        },
        {
          name: '23',
          value: 2.82,
        },
      ],
    },
    {
      siteId: 163800,
      siteName: 'MyNewTestName',
      timeseries: [
        {
          name: '0',
          value: 0.72,
        },
        {
          name: '1',
          value: 0.62,
        },
        {
          name: '2',
          value: 0.54,
        },
        {
          name: '3',
          value: 0.48,
        },
        {
          name: '4',
          value: 1.27,
        },
        {
          name: '5',
          value: 2.73,
        },
        {
          name: '6',
          value: 3.51,
        },
        {
          name: '7',
          value: 5.1,
        },
        {
          name: '8',
          value: 7.2,
        },
        {
          name: '9',
          value: 8.52,
        },
        {
          name: '10',
          value: 10.23,
        },
        {
          name: '11',
          value: 11.56,
        },
        {
          name: '12',
          value: 10.87,
        },
        {
          name: '13',
          value: 11.03,
        },
        {
          name: '14',
          value: 11.31,
        },
        {
          name: '15',
          value: 11.95,
        },
        {
          name: '16',
          value: 9.96,
        },
        {
          name: '17',
          value: 9.88,
        },
        {
          name: '18',
          value: 6.93,
        },
        {
          name: '19',
          value: 4.98,
        },
        {
          name: '20',
          value: 2.59,
        },
        {
          name: '21',
          value: 2.19,
        },
        {
          name: '22',
          value: 1.49,
        },
        {
          name: '23',
          value: 0.82,
        },
      ],
    },
    {
      siteId: 166450,
      siteName: 'TestLeoBuilder',
      timeseries: [
        {
          name: '0',
          value: 8.33,
        },
        {
          name: '1',
          value: 8.33,
        },
        {
          name: '2',
          value: 8.33,
        },
        {
          name: '3',
          value: 8.33,
        },
        {
          name: '4',
          value: 8.33,
        },
        {
          name: '5',
          value: 8.33,
        },
        {
          name: '6',
          value: 8.33,
        },
        {
          name: '7',
          value: 8.33,
        },
        {
          name: '8',
          value: 8.33,
        },
        {
          name: '9',
          value: 8.33,
        },
        {
          name: '10',
          value: 8.33,
        },
        {
          name: '11',
          value: 8.33,
        },
        {
          name: '12',
          value: 8.33,
        },
        {
          name: '13',
          value: 8.33,
        },
        {
          name: '14',
          value: 8.33,
        },
        {
          name: '15',
          value: 8.33,
        },
        {
          name: '16',
          value: 8.33,
        },
        {
          name: '17',
          value: 8.33,
        },
        {
          name: '18',
          value: 8.33,
        },
        {
          name: '19',
          value: 8.33,
        },
        {
          name: '20',
          value: 8.33,
        },
        {
          name: '21',
          value: 8.33,
        },
        {
          name: '22',
          value: 8.33,
        },
        {
          name: '23',
          value: 8.33,
        },
      ],
    },
  ],
}

export const greenCertificates: ITimeseriesItem = {
  unit: 'MWH',
  consumption: [
    {
      name: '0',
      value: 4.8,
    },
    {
      name: '1',
      value: 3.54,
    },
    {
      name: '2',
      value: 2.94,
    },
    {
      name: '3',
      value: 2.83,
    },
    {
      name: '4',
      value: 3.03,
    },
    {
      name: '5',
      value: 4.69,
    },
    {
      name: '6',
      value: 8.3,
    },
    {
      name: '7',
      value: 10.46,
    },
    {
      name: '8',
      value: 15.43,
    },
    {
      name: '9',
      value: 19.99,
    },
    {
      name: '10',
      value: 22.66,
    },
    {
      name: '11',
      value: 24.83,
    },
    {
      name: '12',
      value: 26.95,
    },
    {
      name: '13',
      value: 26.22,
    },
    {
      name: '14',
      value: 26.88,
    },
    {
      name: '15',
      value: 27.58,
    },
    {
      name: '16',
      value: 28.09,
    },
    {
      name: '17',
      value: 24.47,
    },
    {
      name: '18',
      value: 23.72,
    },
    {
      name: '19',
      value: 20.53,
    },
    {
      name: '20',
      value: 17.68,
    },
    {
      name: '21',
      value: 12.53,
    },
    {
      name: '22',
      value: 8.98,
    },
    {
      name: '23',
      value: 6.11,
    },
  ],
  reservedCertProduction: [
    {
      name: '0',
      value: 0.0,
    },
    {
      name: '1',
      value: 0.0,
    },
    {
      name: '2',
      value: 0.0,
    },
    {
      name: '3',
      value: 0.0,
    },
    {
      name: '4',
      value: 0.0,
    },
    {
      name: '5',
      value: 0.0,
    },
    {
      name: '6',
      value: 0.0,
    },
    {
      name: '7',
      value: 0.0,
    },
    {
      name: '8',
      value: 0.0,
    },
    {
      name: '9',
      value: 0.0,
    },
    {
      name: '10',
      value: 0.0,
    },
    {
      name: '11',
      value: 0.0,
    },
    {
      name: '12',
      value: 0.0,
    },
    {
      name: '13',
      value: 0.0,
    },
    {
      name: '14',
      value: 0.0,
    },
    {
      name: '15',
      value: 0.0,
    },
    {
      name: '16',
      value: 0.0,
    },
    {
      name: '17',
      value: 0.0,
    },
    {
      name: '18',
      value: 0.0,
    },
    {
      name: '19',
      value: 0.0,
    },
    {
      name: '20',
      value: 0.0,
    },
    {
      name: '21',
      value: 0.0,
    },
    {
      name: '22',
      value: 0.0,
    },
    {
      name: '23',
      value: 0.0,
    },
  ],
  deliveredPpaProduction: [
    {
      name: '0',
      value: 0.0,
    },
    {
      name: '1',
      value: 0.0,
    },
    {
      name: '2',
      value: 1.0,
    },
    {
      name: '3',
      value: 2.0,
    },
    {
      name: '4',
      value: 3.5,
    },
    {
      name: '5',
      value: 5.0,
    },
    {
      name: '6',
      value: 7.0,
    },
    {
      name: '7',
      value: 9.0,
    },
    {
      name: '8',
      value: 11.0,
    },
    {
      name: '9',
      value: 13.0,
    },
    {
      name: '10',
      value: 15.0,
    },
    {
      name: '11',
      value: 16.5,
    },
    {
      name: '12',
      value: 17.0,
    },
    {
      name: '13',
      value: 17.0,
    },
    {
      name: '14',
      value: 16.5,
    },
    {
      name: '15',
      value: 15.0,
    },
    {
      name: '16',
      value: 13.0,
    },
    {
      name: '17',
      value: 11.0,
    },
    {
      name: '18',
      value: 9.0,
    },
    {
      name: '19',
      value: 7.0,
    },
    {
      name: '20',
      value: 5.0,
    },
    {
      name: '21',
      value: 3.5,
    },
    {
      name: '22',
      value: 2.0,
    },
    {
      name: '23',
      value: 1.0,
    },
  ],
  totalProduction: [
    {
      name: '0',
      value: 0.0,
    },
    {
      name: '1',
      value: 0.0,
    },
    {
      name: '2',
      value: 1.0,
    },
    {
      name: '3',
      value: 2.0,
    },
    {
      name: '4',
      value: 3.5,
    },
    {
      name: '5',
      value: 5.0,
    },
    {
      name: '6',
      value: 7.0,
    },
    {
      name: '7',
      value: 9.0,
    },
    {
      name: '8',
      value: 11.0,
    },
    {
      name: '9',
      value: 13.0,
    },
    {
      name: '10',
      value: 15.0,
    },
    {
      name: '11',
      value: 16.5,
    },
    {
      name: '12',
      value: 17.0,
    },
    {
      name: '13',
      value: 17.0,
    },
    {
      name: '14',
      value: 16.5,
    },
    {
      name: '15',
      value: 15.0,
    },
    {
      name: '16',
      value: 13.0,
    },
    {
      name: '17',
      value: 11.0,
    },
    {
      name: '18',
      value: 9.0,
    },
    {
      name: '19',
      value: 7.0,
    },
    {
      name: '20',
      value: 5.0,
    },
    {
      name: '21',
      value: 3.5,
    },
    {
      name: '22',
      value: 2.0,
    },
    {
      name: '23',
      value: 1.0,
    },
  ],
  production: [
    {
      name: '0',
      value: 0.0,
    },
    {
      name: '1',
      value: 0.0,
    },
    {
      name: '2',
      value: 1.0,
    },
    {
      name: '3',
      value: 2.0,
    },
    {
      name: '4',
      value: 3.5,
    },
    {
      name: '5',
      value: 5.0,
    },
    {
      name: '6',
      value: 7.0,
    },
    {
      name: '7',
      value: 9.0,
    },
    {
      name: '8',
      value: 11.0,
    },
    {
      name: '9',
      value: 13.0,
    },
    {
      name: '10',
      value: 15.0,
    },
    {
      name: '11',
      value: 16.5,
    },
    {
      name: '12',
      value: 17.0,
    },
    {
      name: '13',
      value: 17.0,
    },
    {
      name: '14',
      value: 16.5,
    },
    {
      name: '15',
      value: 15.0,
    },
    {
      name: '16',
      value: 13.0,
    },
    {
      name: '17',
      value: 11.0,
    },
    {
      name: '18',
      value: 9.0,
    },
    {
      name: '19',
      value: 7.0,
    },
    {
      name: '20',
      value: 5.0,
    },
    {
      name: '21',
      value: 3.5,
    },
    {
      name: '22',
      value: 2.0,
    },
    {
      name: '23',
      value: 1.0,
    },
  ],
  missing: [
    {
      name: '0',
      value: 4.8,
    },
    {
      name: '1',
      value: 3.54,
    },
    {
      name: '2',
      value: 1.94,
    },
    {
      name: '3',
      value: 0.83,
    },
    {
      name: '4',
      value: 0.0,
    },
    {
      name: '5',
      value: 0.0,
    },
    {
      name: '6',
      value: 1.3,
    },
    {
      name: '7',
      value: 1.46,
    },
    {
      name: '8',
      value: 4.43,
    },
    {
      name: '9',
      value: 6.99,
    },
    {
      name: '10',
      value: 7.66,
    },
    {
      name: '11',
      value: 8.33,
    },
    {
      name: '12',
      value: 9.95,
    },
    {
      name: '13',
      value: 9.22,
    },
    {
      name: '14',
      value: 10.38,
    },
    {
      name: '15',
      value: 12.58,
    },
    {
      name: '16',
      value: 15.09,
    },
    {
      name: '17',
      value: 13.47,
    },
    {
      name: '18',
      value: 14.72,
    },
    {
      name: '19',
      value: 13.53,
    },
    {
      name: '20',
      value: 12.68,
    },
    {
      name: '21',
      value: 9.03,
    },
    {
      name: '22',
      value: 6.98,
    },
    {
      name: '23',
      value: 5.11,
    },
  ],
  excess: [
    {
      name: '0',
      value: 0.0,
    },
    {
      name: '1',
      value: 0.0,
    },
    {
      name: '2',
      value: 0.0,
    },
    {
      name: '3',
      value: 0.0,
    },
    {
      name: '4',
      value: 0.0,
    },
    {
      name: '5',
      value: 0.0,
    },
    {
      name: '6',
      value: 0.0,
    },
    {
      name: '7',
      value: 0.0,
    },
    {
      name: '8',
      value: 0.0,
    },
    {
      name: '9',
      value: 0.0,
    },
    {
      name: '10',
      value: 0.0,
    },
    {
      name: '11',
      value: 0.0,
    },
    {
      name: '12',
      value: 0.0,
    },
    {
      name: '13',
      value: 0.0,
    },
    {
      name: '14',
      value: 0.0,
    },
    {
      name: '15',
      value: 0.0,
    },
    {
      name: '16',
      value: 0.0,
    },
    {
      name: '17',
      value: 0.0,
    },
    {
      name: '18',
      value: 0.0,
    },
    {
      name: '19',
      value: 0.0,
    },
    {
      name: '20',
      value: 0.0,
    },
    {
      name: '21',
      value: 0.0,
    },
    {
      name: '22',
      value: 0.0,
    },
    {
      name: '23',
      value: 0.0,
    },
  ],
  summary: {
    excess: {
      sellable: [],
      fullCoverageExcessMwh: null,
      ppaExcessMwh: null,
    },
    missing: {
      certificateQuantity: 174,
    },
  },
}

export const buyerMarketplaceProducts: IPaginatedExtendedResult<IProduct> = {
  data: [
    {
      id: 169800,
      name: 'Portugal Wind RNBL',
      state: ProductState.IN_OPERATION,
      description: 'Large wing Lisbon',
      totalVolumeForSaleMwh: 6723.0,
      salesStart: null,
      salesEnd: null,
      emissionFactor: null,
      price: {
        value: 1.12,
        unit: PriceUnit.EUR_MWH,
      },
      site: {
        id: 169900,
        name: 'Lisbon Wind',
        state: SiteState.IN_OPERATION,
        location: {
          id: 169700,
          addressStreet: null,
          addressCity: 'Cascais',
          addressPostCode: '2750',
          addressCountry: Country.PRT,
          coordinatesLat: 38.6960614,
          coordinatesLon: -9.4303646,
          addressSubdivision: 'Lisbon',
          details: null,
          placeId: 'ChIJl5q3eXPEHg0RwrsHGH47DYc',
        },
        productionTechnology: SiteProductionTechnology.WIND,
      },
      image: null,
      availableQuantity: 6723,
      soldQuantity: 0,
      totalQuantity: 6723,
      maxQuantity: 174,
      createdBy: null,
      createdDate: null,
      lastModifiedBy: null,
      lastModifiedDate: null,
    },
    {
      id: 169650,
      name: 'Supper Wind',
      state: ProductState.IN_OPERATION,
      description: 'Awesome Wind',
      totalVolumeForSaleMwh: 200.0,
      salesStart: null,
      salesEnd: null,
      emissionFactor: null,
      price: {
        value: 7.3,
        unit: PriceUnit.GBP_MWH,
      },
      site: {
        id: 169600,
        name: 'Awesome Wind 2',
        state: SiteState.IN_OPERATION,
        location: {
          id: 169550,
          addressStreet: null,
          addressCity: 'Edinburgh',
          addressPostCode: null,
          addressCountry: Country.GBR,
          coordinatesLat: 55.953252,
          coordinatesLon: -3.188267,
          addressSubdivision: 'Scotland',
          details: null,
          placeId: 'ChIJIyaYpQC4h0gRJxfnfHsU8mQ',
        },
        productionTechnology: SiteProductionTechnology.WIND,
      },
      image: null,
      availableQuantity: 200,
      soldQuantity: 0,
      totalQuantity: 200,
      maxQuantity: 137,
      createdBy: null,
      createdDate: null,
      lastModifiedBy: null,
      lastModifiedDate: null,
    },
    {
      id: 168650,
      name: 'Supper Buda',
      state: ProductState.IN_OPERATION,
      description: 'amazing stuff',
      totalVolumeForSaleMwh: 216.0,
      salesStart: null,
      salesEnd: null,
      emissionFactor: null,
      price: {
        value: 8.0,
        unit: PriceUnit.GBP_MWH,
      },
      site: {
        id: 168750,
        name: 'Super Buda',
        state: SiteState.IN_OPERATION,
        location: {
          id: 168550,
          addressStreet: 'Gresham Street',
          addressCity: null,
          addressPostCode: 'EC2V 7PG',
          addressCountry: Country.GBR,
          coordinatesLat: 51.5150538,
          coordinatesLon: -0.0929148,
          addressSubdivision: 'England',
          details: null,
          placeId: 'ChIJ1TUHs6ocdkgRr04eoFJbH40',
        },
        productionTechnology: SiteProductionTechnology.SOLAR,
      },
      image: null,
      availableQuantity: 216,
      soldQuantity: 0,
      totalQuantity: 216,
      maxQuantity: 143,
      createdBy: null,
      createdDate: null,
      lastModifiedBy: null,
      lastModifiedDate: null,
    },
  ],
  page: 1,
  pageSize: 3,
  totalElements: 3,
  totalPages: 1,
}

export const certificateOffer: ICertificateOffer = {
  id: 242650,
  listingId: 988,
  name: 'Purchase designer offer #14',
  state: OfferState.CONFIG_SENT_FOR_SELLER_REVIEW,
  party: {
    id: 208250,
    name: 'hello 1',
  },
  numMeters: 1,
  rounding: RoundingType.DOWN,
  requestedQuantitySnap: 10000,
  availableQuantitySnap: 620000,
  matchedQuantitySnap: 9672,
  purchaseInterval: {
    year: 2024,
    month: 'October',
  },
  estimatedVolume: 9672,
  contract: {
    id: 242450,
    name: 'Purchase designer contract #14',
    state: ContractState.OFFER,
  },
  matchedCerts: [],
  averagePrice: '13.00',
  totalPrice: '125736.00',
  currency: CurrencyCode.EUR,
  product: {
    id: 225050,
    name: 'Wind Britany',
    description: 'BW',
    technology: SiteProductionTechnology.WIND,
    quantity: 9672,
    certificatePrice: '13.00',
    image: null,
  },
  greenCoverage: {
    hourlyPercent: 13.75,
    monthlyPercent: 15.6,
    yearlyPercent: 1.3,
  },
  timeseries: {
    missing: [
      {
        name: '0',
        value: 0.0,
      },
      {
        name: '1',
        value: 310.0,
      },
      {
        name: '2',
        value: 620.0,
      },
      {
        name: '3',
        value: 1085.0,
      },
      {
        name: '4',
        value: 1550.0,
      },
      {
        name: '5',
        value: 2170.0,
      },
      {
        name: '6',
        value: 2790.0,
      },
      {
        name: '7',
        value: 3410.0,
      },
      {
        name: '8',
        value: 4030.0,
      },
      {
        name: '9',
        value: 4650.0,
      },
      {
        name: '10',
        value: 5115.0,
      },
      {
        name: '11',
        value: 5270.0,
      },
      {
        name: '12',
        value: 5270.0,
      },
      {
        name: '13',
        value: 5115.0,
      },
      {
        name: '14',
        value: 4650.0,
      },
      {
        name: '15',
        value: 4030.0,
      },
      {
        name: '16',
        value: 3410.0,
      },
      {
        name: '17',
        value: 2790.0,
      },
      {
        name: '18',
        value: 2170.0,
      },
      {
        name: '19',
        value: 1550.0,
      },
      {
        name: '20',
        value: 1085.0,
      },
      {
        name: '21',
        value: 620.0,
      },
      {
        name: '22',
        value: 310.0,
      },
      {
        name: '23',
        value: 0.0,
      },
    ],
    product: {
      productId: 225050,
      productName: 'Wind Britany',
      timeseries: [
        {
          name: '0',
          value: 0.0,
        },
        {
          name: '1',
          value: 48.36,
        },
        {
          name: '2',
          value: 96.72,
        },
        {
          name: '3',
          value: 169.26,
        },
        {
          name: '4',
          value: 241.8,
        },
        {
          name: '5',
          value: 338.52,
        },
        {
          name: '6',
          value: 435.24,
        },
        {
          name: '7',
          value: 531.96,
        },
        {
          name: '8',
          value: 628.68,
        },
        {
          name: '9',
          value: 725.4,
        },
        {
          name: '10',
          value: 797.94,
        },
        {
          name: '11',
          value: 822.12,
        },
        {
          name: '12',
          value: 822.12,
        },
        {
          name: '13',
          value: 797.94,
        },
        {
          name: '14',
          value: 725.4,
        },
        {
          name: '15',
          value: 628.68,
        },
        {
          name: '16',
          value: 531.96,
        },
        {
          name: '17',
          value: 435.24,
        },
        {
          name: '18',
          value: 338.52,
        },
        {
          name: '19',
          value: 241.8,
        },
        {
          name: '20',
          value: 169.26,
        },
        {
          name: '21',
          value: 96.72,
        },
        {
          name: '22',
          value: 48.36,
        },
        {
          name: '23',
          value: 0.0,
        },
      ],
    },
  },
}

export const certificateListing: ICertListing = {
  id: 988,
  product: {
    id: 225050,
    name: 'Wind Britany',
    state: ProductState.IN_OPERATION,
    description: 'BW',
    totalVolumeForSaleMwh: 3377.0,
    salessSuccessTargetPercentage: null,
    salesStart: null,
    salesEnd: null,
    createdBy: '',
    createdDate: '2025-02-06T12:36:34.816301',
    lastModifiedBy: null,
    lastModifiedDate: null,
    emissionFactor: null,
  },
  price: {
    value: 13.0,
    unit: PriceUnit.EUR_MWH,
  },
  purchaseInterval: {
    year: 2024,
    month: 'October',
  },
  site: {
    id: 223550,
    name: 'Normandie wind',
    state: SiteState.IN_OPERATION,
    operationsStart: '2009-01-01T00:00:00',
    location: {
      id: 223500,
      addressStreet: null,
      addressCity: null,
      addressPostCode: null,
      addressCountry: Country.FRA,
      coordinatesLat: 48.2020471,
      coordinatesLon: -2.9326435,
      addressSubdivision: 'Brittany',
      details: null,
      placeId: 'ChIJr45-rmHKEUgRsCTfNs2lDAE',
    },
    productionTechnology: SiteProductionTechnology.WIND,
    timeseriesId: '7de87dc1-994f-4b76-8501-651e17b31b5d',
  },
  image: null,
  availableQuantity: 620000,
  soldQuantity: 0,
  totalQuantity: 620000,
}

export const adminSellRequests: IPaginatedExtendedResult<ISellExcessRequest> = {
  data: [
    {
      id: 6,
      party: {
        id: 9701,
        name: 'AWS UK',
      },
      technologyKind: SiteProductionTechnology.HYDRO,
      quantity: 3,
      status: ExcessStatus.PENDING,
      certPrice: 5.0,
    },
    {
      id: 7,
      party: {
        id: 9701,
        name: 'AWS UK',
      },
      technologyKind: SiteProductionTechnology.SOLAR,
      quantity: 5,
      status: ExcessStatus.PENDING,
      certPrice: 6.0,
    },
  ],
  page: 1,
  pageSize: 25,
  totalElements: 2,
  totalPages: 1,
}

export const adminWarehouse = {
  unit: 'MWH',
  mixed: [
    {
      name: '0',
      value: 377023.75,
    },
    {
      name: '1',
      value: 771033.54,
    },
    {
      name: '2',
      value: 771007.6,
    },
    {
      name: '3',
      value: 771063.37,
    },
    {
      name: '4',
      value: 817024.6,
    },
    {
      name: '5',
      value: 862977.12,
    },
    {
      name: '6',
      value: 1000792.14,
    },
    {
      name: '7',
      value: 1070117.97,
    },
    {
      name: '8',
      value: 1116087.66,
    },
    {
      name: '9',
      value: 1415790.1,
    },
    {
      name: '10',
      value: 1644654.53,
    },
    {
      name: '11',
      value: 1828359.91,
    },
    {
      name: '12',
      value: 2292208.19,
    },
    {
      name: '13',
      value: 2281140.49,
    },
    {
      name: '14',
      value: 1826728.06,
    },
    {
      name: '15',
      value: 1643016.16,
    },
    {
      name: '16',
      value: 1412597.96,
    },
    {
      name: '17',
      value: 1115301.31,
    },
    {
      name: '18',
      value: 1069336.95,
    },
    {
      name: '19',
      value: 1000801.22,
    },
    {
      name: '20',
      value: 862926.2,
    },
    {
      name: '21',
      value: 816957.46,
    },
    {
      name: '22',
      value: 771052.66,
    },
    {
      name: '23',
      value: 771060.53,
    },
  ],
  hydro: [
    {
      name: '0',
      value: 186718.2,
    },
    {
      name: '1',
      value: 391141.67,
    },
    {
      name: '2',
      value: 391167.67,
    },
    {
      name: '3',
      value: 391193.67,
    },
    {
      name: '4',
      value: 391232.67,
    },
    {
      name: '5',
      value: 391271.67,
    },
    {
      name: '6',
      value: 391323.67,
    },
    {
      name: '7',
      value: 391375.67,
    },
    {
      name: '8',
      value: 391427.67,
    },
    {
      name: '9',
      value: 391479.67,
    },
    {
      name: '10',
      value: 391531.67,
    },
    {
      name: '11',
      value: 391570.67,
    },
    {
      name: '12',
      value: 391583.67,
    },
    {
      name: '13',
      value: 391583.67,
    },
    {
      name: '14',
      value: 391570.67,
    },
    {
      name: '15',
      value: 391531.67,
    },
    {
      name: '16',
      value: 391479.67,
    },
    {
      name: '17',
      value: 391427.67,
    },
    {
      name: '18',
      value: 391375.67,
    },
    {
      name: '19',
      value: 391323.67,
    },
    {
      name: '20',
      value: 391271.67,
    },
    {
      name: '21',
      value: 391232.67,
    },
    {
      name: '22',
      value: 391193.67,
    },
    {
      name: '23',
      value: 391167.67,
    },
  ],
  solar: [
    {
      name: '0',
      value: 116.76,
    },
    {
      name: '1',
      value: 9.0,
    },
    {
      name: '2',
      value: 22.0,
    },
    {
      name: '3',
      value: 35.0,
    },
    {
      name: '4',
      value: 45966.66,
    },
    {
      name: '5',
      value: 91898.32,
    },
    {
      name: '6',
      value: 229660.81,
    },
    {
      name: '7',
      value: 298950.32,
    },
    {
      name: '8',
      value: 344888.49,
    },
    {
      name: '9',
      value: 644529.35,
    },
    {
      name: '10',
      value: 873325.62,
    },
    {
      name: '11',
      value: 1056993.77,
    },
    {
      name: '12',
      value: 1520865.14,
    },
    {
      name: '13',
      value: 1509797.57,
    },
    {
      name: '14',
      value: 1055412.69,
    },
    {
      name: '15',
      value: 871744.54,
    },
    {
      name: '16',
      value: 641367.19,
    },
    {
      name: '17',
      value: 344097.95,
    },
    {
      name: '18',
      value: 298159.78,
    },
    {
      name: '19',
      value: 229660.81,
    },
    {
      name: '20',
      value: 91898.32,
    },
    {
      name: '21',
      value: 45966.66,
    },
    {
      name: '22',
      value: 35.0,
    },
    {
      name: '23',
      value: 22.0,
    },
  ],
  wind: [
    {
      name: '0',
      value: 190188.79,
    },
    {
      name: '1',
      value: 379882.87,
    },
    {
      name: '2',
      value: 379817.94,
    },
    {
      name: '3',
      value: 379834.71,
    },
    {
      name: '4',
      value: 379825.27,
    },
    {
      name: '5',
      value: 379807.12,
    },
    {
      name: '6',
      value: 379807.66,
    },
    {
      name: '7',
      value: 379791.97,
    },
    {
      name: '8',
      value: 379771.51,
    },
    {
      name: '9',
      value: 379781.08,
    },
    {
      name: '10',
      value: 379797.24,
    },
    {
      name: '11',
      value: 379795.47,
    },
    {
      name: '12',
      value: 379759.38,
    },
    {
      name: '13',
      value: 379759.26,
    },
    {
      name: '14',
      value: 379744.71,
    },
    {
      name: '15',
      value: 379739.95,
    },
    {
      name: '16',
      value: 379751.1,
    },
    {
      name: '17',
      value: 379775.69,
    },
    {
      name: '18',
      value: 379801.5,
    },
    {
      name: '19',
      value: 379816.75,
    },
    {
      name: '20',
      value: 379756.21,
    },
    {
      name: '21',
      value: 379758.13,
    },
    {
      name: '22',
      value: 379824.0,
    },
    {
      name: '23',
      value: 379870.86,
    },
  ],
  missing: [
    {
      name: '0',
      value: 113855.1,
    },
    {
      name: '1',
      value: 115041.0,
    },
    {
      name: '2',
      value: 112098.6,
    },
    {
      name: '3',
      value: 110885.3,
    },
    {
      name: '4',
      value: 109571.0,
    },
    {
      name: '5',
      value: 111333.0,
    },
    {
      name: '6',
      value: 110927.1,
    },
    {
      name: '7',
      value: 107884.3,
    },
    {
      name: '8',
      value: 109684.4,
    },
    {
      name: '9',
      value: 113480.7,
    },
    {
      name: '10',
      value: 114186.2,
    },
    {
      name: '11',
      value: 116673.2,
    },
    {
      name: '12',
      value: 118762.8,
    },
    {
      name: '13',
      value: 120772.6,
    },
    {
      name: '14',
      value: 122124.5,
    },
    {
      name: '15',
      value: 121221.8,
    },
    {
      name: '16',
      value: 121404.4,
    },
    {
      name: '17',
      value: 121067.1,
    },
    {
      name: '18',
      value: 124399.1,
    },
    {
      name: '19',
      value: 123236.5,
    },
    {
      name: '20',
      value: 121532.5,
    },
    {
      name: '21',
      value: 119393.9,
    },
    {
      name: '22',
      value: 118667.4,
    },
    {
      name: '23',
      value: 115099.9,
    },
  ],
  certificateStock: {
    mixed: 28309059,
    hydro: 9188177,
    solar: 10195424,
    wind: 8925459,
  },
}

export const consumption: Consumption[] = [
  {name: '0', value: 0},
  {name: '1', value: 1},
  {name: '2', value: 2},
  {name: '3', value: 3},
  {name: '4', value: 4},
  {name: '5', value: 5},
  {name: '6', value: 6},
  {name: '7', value: 7},
  {name: '8', value: 8},
  {name: '9', value: 9},
  {name: '10', value: 10},
  {name: '11', value: 11},
  {name: '12', value: 12},
  {name: '13', value: 13},
  {name: '14', value: 14},
  {name: '15', value: 15},
  {name: '16', value: 16},
  {name: '17', value: 17},
  {name: '18', value: 18},
  {name: '19', value: 19},
  {name: '20', value: 20},
  {name: '21', value: 21},
  {name: '22', value: 22},
  {name: '23', value: 23},
  {name: '24', value: 24},
]

export const externalContracts: IPaginatedExtendedResult<IExternalContract> = {
  data: [
    {
      id: 6,
      name: 'BSWG160 - Bunschotenweg DC1 + 2 - Rotterdam',
      partyId: 104951,
      volumePercent: '100.00',
      pricePerMwh: '25.00',
      startDate: '2024-03-01',
      endDate: '2034-03-01',
    },
    {
      id: 12,
      name: 'Test',
      partyId: 104951,
      volumePercent: '100.00',
      pricePerMwh: '5.00',
      startDate: '2024-05-22',
      endDate: '2024-05-31',
    },
    {
      id: 13,
      name: 'test',
      partyId: 104951,
      volumePercent: '100.00',
      pricePerMwh: '5.00',
      startDate: '2024-06-05',
      endDate: '2024-06-07',
    },
  ],
  page: 1,
  pageSize: 25,
  totalElements: 3,
  totalPages: 1,
}

export const financialPerformance: IFinancialPerformance = {
  summary: {
    generationMwh: 193.33,
    profitCurrency: CurrencyCode.EUR,
    totalProfit: 83379.46,
    averageProfit: 27793.15,
  },
  contracts: [
    {
      id: 6,
      name: 'BSWG160 - Bunschotenweg DC1 + 2 - Rotterdam',
      price: 25.0,
      volumeMwh: 193.33333333333331,
      totalPrice: 4833.33,
      averageMarketPrice: 68.87,
      totalProfit: 83379.46,
      averageProfit: 431.27,
    },
    {
      id: 12,
      name: 'Test',
      price: 5.0,
      volumeMwh: 0.0,
      totalPrice: 0.0,
      averageMarketPrice: 68.87,
      totalProfit: 0.0,
      averageProfit: 0.0,
    },
    {
      id: 13,
      name: 'test',
      price: 5.0,
      volumeMwh: 0.0,
      totalPrice: 0.0,
      averageMarketPrice: 68.87,
      totalProfit: 0.0,
      averageProfit: 0.0,
    },
  ],
  contractsTimeseries: [
    {
      contractId: 6,
      timeseries: {
        currency: CurrencyCode.EUR,
        series: [
          {
            name: '0',
            value: 0.0,
          },
          {
            name: '1',
            value: 0.0,
          },
          {
            name: '2',
            value: 3298.67,
          },
          {
            name: '3',
            value: 3317.33,
          },
          {
            name: '4',
            value: 3952.4700000000003,
          },
          {
            name: '5',
            value: 3913.94,
          },
          {
            name: '6',
            value: 4453.4400000000005,
          },
          {
            name: '7',
            value: 4233.28,
          },
          {
            name: '8',
            value: 3726.57,
          },
          {
            name: '9',
            value: 2998.62,
          },
          {
            name: '10',
            value: 3195.09,
          },
          {
            name: '11',
            value: 3063.11,
          },
          {
            name: '12',
            value: 2063.07,
          },
          {
            name: '13',
            value: 1583.73,
          },
          {
            name: '14',
            value: 2605.4900000000002,
          },
          {
            name: '15',
            value: 3181.26,
          },
          {
            name: '16',
            value: 4069.98,
          },
          {
            name: '17',
            value: 4775.47,
          },
          {
            name: '18',
            value: 5734.1,
          },
          {
            name: '19',
            value: 5747.96,
          },
          {
            name: '20',
            value: 5161.75,
          },
          {
            name: '21',
            value: 4771.91,
          },
          {
            name: '22',
            value: 4009.78,
          },
          {
            name: '23',
            value: 3522.4300000000003,
          },
        ],
      },
    },
    {
      contractId: 12,
      timeseries: {
        currency: CurrencyCode.EUR,
        series: [
          {
            name: '0',
            value: 0.0,
          },
          {
            name: '1',
            value: 0.0,
          },
          {
            name: '2',
            value: 0.0,
          },
          {
            name: '3',
            value: 0.0,
          },
          {
            name: '4',
            value: 0.0,
          },
          {
            name: '5',
            value: 0.0,
          },
          {
            name: '6',
            value: 0.0,
          },
          {
            name: '7',
            value: 0.0,
          },
          {
            name: '8',
            value: 0.0,
          },
          {
            name: '9',
            value: 0.0,
          },
          {
            name: '10',
            value: 0.0,
          },
          {
            name: '11',
            value: 0.0,
          },
          {
            name: '12',
            value: 0.0,
          },
          {
            name: '13',
            value: 0.0,
          },
          {
            name: '14',
            value: 0.0,
          },
          {
            name: '15',
            value: 0.0,
          },
          {
            name: '16',
            value: 0.0,
          },
          {
            name: '17',
            value: 0.0,
          },
          {
            name: '18',
            value: 0.0,
          },
          {
            name: '19',
            value: 0.0,
          },
          {
            name: '20',
            value: 0.0,
          },
          {
            name: '21',
            value: 0.0,
          },
          {
            name: '22',
            value: 0.0,
          },
          {
            name: '23',
            value: 0.0,
          },
        ],
      },
    },
    {
      contractId: 13,
      timeseries: {
        currency: CurrencyCode.EUR,
        series: [
          {
            name: '0',
            value: 0.0,
          },
          {
            name: '1',
            value: 0.0,
          },
          {
            name: '2',
            value: 0.0,
          },
          {
            name: '3',
            value: 0.0,
          },
          {
            name: '4',
            value: 0.0,
          },
          {
            name: '5',
            value: 0.0,
          },
          {
            name: '6',
            value: 0.0,
          },
          {
            name: '7',
            value: 0.0,
          },
          {
            name: '8',
            value: 0.0,
          },
          {
            name: '9',
            value: 0.0,
          },
          {
            name: '10',
            value: 0.0,
          },
          {
            name: '11',
            value: 0.0,
          },
          {
            name: '12',
            value: 0.0,
          },
          {
            name: '13',
            value: 0.0,
          },
          {
            name: '14',
            value: 0.0,
          },
          {
            name: '15',
            value: 0.0,
          },
          {
            name: '16',
            value: 0.0,
          },
          {
            name: '17',
            value: 0.0,
          },
          {
            name: '18',
            value: 0.0,
          },
          {
            name: '19',
            value: 0.0,
          },
          {
            name: '20',
            value: 0.0,
          },
          {
            name: '21',
            value: 0.0,
          },
          {
            name: '22',
            value: 0.0,
          },
          {
            name: '23',
            value: 0.0,
          },
        ],
      },
    },
  ],
}

export const marketListings: IPaginatedExtendedResult<IMarketListing> = {
  data: [
    {
      id: 962,
      productId: 140050,
      productName: 'LeoTestProductName12Q',
      currency: CurrencyCode.EUR,
      certificateId: 1059,
      startDate: '2024-04-01',
      certPrice: 23.0,
      totalQuantity: 300,
      soldQuantity: 0,
      availableQuantity: 300,
    },
    {
      id: 961,
      productId: 140050,
      productName: 'LeoTestProductName12Q',
      currency: CurrencyCode.EUR,
      certificateId: 1058,
      startDate: '2024-03-01',
      certPrice: 21.0,
      totalQuantity: 199,
      soldQuantity: 0,
      availableQuantity: 199,
    },
    {
      id: 956,
      productId: 140050,
      productName: 'LeoTestProductName12Q',
      currency: CurrencyCode.EUR,
      certificateId: 1052,
      startDate: '2024-02-01',
      certPrice: 15.0,
      totalQuantity: 136,
      soldQuantity: 0,
      availableQuantity: 136,
    },
  ],
  page: 1,
  pageSize: 25,
  totalElements: 3,
  totalPages: 1,
}

export const offers: IPaginatedExtendedResult<INewOffer> = {
  data: [
    {
      id: 110550,
      name: 'Purchase designer offer #19',
      startDate: '2024-02-01T00:00:00',
      endDate: '2024-02-29T23:59:59',
      certPrice: 10.0,
      priceUnit: PriceUnit.GBP_MWH,
      totalPrice: 2740.0,
      purchaseInterval: {
        year: 2024,
        month: 'February',
      },
      state: OfferState.CONFIG_SENT_FOR_SELLER_REVIEW,
      kind: OfferKind.CERTIFICATE_TRADE,
      validFrom: '2024-03-22T10:11:55.827785',
      validTo: '2024-04-06T10:11:55.827785',
      contract: {
        id: 110350,
        name: 'Purchase designer contract #19',
        state: ContractState.OFFER,
        signingDate: null,
        validFrom: '2024-04-01T00:00:00',
        validTo: '2024-04-30T23:59:59',
        contractType: {
          id: 1300,
          name: 'Energy Supply Agreement',
        },
        volumeMwh: 274.0,
      },
      customer: {
        id: 104951,
        name: 'Iron Mountain',
      },
    },
    {
      id: 106700,
      name: 'Purchase designer offer #4',
      startDate: '2024-02-01T00:00:00',
      endDate: '2024-02-29T23:59:59',
      certPrice: 7.1,
      priceUnit: PriceUnit.GBP_MWH,
      totalPrice: 596.4,
      purchaseInterval: {
        year: 2024,
        month: 'February',
      },
      state: OfferState.CONFIG_SENT_FOR_SELLER_REVIEW,
      kind: OfferKind.CERTIFICATE_TRADE,
      validFrom: '2024-03-22T04:17:04.487595',
      validTo: '2024-04-06T04:17:04.487595',
      contract: {
        id: 106500,
        name: 'Purchase designer contract #4',
        state: ContractState.OFFER,
        signingDate: null,
        validFrom: '2024-04-01T00:00:00',
        validTo: '2024-04-30T23:59:59',
        contractType: {
          id: 1300,
          name: 'Energy Supply Agreement',
        },
        volumeMwh: 84.0,
      },
      customer: {
        id: 104951,
        name: 'Iron Mountain',
      },
    },
    {
      id: 84700,
      name: 'Purchase designer offer #15',
      startDate: '2024-01-01T00:00:00',
      endDate: '2024-01-31T23:59:59',
      certPrice: 10.3,
      priceUnit: PriceUnit.GBP_MWH,
      totalPrice: 1545.0,
      purchaseInterval: {
        year: 2024,
        month: 'January',
      },
      state: OfferState.CONFIG_SENT_FOR_SELLER_REVIEW,
      kind: OfferKind.CERTIFICATE_TRADE,
      validFrom: '2024-02-06T08:40:21.195094',
      validTo: '2024-02-21T08:40:21.195094',
      contract: {
        id: 84500,
        name: 'Purchase designer contract #15',
        state: ContractState.OFFER,
        signingDate: null,
        validFrom: '2024-03-01T00:00:00',
        validTo: '2024-03-31T23:59:59',
        contractType: {
          id: 1300,
          name: 'Energy Supply Agreement',
        },
        volumeMwh: 150.0,
      },
      customer: {
        id: 73001,
        name: 'T Charger',
      },
    },
  ],
  page: 1,
  pageSize: 25,
  totalElements: 3,
  totalPages: 1,
}

export const marketListingCertReport: IMarketListingReport = {
  hourly: [
    {
      contracts: [
        {
          certs: {
            certScheme: CertSchemeType.OFGEM,
            end: 'G02635NWSC1723801283010624300624GEN',
            start: 'G02635NWSC1723801284010624300624GEN',
          },
          id: 7200,
        },
        {
          certs: {
            certScheme: CertSchemeType.OFGEM,
            end: 'G02635NWSC1723801283010624300624GEN',
            start: 'G02635NWSC1723801284010624300624GEN',
          },
          id: 7450,
        },
      ],
      gap: {
        certScheme: CertSchemeType.OFGEM,
        end: 'G02635NWSC1723801283010624300624GEN',
        start: 'G02635NWSC1723801282010624300624GEN',
      },
      timestamp: '2024-06-01T00:00:00Z',
      total: {
        certScheme: CertSchemeType.OFGEM,
        end: 'G02635NWSC1723801285010624300624GEN',
        start: 'G02635NWSC1723801281010624300624GEN',
      },
    },
    {
      contracts: [
        {
          certs: {
            certScheme: CertSchemeType.OFGEM,
            end: 'G02635NWSC1723801285010624300624GEN',
            start: 'G02635NWSC1723801286010624300624GEN',
          },
          id: 7200,
        },
        {
          certs: {
            certScheme: CertSchemeType.OFGEM,
            end: 'G02635NWSC1723801285010624300624GEN',
            start: 'G02635NWSC1723801286010624300624GEN',
          },
          id: 7450,
        },
      ],
      gap: null,
      timestamp: '2024-06-01T01:00:00Z',
      total: {
        certScheme: CertSchemeType.OFGEM,
        end: 'G02635NWSC1723801287010624300624GEN',
        start: 'G02635NWSC1723801283010624300624GEN',
      },
    },
    {
      contracts: [
        {
          certs: null,
          id: 7200,
        },
        {
          certs: {
            certScheme: CertSchemeType.OFGEM,
            end: 'G02635NWSC1723801287010624300624GEN',
            start: 'G02635NWSC1723801288010624300624GEN',
          },
          id: 7450,
        },
      ],
      gap: {
        certScheme: CertSchemeType.OFGEM,
        end: 'G02635NWSC1723801287010624300624GEN',
        start: 'G02635NWSC1723801286010624300624GEN',
      },
      timestamp: '2024-06-01T02:00:00Z',
      total: {
        certScheme: CertSchemeType.OFGEM,
        end: 'G02635NWSC1723801289010624300624GEN',
        start: 'G02635NWSC1723801285010624300624GEN',
      },
    },
  ],
}

export const tenderResponses: IPaginatedExtendedResult<ITenderRespond> = {
  data: [
    {
      id: 1,
      price: '10.00',
      siteName: 'Test',
      technology: SiteProductionTechnology.HYDRO,
      state: SiteState.IN_CONSTRUCTION,
      capacityMwac: 6002.0,
      operationsStart: '2026-06-29',
      attachmentId: '1',
      year: 2025,
    },
    {
      id: 2,
      price: '100.00',
      siteName: 'test2',
      technology: SiteProductionTechnology.WIND,
      state: SiteState.IN_CONSTRUCTION,
      capacityMwac: 1000.0,
      operationsStart: '2012-12-12',
      attachmentId: '2',
      year: 2025,
    },
  ],
  page: 1,
  pageSize: 25,
  totalElements: 2,
  totalPages: 1,
}

export const buyerContracts: IPaginatedExtendedResult<IBuyerContract> = {
  data: [
    {
      id: 1,
      name: 'Contract 1',
      state: ContractState.ACTIVE,
      startDate: '2024-01-01',
      endDate: '2024-01-31',
      certPrice: 5,
      priceUnit: PriceUnit.GBP_MWH,
      totalPrice: 500,
      purchaseInterval: {
        year: 0,
        month: '',
      },
      kind: '',
      validFrom: '2024-01-01',
      validTo: '2024-01-31',
      seller: undefined,
      contract: {
        id: 1,
        name: 'Sample Contract',
        state: ContractState.ACTIVE,
        signingDate: '2024-01-01',
        validFrom: '2024-01-01',
        validTo: '2024-01-31',
        volumeMwh: 1000,
      },
    },
    {
      id: 2,
      name: 'Contract 2',
      state: ContractState.ACTIVE,
      startDate: '2024-02-01',
      endDate: '2024-02-28',
      certPrice: 200,
      priceUnit: PriceUnit.GBP_MWH,
      totalPrice: 10000,
      purchaseInterval: {
        year: 0,
        month: '',
      },
      kind: '',
      validFrom: '2024-02-01',
      validTo: '2024-02-28',
      seller: undefined,
      contract: {
        id: 2,
        name: 'Sample Contract 2',
        state: ContractState.ACTIVE,
        signingDate: '2024-02-01',
        validFrom: '2024-02-01',
        validTo: '2024-02-28',
        volumeMwh: 5,
      },
    },
  ],
  page: 1,
  pageSize: 25,
  totalElements: 2,
  totalPages: 1,
}

export const rawSpotPrices = {
  intervals: [
    {
      name: '2024-11-17 00:00:00',
      prices: [
        {
          country: 'GBR',
          price: '121.24999999999999',
          priceUnit: 'GBP_MWH',
        },
        {
          country: 'ESP',
          price: '117.74',
          priceUnit: 'EUR_MWH',
        },
        {
          country: 'DEU',
          price: '69.28',
          priceUnit: 'EUR_MWH',
        },
        {
          country: 'FRA',
          price: '82.16',
          priceUnit: 'EUR_MWH',
        },
        {
          country: 'BGR',
          price: '108.03',
          priceUnit: 'EUR_MWH',
        },
      ],
    },
    {
      name: '2024-11-17 01:00:00',
      prices: [
        {
          country: 'GBR',
          price: '177.66000000000000',
          priceUnit: 'GBP_MWH',
        },
        {
          country: 'ESP',
          price: '108.06',
          priceUnit: 'EUR_MWH',
        },
        {
          country: 'DEU',
          price: '72.48',
          priceUnit: 'EUR_MWH',
        },
        {
          country: 'FRA',
          price: '79.81',
          priceUnit: 'EUR_MWH',
        },
        {
          country: 'BGR',
          price: '108.14',
          priceUnit: 'EUR_MWH',
        },
      ],
    },
    {
      name: '2024-11-17 02:00:00',
      prices: [
        {
          country: 'GBR',
          price: '52.29000000000000',
          priceUnit: 'GBP_MWH',
        },
        {
          country: 'ESP',
          price: '107.74',
          priceUnit: 'EUR_MWH',
        },
        {
          country: 'DEU',
          price: '58.72',
          priceUnit: 'EUR_MWH',
        },
        {
          country: 'FRA',
          price: '67.92',
          priceUnit: 'EUR_MWH',
        },
        {
          country: 'BGR',
          price: '105.93',
          priceUnit: 'EUR_MWH',
        },
      ],
    },
    {
      name: '2024-11-17 03:00:00',
      prices: [
        {
          country: 'GBR',
          price: '177.91628807430982',
          priceUnit: 'GBP_MWH',
        },
        {
          country: 'ESP',
          price: '100',
          priceUnit: 'EUR_MWH',
        },
        {
          country: 'DEU',
          price: '42',
          priceUnit: 'EUR_MWH',
        },
        {
          country: 'FRA',
          price: '54.17',
          priceUnit: 'EUR_MWH',
        },
        {
          country: 'BGR',
          price: '106.9',
          priceUnit: 'EUR_MWH',
        },
      ],
    },
    {
      name: '2024-11-17 04:00:00',
      prices: [
        {
          country: 'GBR',
          price: '113.00000000000000',
          priceUnit: 'GBP_MWH',
        },
        {
          country: 'ESP',
          price: '103',
          priceUnit: 'EUR_MWH',
        },
        {
          country: 'DEU',
          price: '34.81',
          priceUnit: 'EUR_MWH',
        },
        {
          country: 'FRA',
          price: '50.05',
          priceUnit: 'EUR_MWH',
        },
        {
          country: 'BGR',
          price: '117.48',
          priceUnit: 'EUR_MWH',
        },
      ],
    },
  ],
}

export const managedPartyMembers: IDetailedPartyMemberWithUsers[] = [
  {
    detMember: {
      member: {
        id: 1,
        representationBasis: RepresentationBasis.CI_CUSTOMER_MANAGER,
        partyId: 1,
        createdBy: '1',
        createdDate: '2024-01-01',
        lastModifiedBy: '1',
        lastModifiedDate: '2024-01-01',
        userId: 1,
      },
      profile: {
        id: '1',
        login: 'test',
        firstName: 'Test',
        lastName: 'User',
        email: 'test@test.com',
        imageUrl: 'test.com',
        activated: true,
        createdBy: '1',
      },
      role: PartyMemberRole.MANAGER,
      partyLocation: {
        id: 1,
        addressStreet: 'London',
        addressCity: 'London',
        addressPostCode: '12345',
        addressCountry: Country.GBR,
        addressSubdivision: 'London',
        coordinatesLat: 1,
        coordinatesLon: 1,
        placeId: '1',
        searchString: '1',
      },
    },
    user: {
      id: 1,
      activated: true,
      enabled: true,
      email: 'test@test.com',
      firstName: 'Test',
      lastName: 'User',
    },
  },
]

export const aggrSpotPrices = {
  intervals: [
    {
      interval: 1,
      prices: [
        {
          country: 'GBR',
          price: '4442.56366317426290',
          missingHours: 0,
          priceUnit: 'GBP_MWH',
        },
        {
          country: 'ESP',
          price: '1968.45',
          missingHours: 0,
          priceUnit: 'EUR_MWH',
        },
        {
          country: 'DEU',
          price: '1833.85',
          missingHours: 0,
          priceUnit: 'EUR_MWH',
        },
        {
          country: 'FRA',
          price: '1947.85',
          missingHours: 0,
          priceUnit: 'EUR_MWH',
        },
        {
          country: 'BGR',
          price: '3599.46',
          missingHours: 0,
          priceUnit: 'EUR_MWH',
        },
      ],
    },
    {
      interval: 2,
      prices: [
        {
          country: 'GBR',
          price: '3967.04443734700224',
          missingHours: 0,
          priceUnit: 'GBP_MWH',
        },
        {
          country: 'ESP',
          price: '2096.94',
          missingHours: 0,
          priceUnit: 'EUR_MWH',
        },
        {
          country: 'DEU',
          price: '2567.65',
          missingHours: 0,
          priceUnit: 'EUR_MWH',
        },
        {
          country: 'FRA',
          price: '2090.99',
          missingHours: 0,
          priceUnit: 'EUR_MWH',
        },
        {
          country: 'BGR',
          price: '2562.45',
          missingHours: 0,
          priceUnit: 'EUR_MWH',
        },
      ],
    },
    {
      interval: 3,
      prices: [
        {
          country: 'GBR',
          price: '4374.02959327063218',
          missingHours: 0,
          priceUnit: 'GBP_MWH',
        },
        {
          country: 'ESP',
          price: '1732.55',
          missingHours: 0,
          priceUnit: 'EUR_MWH',
        },
        {
          country: 'DEU',
          price: '2233.27',
          missingHours: 0,
          priceUnit: 'EUR_MWH',
        },
        {
          country: 'FRA',
          price: '1898.79',
          missingHours: 0,
          priceUnit: 'EUR_MWH',
        },
        {
          country: 'BGR',
          price: '2302.50',
          missingHours: 0,
          priceUnit: 'EUR_MWH',
        },
      ],
    },
    {
      interval: 4,
      prices: [
        {
          country: 'GBR',
          price: '4374.49173407941050',
          missingHours: 0,
          priceUnit: 'GBP_MWH',
        },
        {
          country: 'ESP',
          price: '2504.26',
          missingHours: 0,
          priceUnit: 'EUR_MWH',
        },
        {
          country: 'DEU',
          price: '3030.57',
          missingHours: 0,
          priceUnit: 'EUR_MWH',
        },
        {
          country: 'FRA',
          price: '2598.62',
          missingHours: 0,
          priceUnit: 'EUR_MWH',
        },
        {
          country: 'BGR',
          price: '3168.90',
          missingHours: 0,
          priceUnit: 'EUR_MWH',
        },
      ],
    },
  ],
}

export const buyerCompanyInformation: ICompanyInformation = {
  id: 1,
  name: 'Test Company',
  registrationCode: null,
  vatCode: null,
}

export const salesContracts: IPaginatedExtendedResult<ISellerSignedContract> = {
  data: [
    {
      offer: {
        id: 226000,
        name: 'Purchase designer offer #3',
        startDate: '2025-01-01T00:00:00',
        endDate: '2025-01-31T23:59:59',
        certPrice: 0.45,
        priceUnit: 'EUR_MWH',
        totalPrice: '22.50',
        purchaseInterval: {
          year: 2025,
          month: 'January',
        },
        state: 'CONTRACT_SIGNED',
        kind: 'CERTIFICATE_TRADE',
        validFrom: '2025-02-06T13:03:39.029047',
        validTo: '2025-02-21T13:03:39.029047',
      },
      id: 225800,
      name: 'Purchase designer contract #3',
      state: 'SIGNED',
      signingDate: '2025-02-06T13:04:11.866240',
      validFrom: '2025-03-01T00:00:00',
      validTo: '2025-03-31T23:59:59',
      contractType: {
        id: 1300,
        name: 'Energy Supply Agreement',
      },
      volumeMwh: 50.0,
      site: {
        id: 223550,
        name: 'Normandie wind',
        meterReference: 'FR902938429',
        shape: 'COMMERCIAL',
        state: 'IN_OPERATION',
        description: null,
        operationsStart: '2009-01-01',
        operationsEnd: null,
        location: {
          id: 223500,
          addressStreet: null,
          addressCity: null,
          addressPostCode: null,
          addressCountry: 'FRA',
          coordinatesLat: 48.2020471,
          coordinatesLon: -2.9326435,
          createdBy: '',
          createdDate: '2025-02-06T11:44:48.271844',
          lastModifiedBy: null,
          lastModifiedDate: null,
          addressSubdivision: 'Brittany',
          details: null,
          placeId: 'ChIJr45-rmHKEUgRsCTfNs2lDAE',
        },
        productionTechnology: 'WIND',
      },
      customer: {
        id: 208250,
        name: 'hello 1',
      },
    },
  ] as unknown as ISellerSignedContract[],
  page: 1,
  pageSize: 25,
  totalElements: 3,
  totalPages: 1,
}

export const rawProposalDetails: IRawProposalDetails = {
  timeseries: {
    consumption: [
      {
        date: '2024-05-01T00:00:00',
        valueKwh: 224.1375,
      },
      {
        date: '2024-05-01T01:00:00',
        valueKwh: 224.1375,
      },
      {
        date: '2024-05-01T02:00:00',
        valueKwh: 448.275,
      },
      {
        date: '2024-05-01T03:00:00',
        valueKwh: 448.275,
      },
      {
        date: '2024-05-01T04:00:00',
        valueKwh: 448.275,
      },
      {
        date: '2024-05-01T05:00:00',
        valueKwh: 448.275,
      },
    ],
    missing: [
      {
        date: '2024-05-01T00:00:00',
        valueKwh: 224.1375,
      },
      {
        date: '2024-05-01T01:00:00',
        valueKwh: 224.1375,
      },
      {
        date: '2024-05-01T02:00:00',
        valueKwh: 448.275,
      },
      {
        date: '2024-05-01T03:00:00',
        valueKwh: 448.275,
      },
      {
        date: '2024-05-01T04:00:00',
        valueKwh: 448.275,
      },
      {
        date: '2024-05-01T05:00:00',
        valueKwh: 448.275,
      },
    ],
    purchaseFractions: [
      {
        date: '2024-05-01T00:00:00',
        valueKwh: 224.1375,
      },
      {
        date: '2024-05-01T01:00:00',
        valueKwh: 224.1375,
      },
      {
        date: '2024-05-01T02:00:00',
        valueKwh: 448.275,
      },
      {
        date: '2024-05-01T03:00:00',
        valueKwh: 448.275,
      },
      {
        date: '2024-05-01T04:00:00',
        valueKwh: 448.275,
      },
      {
        date: '2024-05-01T05:00:00',
        valueKwh: 448.275,
      },
    ],
    maxAllowed: [
      {
        certs: 0,
        date: '2024-05-01T00:00:00',
      },
      {
        certs: 0,
        date: '2024-05-01T01:00:00',
      },
      {
        certs: 0,
        date: '2024-05-01T02:00:00',
      },
      {
        certs: 0,
        date: '2024-05-01T03:00:00',
      },
      {
        certs: 0,
        date: '2024-05-01T04:00:00',
      },
      {
        certs: 0,
        date: '2024-05-01T05:00:00',
      },
    ],
    purchase: [
      {
        certs: 0,
        date: '2024-05-01T00:00:00',
      },
      {
        certs: 0,
        date: '2024-05-01T01:00:00',
      },
      {
        certs: 0,
        date: '2024-05-01T02:00:00',
      },
      {
        certs: 0,
        date: '2024-05-01T03:00:00',
      },
      {
        certs: 0,
        date: '2024-05-01T04:00:00',
      },
      {
        certs: 0,
        date: '2024-05-01T05:00:00',
      },
    ],
    productAvailableNoOverlap: [
      {
        certs: 0,
        date: '2024-05-01T00:00:00',
      },
      {
        certs: 0,
        date: '2024-05-01T01:00:00',
      },
      {
        certs: 1,
        date: '2024-05-01T02:00:00',
      },
      {
        certs: 0,
        date: '2024-05-01T03:00:00',
      },
      {
        certs: 0,
        date: '2024-05-01T04:00:00',
      },
      {
        certs: 1,
        date: '2024-05-01T05:00:00',
      },
    ],
  },
}

export const sellerSignedSnapshottedContract: ISellerSnapshottedContract = {
  id: 242450,
  name: 'Purchase designer contract #14',
  state: ContractState.SIGNED,
  purchaseVolume: 9672,
  listingId: 988,
  offer: {
    id: 242650,
    name: 'Purchase designer offer #14',
    totalPrice: '125736.00',
    eacPrice: '13.00',
    currency: CurrencyCode.EUR,
    state: OfferState.CONTRACT_SIGNED,
  },
  buyer: {
    id: 208250,
    name: 'hello 1',
  },
  product: {
    id: 225050,
    name: 'Wind Britany',
    description: 'BW',
    technology: SiteProductionTechnology.WIND,
    quantity: 9672,
    certificatePrice: '13.00',
    image: {
      id: 224900,
      name: 'Tangy Wind Mills.jpg',
      fileName: 'Tangy Wind Mills.jpg',
      size: 14236,
      mimeType: 'application/octet-stream',
    },
  },
  purchaseInterval: {
    year: 2024,
    month: 'October',
  },
  matchedCerts: [
    {
      timestamp: '2024-10-01T00:00:00Z',
      cert: null,
    },
    {
      timestamp: '2024-10-01T01:00:00Z',
      cert: {
        start: '20250228135236000000000000000000001',
        end: '20250228135236000000000000000000001',
        certScheme: CertSchemeType.EECS,
      },
    },
    {
      timestamp: '2024-10-01T02:00:00Z',
      cert: {
        start: '20250228135236000000000000000000101',
        end: '20250228135236000000000000000000103',
        certScheme: CertSchemeType.EECS,
      },
    },
    {
      timestamp: '2024-10-01T03:00:00Z',
      cert: {
        start: '20250228135236000000000000000000301',
        end: '20250228135236000000000000000000305',
        certScheme: CertSchemeType.EECS,
      },
    },
    {
      timestamp: '2024-10-01T04:00:00Z',
      cert: {
        start: '20250228135236000000000000000000651',
        end: '20250228135236000000000000000000658',
        certScheme: CertSchemeType.EECS,
      },
    },
    {
      timestamp: '2024-10-01T05:00:00Z',
      cert: {
        start: '20250228135236000000000000000001151',
        end: '20250228135236000000000000000001161',
        certScheme: CertSchemeType.EECS,
      },
    },
  ],
  purchaseTimeseries: [
    {
      name: '0',
      value: 0.0,
    },
    {
      name: '1',
      value: 51.57,
    },
    {
      name: '2',
      value: 97.41,
    },
    {
      name: '3',
      value: 171.9,
    },
    {
      name: '4',
      value: 240.65,
    },
    {
      name: '5',
      value: 338.06,
    },
    {
      name: '6',
      value: 435.47,
    },
    {
      name: '7',
      value: 532.88,
    },
    {
      name: '8',
      value: 630.28,
    },
    {
      name: '9',
      value: 721.96,
    },
    {
      name: '10',
      value: 796.45,
    },
    {
      name: '11',
      value: 819.37,
    },
    {
      name: '12',
      value: 819.37,
    },
    {
      name: '13',
      value: 796.45,
    },
    {
      name: '14',
      value: 721.96,
    },
    {
      name: '15',
      value: 630.28,
    },
    {
      name: '16',
      value: 532.88,
    },
    {
      name: '17',
      value: 435.47,
    },
    {
      name: '18',
      value: 338.06,
    },
    {
      name: '19',
      value: 240.65,
    },
    {
      name: '20',
      value: 171.9,
    },
    {
      name: '21',
      value: 97.41,
    },
    {
      name: '22',
      value: 51.57,
    },
    {
      name: '23',
      value: 0.0,
    },
  ],
}
