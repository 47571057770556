import ISite, {SiteProductionTechnology, SiteState} from './ISite'
import IAttachment from './IAttachment'
import IPrice, {PriceUnit} from './IPrice'
import IProductType from './IProductType'
import IParty from './IParty'
import {IRegoCertificate} from './IRegoCertificate'
import {IBaseContract} from './IContract'
import ILocation from './ILocation'

export enum ProductState {
  DRAFT = 'DRAFT',
  IN_OPERATION = 'IN_OPERATION',
  RETIRED = 'RETIRED',
}

export interface ICertificateProduct {
  id: number
  name: string
  description?: string
  technology: SiteProductionTechnology
  quantity: number
  certificatePrice: string
  image: IAttachment
}

export interface IBasicProduct {
  id: number
  name: string
  description: string
}

export interface IUpdateProduct {
  name: string
  description: string
  imgAttachmentId: string
}

interface ISeller {
  id: string
  name: string
}

export interface ISellerProduct {
  id: number
  name: string
  description: string
  image: IAttachment
}

export default interface IProduct {
  id: number
  name: string
  description: string
  image: IAttachment
  availableQuantity: number
  listingId?: number
  createdBy: string
  createdDate: string
  emissionFactor: number
  lastModifiedBy: string
  lastModifiedDate: string
  maxQuantity: number
  price: IPrice
  salesEnd: string
  salesStart: string
  site: {
    id: number
    location: ILocation
    name: string
    productionTechnology: SiteProductionTechnology
    state: SiteState
    operationsStart?: string
  }
  soldQuantity: number
  state: ProductState
  totalQuantity: number
  totalVolumeForSaleMwh: number
  seller?: ISeller
}

// This will change later to properly reflect listings not the product, for now they are almost the same
export interface IMarketplaceListing {
  id: number // TODO: change to listinId
  seller: ISeller
  name: string
  state: ProductState
  description: string
  totalVolumeForSaleMwh: number
  salessSuccessTargetPercentage: number
  salesStart: string
  salesEnd: string
  createdBy: string
  createdDate: string
  lastModifiedBy: string
  lastModifiedDate: string
  emissionFactor: number
  listingId: number
  price: {
    value: number
    unit: PriceUnit
  }
  site: ISite
  image: IAttachment
  availableQuantity: number
  soldQuantity: number
  totalQuantity: number
  maxQuantity: number
}
