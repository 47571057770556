import {Redirect, Route, Switch} from 'react-router'
import Layout from '../../components/Platform/Layout'
import React from 'react'
import Helmet from '../../components/Helmet'
import PageNotFound from 'views/PageNotFound'
import AuthGuard from '../../components/Platform/AuthGuard'
import NotFound from '../../components/NotFound'
import IRoute from '../../interfaces/IRoute'
import {addPrefixToRoutes} from '../../helpers/misc'
import OnboardingGuard from 'components/Platform/OnboardingGuard'

interface IProps extends React.PropsWithChildren {
  routes: IRoute[]
  routePrefix: string
}

const Platform: React.FC<IProps> = ({routes, routePrefix}) => {
  const prefixedRoutes = addPrefixToRoutes(routes, routePrefix)

  return (
    <AuthGuard>
      <OnboardingGuard>
        <Switch>
          {prefixedRoutes.map(
            route =>
              route.redirect && (
                <Route exact path={route.path} key={route.redirect}>
                  <Redirect to={`${routePrefix}${route.redirect}`} />
                </Route>
              ),
          )}
          {prefixedRoutes.map(route => {
            return (
              <Route
                key={route.path}
                path={route.path}
                render={() => {
                  const subRoutes = route.children.filter(({component}) => !!component)
                  const visibleSubRoutes = subRoutes.filter(r => !r.hidden)
                  const RouteComponent: React.FC<React.PropsWithChildren> = route.component

                  let content = (
                    <Switch>
                      {subRoutes.map(subRoute => (
                        <Route key={subRoute.path} path={subRoute.path} component={subRoute.component} />
                      ))}
                      <Route
                        exact
                        path={route.path}
                        render={() => visibleSubRoutes[0] && <Redirect to={visibleSubRoutes[0].path} />}
                      />
                      <Route path="*" component={NotFound} />
                    </Switch>
                  )

                  if (route.component) {
                    content = <RouteComponent>{content}</RouteComponent>
                  }

                  return (
                    <Helmet title={route.title}>
                      <Layout topNavigation={prefixedRoutes.filter(r => !r.hidden)} asideNavigation={visibleSubRoutes}>
                        {content}
                      </Layout>
                    </Helmet>
                  )
                }}
              />
            )
          })}
          {prefixedRoutes.map(
            route =>
              route.default && (
                <Route exact path={routePrefix} key={`${routePrefix}/${route.path}`}>
                  <Redirect to={`${route.path}`} />
                </Route>
              ),
          )}
          {prefixedRoutes.length > 0 && (
            <Route exact path={routePrefix}>
              <Redirect to={prefixedRoutes[0].path} />
            </Route>
          )}
          <Route path="*" component={PageNotFound} />
        </Switch>
      </OnboardingGuard>
    </AuthGuard>
  )
}

export default Platform
