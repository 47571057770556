import CookiesModal from 'components/Legal/CookiesModal'
import PrivacyModal from 'components/Legal/PrivacyModal'
import TermsModal from 'components/Legal/TermsModal'
import PlatformLayout from 'components/Platform/Layout'
import Layout from 'components/Platform/Onboarding/Layout'
import useProfile from 'hooks/useProfile'
import useSSE from 'hooks/useSSE'
import React, {useEffect} from 'react'
import {Route, Switch} from 'react-router-dom'
import NoAssociatedCompany from 'views/NoAssociatedCompany'
import CreateAccount from 'views/Onboarding/CreateAccount'
import SignIn from 'views/Onboarding/SignIn'
import ErrorBoundary from './components/ErrorBoundary'
import Helmet from './components/Helmet'
import SecurityModal from './components/Legal/SecurityModal'
import useTheme from './hooks/useTheme'
import Providers from './Providers'
import Logout from './views/Logout'
import Onboarding from './views/Onboarding'
import PageNotFound from './views/PageNotFound'
import Broker from './views/Platform/Broker'
import Consumer from './views/Platform/Consumer'
import useAlerts from 'hooks/useAlerts'
import useProfileDetails from 'hooks/useProfileDetails'

const Root = () => {
  const {user, isLoaded} = useProfile()

  if (!isLoaded) {
    return (
      <PlatformLayout topNavigation={[]} asideNavigation={[]}>
        <></>
      </PlatformLayout>
    )
  }

  return (
    <Layout showLogout={!!user}>
      <SignIn />
    </Layout>
  )
}

const Register = () => {
  const {user} = useProfile()

  return (
    <Layout showLogout={!!user}>
      <CreateAccount />
    </Layout>
  )
}

const Main = () => {
  const {addError} = useAlerts()
  const {loadProfile} = useProfileDetails()
  const theme = useTheme()

  useSSE()

  useEffect(() => {
    loadProfile().catch(error => {
      console.error(error)
      addError(error.message, error?.correlationId)
    })
  }, [])

  return (
    <Helmet title={theme.title}>
      <Switch>
        <Route path="/consumer/:partyId?" component={Consumer} />
        <Route path="/broker/:partyId?" component={Broker} />
        <Route path="/register" component={Register} />
        <Route path="/on-boarding" component={Onboarding} />
        <Route path="/no-associated" component={NoAssociatedCompany} />
        <Route exact path="/" component={Root} />
        <Route exact path="/logout" component={Logout} />
        <Route path="*" component={PageNotFound} />
      </Switch>
      <CookiesModal />
      <PrivacyModal />
      <TermsModal />
      <SecurityModal />
    </Helmet>
  )
}

const App = () => {
  return (
    <Providers>
      <ErrorBoundary>
        <Main />
      </ErrorBoundary>
    </Providers>
  )
}

export default App
