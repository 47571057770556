import React, {useState} from 'react'
import useTheme from 'hooks/useTheme'
import Box from 'components/Box'
import Text from 'components/Text'
import useLocalization from 'hooks/useLocalization'
import {useAtom} from 'jotai'
import {
  IValue,
  PeriodType,
  periodAtom,
  YEARS,
  yearAtom,
  MONTHS,
  QuickLinkValue,
  PeriodValue,
  IExtenderValue,
  yesterdayValue,
  ytdValue,
} from './periodSelect'
import CustomPeriod from './CustomPeriod'
import Chip from './Chip'
import Period from './Period'
import CustomDay from './CustomDay'

const getValueByPeriod = (period: PeriodType, year: number, value?: string): IValue => {
  if (period === PeriodType.YEAR) {
    return {startYear: year, startMonth: MONTHS[0], endYear: year, endMonth: MONTHS[11]}
  }
  if (period === PeriodType.MONTH) {
    return {startYear: year, startMonth: value, endYear: year, endMonth: value}
  }
  if (period === PeriodType.QUARTER) {
    const parsedValue = +value[1]
    const startMonth = parsedValue * 3 - 3
    const endMonth = startMonth + 2

    return {startYear: year, startMonth: MONTHS[startMonth], endYear: year, endMonth: MONTHS[endMonth]}
  }
}

export interface IProps extends React.PropsWithChildren {
  setValue: (value: IValue) => void
  setQuickLinkValue?: (value: IValue) => void
  customYears?: number[]
  hideCustom?: boolean
  hideQuarter?: boolean
  hideWholeYear?: boolean
  quickLinkValue?: IExtenderValue
  onMonthChange?: (month: string, year: number) => void
  withDaySelect?: boolean
  withYesterday?: boolean
  withYtd?: boolean
}

const PeriodSelect: React.FC<IProps> = ({
  setValue,
  quickLinkValue,
  setQuickLinkValue,
  customYears,
  hideCustom,
  hideQuarter,
  hideWholeYear,
  onMonthChange,
  withDaySelect,
  withYesterday,
  withYtd,
}) => {
  const theme = useTheme()
  const {translate} = useLocalization()
  const [selected, setSelected] = useAtom(periodAtom)
  const [year, setYear] = useAtom(yearAtom)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const isCustomPeriod = selected.period === PeriodType.CUSTOM
  const isQuickLink = selected.period === PeriodType.QUICK_LINK
  const withQuickLinks = withDaySelect || withYesterday || withYtd

  const handleChange = (period: PeriodType, value?: string) => {
    setAnchorEl(null)
    setSelected({period, value})

    if (period !== PeriodType.CUSTOM) {
      setValue(getValueByPeriod(period, year, value))
    }

    if (period === PeriodType.MONTH) {
      onMonthChange && onMonthChange(value, year)
    }
  }

  const handleYearClick = (year: number) => {
    setYear(year)

    if (selected.period === PeriodType.MONTH && onMonthChange) {
      onMonthChange(selected.value, year)
    }

    if (selected.period === PeriodType.QUICK_LINK) {
      setSelected({period: PeriodType.YEAR, value: PeriodValue.WHOLE_YEAR})
      setValue(getValueByPeriod(PeriodType.YEAR, year, PeriodValue.WHOLE_YEAR))
    } else {
      setValue(getValueByPeriod(selected.period, year, selected.value))
    }
  }

  const handleQuickLinkClick = (value: QuickLinkValue) => {
    setSelected({period: PeriodType.QUICK_LINK, value})

    if (value === QuickLinkValue.YESTERDAY) {
      setQuickLinkValue(yesterdayValue)
    }
    if (value === QuickLinkValue.YTD) {
      setQuickLinkValue(ytdValue)
    }
  }

  return (
    <Box
      data-cy="period-select"
      direction="row"
      align="center"
      height={4}
      margin={{vertical: 2}}
      gap={2}
      pad={{top: isCustomPeriod ? 2 : 0}}
    >
      {!isCustomPeriod && withQuickLinks && (
        <Box align="center" gap={1}>
          <Text color={theme.colors.accent} size="small" uppercase font={theme.font.secondaryFont} nowrap>
            {translate('Quick links')}
          </Text>
          {withYesterday && (
            <Chip
              onClick={() => handleQuickLinkClick(QuickLinkValue.YESTERDAY)}
              label={translate('Yesterday')}
              selected={selected.period === PeriodType.QUICK_LINK && selected.value === QuickLinkValue.YESTERDAY}
            />
          )}
          {withDaySelect &&
            (selected.value === QuickLinkValue.CUSTOM_DAY ? (
              <Box height={4}>
                <CustomDay setValue={setQuickLinkValue} value={quickLinkValue} />
              </Box>
            ) : (
              <Chip onClick={() => handleQuickLinkClick(QuickLinkValue.CUSTOM_DAY)} label={translate('Custom day')} />
            ))}
          {withYtd && (
            <Chip
              onClick={() => handleQuickLinkClick(QuickLinkValue.YTD)}
              selected={selected.period === PeriodType.QUICK_LINK && selected.value === QuickLinkValue.YTD}
              label={translate('Year To Last Month')}
            />
          )}
        </Box>
      )}

      {!isCustomPeriod && (
        <Box gap={1} align="center">
          <Text color={theme.colors.accent} size="small" uppercase font={theme.font.secondaryFont} nowrap>
            {withQuickLinks ? translate('Or choose a year') : translate('Choose a year')}
          </Text>
          {(customYears || YEARS).map(item => (
            <Chip
              onClick={() => handleYearClick(item)}
              key={item}
              label={`${item}`}
              selected={!isQuickLink && year === item}
            />
          ))}
        </Box>
      )}

      <Box direction="row" align="center" gap={1}>
        <Text color={theme.colors.accent} size="small" uppercase font={theme.font.secondaryFont} nowrap>
          {translate('And period')}
        </Text>
        <Period
          open={open}
          setAnchorEl={setAnchorEl}
          anchorEl={anchorEl}
          selected={selected}
          handleChange={handleChange}
          hideCustom={hideCustom}
          hideQuarter={hideQuarter}
          hideWholeYear={hideWholeYear}
        />
      </Box>
      {isCustomPeriod && (
        <Box align="center" direction="row" margin={{left: 1, top: -0.75}}>
          <CustomPeriod setValue={setValue} />
        </Box>
      )}
    </Box>
  )
}

export default PeriodSelect
