import React from 'react'
import Text, {ISize} from 'components/Text'
import useTheme from 'hooks/useTheme'
import styled from 'styled-components'

export interface IProps
  extends React.PropsWithChildren,
    Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'onChange'> {
  label?: any
  error?: string
  onChange?: (value: boolean) => void
  labelSize?: ISize
  labelColor?: string
}

const Label = styled.label`
  cursor: pointer;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 4px;
  align-items: center;
`

const StyledCheckbox = styled.input.attrs<any>({
  type: 'checkbox',
})`
  width: 16px;
  height: 16px;
`

// eslint-disable-next-line react/display-name
const Checkbox: React.FC<IProps> = React.forwardRef((props, ref: React.Ref<any>) => {
  const theme = useTheme()
  const {label, error, checked, onChange, labelSize = 'small', labelColor, ...rest} = props

  return (
    <Label>
      <StyledCheckbox
        {...rest}
        checked={!!checked}
        onChange={e => {
          onChange && onChange(e.target.checked)
        }}
        ref={ref}
      />
      <Text inline size={labelSize} color={error ? theme.colors.error : labelColor} uppercase>
        {label}
      </Text>
    </Label>
  )
})

export default Checkbox
