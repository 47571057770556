import {TextDocumentType} from 'domain/IDocumentType'
import {IProposalDetailsBody} from 'domain/IProposalDetails'
import ITextDocument from 'domain/ITextDocument'
import IAttachment from 'domain/IAttachment'
import {INewOffer} from 'domain/IOffer'
import IPartyMember from 'domain/IPartyMember'
import {IPasswordFromInvite} from 'domain/IPasswordFromInvite'
import IContentService from './IContentService'
import IHttpClient from './IHttpClient'
import {IUserProfile} from 'domain/IUserProfile'

export default class LederhosenContentService implements IContentService {
  private readonly httpClient: IHttpClient

  constructor(httpClient: IHttpClient) {
    this.httpClient = httpClient
  }

  public async getTextDocument(documentType: TextDocumentType, locale: string = 'en'): Promise<ITextDocument> {
    const {data} = await this.httpClient.get<ITextDocument>(`/public/text-document`, {params: {documentType, locale}})

    return data
  }

  public async confirmUserPhoneNumber(code: string): Promise<void> {
    const {data} = await this.httpClient.post<void>(`/public/phone-verification/confirm?verification_code=${code}`)

    return data
  }

  public async sendUserPhoneVerification(phone: string): Promise<void> {
    const {data} = await this.httpClient.post<void>(`/public/phone-verification`, {phone})

    return data
  }

  public async sendContractVerificationCode(contractId: number): Promise<void> {
    const {data} = await this.httpClient.post<void>(`/public/contract-verification?contract=${contractId}`)

    return data
  }

  public async confirmContractVerificationCode(contractId: number, code: string): Promise<void> {
    const {data} = await this.httpClient.post<void>(
      `/public/contract-verification/confirm?contract=${contractId}&code=${code}`,
    )

    return data
  }

  public async getPartyMembers(asPartyId: number): Promise<IPartyMember[]> {
    const {data} = await this.httpClient.get<IPartyMember[]>(`/public/parties/${asPartyId}/members`)

    return data
  }

  public async getUserFromInvite(accessCode: string): Promise<IUserProfile> {
    const {data} = await this.httpClient.get(`/public/user-invites/${accessCode}`)

    return data as IUserProfile
  }

  public async setPasswordFromInvite(accessCode: string, password: IPasswordFromInvite): Promise<void> {
    await this.httpClient.post(`/public/user-invites/${accessCode}/confirm`, password)
  }

  public getAttachmentURL(attachment: IAttachment): string {
    if (!attachment) {
      return ''
    }

    return `${this.httpClient.defaults.baseURL}/public/attachments/download/${attachment.id}-${attachment.fileName}`
  }
}
