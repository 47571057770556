import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import React from 'react'
import {createRoot} from 'react-dom/client'
import 'dayjs/locale/en'

import 'style.css'
import './script'

import App from './App'
import * as dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import updateLocale from 'dayjs/plugin/updateLocale'
import env from '@beam-australia/react-env'

dayjs.extend(updateLocale)
dayjs.extend(utc)

dayjs.locale(env('LANGUAGE') || 'en')
dayjs.updateLocale(env('LANGUAGE') || 'en', {
  week: {
    dow: 1,
  },
})

const root = createRoot(document.getElementById('root') as HTMLElement)
root.render(<App />)
