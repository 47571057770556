import React from 'react'
import FormInput from 'components/Form/FormInput'
import useLocalization from 'hooks/useLocalization'
import FormSelect from 'components/Form/FormSelect'
import FormTextArea from 'components/Form/FormTextArea'
import Box from 'components/Box'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Heading from 'components/Heading'
import ProductSiteForm from './ProductSiteForm'
import Radio from 'components/Radio'
import {basicSitesAtom} from 'atoms/marketListings'
import {useAtomValue} from 'jotai'
import FormAttachmentUpload from 'components/Platform/Form/FormAttachmentUpload'

interface IProps extends React.PropsWithChildren {
  handleRadioChange: (event: React.ChangeEvent<HTMLInputElement>, isProduct: boolean) => void
  newSite: boolean
}

const ProductForm: React.FC<IProps> = ({handleRadioChange, newSite}) => {
  const {translate} = useLocalization()
  const {data: sites} = useAtomValue(basicSitesAtom)

  return (
    <>
      <FormInput
        defaultValue=""
        name="product.name"
        label={translate('Product name')}
        required
        data-cy="product-name"
      />
      <FormTextArea
        defaultValue=""
        name="product.description"
        label={translate('Product description')}
        required
        data-cy="product-desc"
      />
      <FormAttachmentUpload
        label={translate('Product image')}
        name="product.imgAttachment"
        accept="image/*"
        removable
        data-cy="product-img"
      />
      <Box pad={{top: 2}}>
        <Heading size="msmall">{translate('Site')}</Heading>
        <RadioGroup row value={newSite} onChange={e => handleRadioChange(e, false)}>
          <FormControlLabel value={false} control={<Radio />} label={translate('Existing site')} />
          <FormControlLabel data-cy="new-site-radio" value={true} control={<Radio />} label={translate('New site')} />
        </RadioGroup>
      </Box>
      {newSite ? (
        <ProductSiteForm />
      ) : (
        <FormSelect
          name="site.id"
          placeholder={translate('Select site')}
          label={translate('Choose site')}
          data-cy="site-select"
          defaultValue=""
          options={sites?.map(site => ({
            value: site.id,
            label: site.name,
          }))}
          required
        />
      )}
    </>
  )
}

export default ProductForm
