import React from 'react'
import ProductListPage from 'components/Platform/Portfolio/Product/ProductListPage'
import ResourceGateway from 'components/ResourceGateway'
import ProductEditPage from 'components/Platform/Portfolio/Product/ProductEditPage'
import {getPlatformUrl} from 'helpers/party'
import {useAtomValue} from 'jotai'
import {partyAtom} from 'atoms/party'

const Products = () => {
  const party = useAtomValue(partyAtom)

  return (
    <ResourceGateway
      single={routeProps => <ProductEditPage productId={routeProps.match.params.id} />}
      list={() => <ProductListPage urlPrefix={`${getPlatformUrl(party)}/portfolio/products`} />}
    />
  )
}

export default Products
