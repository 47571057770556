import React, {ReactElement, useEffect} from 'react'
import styled from 'styled-components'
import Box from './Box'
import Text from './Text'
import useTheme from '../hooks/useTheme'
import Link from './Link'
import ITheme from '../theme/ITheme'
import {useLocation} from 'react-router'
import TechnologyIcon from './Platform/TechnologyIcon'
import {SiteProductionTechnology} from '../domain/ISite'
import {ISpacingProps} from './styleProps/spacing'
import Label from './Label'
import FormInput from './Form/FormInput'
import useLocalization from '../hooks/useLocalization'
import Form from './Form'
import {dateStartOf, futureDate} from 'helpers/date'

interface IProps extends ISpacingProps {
  label?: string
  title?: string
  choices: IChoice[]
  selected?: IChoice | IChoice[]
  multiselect?: boolean
  customLabel?: string
  transparent?: boolean
  name?: string
  children?: (choice: IChoice | IChoice[]) => any
  technology?: boolean
  colored?: boolean
  choiceMaxWidth?: string
  suffixComponent?: ReactElement
  filterListTargetId?: string
  skipDate?: boolean
  setActiveChoice?: (choice: IChoice | IChoice[]) => any
}

const FilterIcon: React.FC<{technology: boolean; slug: string; colored: boolean; color: string; name: string}> = ({
  technology,
  slug,
  colored,
  color,
  name,
}) => {
  const theme = useTheme()

  if (technology && slug in SiteProductionTechnology) {
    return <TechnologyIcon technology={SiteProductionTechnology[slug]} />
  } else if (colored && color) {
    return <InitialIcon color={color} name={name} />
  } else if (colored || technology) {
    return <InitialIcon color={theme.colors.limeGreen} name={name || 'All'} />
  } else {
    return null
  }
}

const ColoredIcon = styled.div<{color: string}>`
  text-transform: uppercase;
  background-color: ${props => props.color};
  color: ${props => (props.theme as ITheme).colors.common.white};
  border-radius: 50%;
  min-height: 28px;
  min-width: 28px;
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 16px;
  margin-right: 8px;

  span {
    margin: 0 auto;
    vertical-align: middle;
  }
`

export const InitialIcon: React.FC<{color: string; name: string}> = ({color, name}) => {
  return (
    <ColoredIcon color={color}>
      <span>{name[0]}</span>
    </ColoredIcon>
  )
}

export interface IChoice {
  slug: any
  name: string
  color?: string
}

const HideOverflow = styled.span`
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const StyledLink = styled(Link)<{selected: boolean; $technology?: boolean; $colored?: boolean; maxwidth?: string}>`
  text-transform: uppercase;
  font-size: 10px;
  display: block;
  padding: 5px;
  max-height: 28px;
  max-width: ${props => props.maxwidth || 'unset'};
  color: ${props => (props.theme as ITheme).colors.secondary};
  border-radius: 8px;
  margin-bottom: 10px;

  ${props =>
    props.selected &&
    `
      background-color: ${(props.theme as ITheme).colors.accent};
      color: ${(props.theme as ITheme).colors.common.white};
  `}

  ${props =>
    (props.$technology || props.$colored) &&
    `
      display: flex;
      padding: ${props.$colored ? '10px' : '8px'};
      border-radius: 28px;
      font-weight: 500;
      align-items: center;
      font-size: 14px;
      background-color: ${(props.theme as ITheme).colors.light3};
      img {
        padding-right: 8px;
      }
  `}
  
  ${props =>
    props.selected &&
    (props.$technology || props.$colored) &&
    `
      background-color: ${(props.theme as ITheme).colors.accent};
  `}
`

const Filter = ({
  label,
  title,
  choices,
  selected,
  customLabel,
  transparent,
  name = 'filter',
  multiselect = false,
  children,
  technology,
  colored = false,
  choiceMaxWidth,
  suffixComponent,
  filterListTargetId,
  setActiveChoice,
  skipDate,
  ...props
}: IProps) => {
  const theme = useTheme()
  const location = useLocation()
  const {translate} = useLocalization()
  const urlSearchParams = new URLSearchParams(location.search)
  const activeSlug: string | string[] = multiselect
    ? urlSearchParams.getAll(name) || []
    : urlSearchParams.get(name) || ''

  let selectedFilter: IChoice | IChoice[] = null

  useEffect(() => {
    if (setActiveChoice && activeSlug && !multiselect) {
      setActiveChoice(choices.find(p => activeSlug.includes(p.slug)))
    }

    if (setActiveChoice && activeSlug && multiselect) {
      setActiveChoice(choices.filter(p => activeSlug.includes(p.slug)))
    }
  }, [activeSlug.toString()])

  if (!choices?.length && !suffixComponent) {
    return null
  }

  if (multiselect) {
    selectedFilter = selected || (activeSlug && choices?.filter(p => activeSlug.includes(p.slug)))
  } else {
    selectedFilter = selected || (activeSlug ? choices?.find(p => p.slug === activeSlug) : choices[0])
  }

  const isSelected = (item: IChoice): boolean => {
    if (!selectedFilter) {
      return false
    }

    if (multiselect) {
      return (selectedFilter as IChoice[]).some(selected => selected.slug === item.slug)
    } else {
      return (selectedFilter as IChoice).slug === item.slug
    }
  }

  const startOfYear = dateStartOf(new Date(), 'year')

  const getToPath = (item: IChoice) => {
    const urlSearchParams = new URLSearchParams(location.search)

    if (multiselect && isSelected(item)) {
      urlSearchParams.delete(name)
      ;(selectedFilter as IChoice[]).forEach(filter => {
        filter.slug !== item.slug && urlSearchParams.append(name, filter.slug)
      })
    } else if (multiselect) {
      urlSearchParams.append(name, item.slug)
    } else {
      urlSearchParams.set(name, item.slug)
    }

    return urlSearchParams.toString()
  }

  return (
    <>
      <Box {...props}>
        {title && (
          <Text size="msmall" bold margin={{bottom: 1.5}} uppercase color={theme.colors.secondary}>
            {title}
          </Text>
        )}
        <Box
          justify="flex-start"
          color={transparent ? null : theme.colors.outline}
          round
          margin={{top: 1}}
          pad={{vertical: 0.75, horizontal: title ? 0 : 0.5}}
        >
          <Box justify="center" align="center" gap={1}>
            {label && (
              <Text size="small" uppercase color={theme.colors.accent} pad={{bottom: 1}}>
                {label}
              </Text>
            )}
            <Box id={filterListTargetId} justify="flex-start" align="start" gap={1} wrap>
              {choices?.map((period, i) => {
                return (
                  <StyledLink
                    maxwidth={choiceMaxWidth}
                    to={`?${getToPath(period)}`}
                    key={`${i}-${period.slug}`}
                    selected={isSelected(period)}
                    $technology={technology}
                    $colored={colored}
                  >
                    <FilterIcon
                      technology={technology}
                      colored={colored}
                      slug={period.slug}
                      color={period.color}
                      name={period.name}
                    />
                    {choiceMaxWidth ? <HideOverflow title={period.name}>{period.name}</HideOverflow> : period.name}
                  </StyledLink>
                )
              })}
              {customLabel && (
                <StyledLink to={null} selected>
                  {customLabel}
                </StyledLink>
              )}
              {suffixComponent && suffixComponent}
            </Box>
          </Box>
        </Box>
      </Box>
      {!skipDate && (
        <Label text={translate('Choose start and end date')}>
          <Form onSubmit={() => console.log('x')} submissionFeedback={null}>
            <Box direction="row" gap={1}>
              <FormInput
                type="date"
                name="contract.validFrom"
                defaultValue={startOfYear.toISOString()}
                validate={(value, getValues) =>
                  Date.parse(value) <= Date.parse(getValues()['contract.validTo']) || translate('From date')
                }
              />
              -
              <FormInput
                type="date"
                name="contract.validTo"
                defaultValue={futureDate(startOfYear, 'year', 12).toISOString()}
                validate={(value, getValues) =>
                  Date.parse(value) >= Date.parse(getValues()['contract.validFrom']) || translate('To date')
                }
              />
            </Box>
          </Form>
        </Label>
      )}
      {children && children(selectedFilter)}
    </>
  )
}

export default Filter
