import React, {useState} from 'react'
import styled from 'styled-components'
import {useHistory} from 'react-router-dom'
import ITheme from '../../theme/ITheme'
import TableSearch, {IProps as ISearchProps} from './TableSearch'
import Box from '../Box'
import {orderBy} from 'lodash-es'
import ExpandButton from '../IconButton/ExpandButton'
import {ISpacingProps} from 'components/styleProps/spacing'
import Text from 'components/Text'
import {CSVLink} from 'react-csv'
import ExportButton from '../IconButton/ExportButton'
import Skeleton from '@mui/material/Skeleton'
import Rain from 'components/Icons/Rain'
import useTheme from 'hooks/useTheme'
import {formatDecimal, formatMoney} from 'helpers/format'
import {CurrencyCode} from 'domain/IPrice'

export interface IColumn<T> {
  accessor: (item: T) => string | number | boolean | Date | T[keyof T] | T
  render?: (value: string | number | boolean | Date | T[keyof T] | T, item: T, rowIndex: number) => any
  title?: string | React.ReactNode
  searchable?: boolean
  sortable?: boolean
  disableLink?: boolean
}

interface IProps<T> extends React.PropsWithChildren, ISpacingProps {
  tableName?: string
  data: Array<T>
  columns: Array<IColumn<T>>
  isLoading?: boolean
  linkTo?: (item: T) => string
  search?: boolean | Omit<ISearchProps<T>, 'columns' | 'onFilter'>
  sortable?: boolean
  exportable?: boolean
  highlightOnHover?: boolean
  wrapHeadCell?: boolean
}

export const StyledTable = styled.table`
  width: 100%;
  text-align: left;
  border: 0;
  padding: 0;
  border-collapse: collapse;
  font-family: ${(props: {theme: ITheme}) => props.theme.font.secondaryFont};

  th,
  tr,
  td {
    border-bottom: 1px solid ${props => (props.theme as ITheme).colors.light1};
    padding: 0px 16px 0px 12px;
    height: 48px;
  }
`

export const StyledHeadCell = styled.th<{wrapHeadCell?: boolean}>`
  ${props =>
    props.onClick &&
    `
    cursor: pointer;
    user-select: none;
  `}

  ${props =>
    !props.wrapHeadCell &&
    `
    white-space: nowrap;
  `}
`

export const StyledRow = styled.tr<{isLink?: boolean; highlightOnHover?: boolean}>`
  ${props =>
    (props.highlightOnHover || props.isLink) &&
    `
  cursor: ${props.isLink ? 'pointer' : 'default'};
  
  &:hover {
    background-color: ${(props.theme as ITheme).colors.outline};};
  }
  `}
`

const CenteredDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const Wrap = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 3px;
  gap: 10px;
`

const LOADING_SKELETON_HEIGHT = 65
const LOADING_SKELETON_ANIMATION = 'wave'

interface TableValueProps {
  value?: number | string
  text?: string
  currency?: CurrencyCode
  unit?: string
  rounded?: boolean
  nowrap?: boolean
  prefix?: string
  bold?: boolean
}

export const TableValue: React.FC<TableValueProps> = ({value, text, nowrap, currency, unit, rounded, prefix, bold}) => {
  const theme = useTheme()

  return (
    <Box direction="row" gap={0.5} align="center">
      {(value === 0 || value) && (
        <Text nowrap={nowrap} size={bold ? 'mlarge' : 'medium'} font={theme.font.numbers} bold={bold}>
          {prefix}
          {rounded ? formatDecimal(value, 0) : formatMoney(value)}
        </Text>
      )}
      {text && <Text size="medium">{text}</Text>}

      <Text size="small" font={theme.font.secondaryFont} color={theme.colors.accent} uppercase>
        {currency && CurrencyCode[currency]}
        {unit && `${currency ? '/' : ''}${unit}`}
      </Text>
    </Box>
  )
}

const Table = <T extends Record<string, any>>({
  tableName,
  data,
  isLoading,
  columns,
  linkTo,
  search,
  sortable = true,
  exportable,
  highlightOnHover,
  pad,
  margin,
  wrapHeadCell,
}: IProps<T>) => {
  const history = useHistory()
  const [[filter], setFilter] = useState<[(row: T) => boolean]>([() => true])
  const [sortBy, setSortBy] = useState<{index: number; order: 'asc' | 'desc'}>(null)
  const searchProps = typeof search === 'object' ? search : {}

  if (isLoading) {
    return Array.from({length: 6}).map((_, index) => (
      <Skeleton animation={LOADING_SKELETON_ANIMATION} height={LOADING_SKELETON_HEIGHT} key={index} />
    ))
  }

  if (!data || !data.length) {
    return (
      <CenteredDiv>
        <Rain />
        <Text size="mlarge">No data to show</Text>
      </CenteredDiv>
    )
  }

  const filteredRows = (sortBy ? (orderBy([...data], columns[sortBy.index].accessor, sortBy.order) as T[]) : data || [])
    .map((item, rowIndex) => {
      if (!filter(item)) {
        return null
      }

      return item
    })
    .filter(item => item != null)

  const headers = columns.map(column => column.title).filter(title => typeof title === 'string')

  const csvData = filteredRows.map(item => {
    return columns
      .filter(column => typeof column.title === 'string')
      .map((column, columnIndex) => {
        return column.accessor(item)
      })
  })

  return (
    <Box pad={pad} margin={margin}>
      {(search || exportable) && (
        <Wrap>
          {search && (
            <Box grow={true}>
              <TableSearch<T> onFilter={newFilter => setFilter([newFilter])} columns={columns} {...searchProps} />
            </Box>
          )}
          {exportable && (
            <CSVLink
              style={{marginLeft: 'auto', marginRight: '5px'}}
              data={csvData}
              headers={headers}
              filename={tableName ? `${tableName}.csv` : 'table.csv'}
            >
              <ExportButton />
            </CSVLink>
          )}
        </Wrap>
      )}
      <StyledTable>
        <thead>
          <tr>
            {columns.map((column, index) => (
              <StyledHeadCell
                wrapHeadCell={wrapHeadCell}
                key={index}
                onClick={
                  ((column.sortable === undefined ? sortable : column.sortable) &&
                    (() =>
                      setSortBy({
                        index,
                        order: sortBy?.index === index && sortBy?.order === 'asc' ? 'desc' : 'asc',
                      }))) ||
                  undefined
                }
              >
                <Box align="center">
                  {sortBy && sortBy.index === index && <ExpandButton noHover expanded={sortBy?.order === 'desc'} />}
                  {column.title}
                </Box>
              </StyledHeadCell>
            ))}
          </tr>
        </thead>
        <tbody>
          {filteredRows.map((item, rowIndex) => {
            return (
              <StyledRow
                highlightOnHover={highlightOnHover}
                isLink={!!linkTo}
                key={item?.id || rowIndex}
                onClick={() => linkTo && history.push(linkTo(item))}
              >
                {columns.map((column, columnIndex) => {
                  const value = column.accessor(item)

                  return (
                    <td
                      onClick={e => column.disableLink && e.stopPropagation()}
                      key={columnIndex}
                      style={column.disableLink && {cursor: 'default'}}
                    >
                      {column.render ? column.render(value, item, rowIndex) : value}
                    </td>
                  )
                })}
              </StyledRow>
            )
          })}
        </tbody>
      </StyledTable>
    </Box>
  )
}

export default Table
