import {atom, PrimitiveAtom} from 'jotai'
import {atomWithCleanup, atomWithSwr, loadable} from './utils'
import {partyAtom} from './party'
import {intervalAtom, rootServiceAtom} from './general'
import RootService from 'services'
import {atomWithRefresh} from 'jotai/utils'
import {MatchStatus, QuantityKind, RoundingType} from 'domain/IProposalDetails'

export const getStatusMessage = (matchStatus: MatchStatus, restrictedCountry: boolean) => {
  if (restrictedCountry) {
    return "You can't buy from another country, contact Renewabl to move these certificates to your country"
  }

  if (matchStatus === MatchStatus.NoConsumptionForPeriod) {
    return 'Upload your consumption'
  }

  if (matchStatus === MatchStatus.MatchedQuantityTooLow) {
    return 'Your selected volume is too low, please select a higher volume'
  }

  if (matchStatus === MatchStatus.AnyRoundMaxAllowedTooLow) {
    return 'This listing is not a good fit for your needs, please select a different listing'
  }

  if (matchStatus === MatchStatus.RoundDownMaxAllowedTooLow) {
    return "Current matching mode is not a good fit for your needs, please use 'Greedy' matching mode"
  }

  return null
}

export const listingIdAtom = atom<string>(null) as PrimitiveAtom<string>
export const offerIdAtom = atom<string>(null) as PrimitiveAtom<string>
export const quantityAtom = atomWithCleanup<number>(null)
export const matchingModeAtom = atomWithCleanup<RoundingType>(RoundingType.DOWN, true)

export const listingAtom = loadable(
  atom(async get => {
    const services: RootService = get(rootServiceAtom)
    const party = get(partyAtom)
    const listingId = get(listingIdAtom)

    if (listingId === null) return null

    const product = await services.v2ContentService.getCertificateListing(party.id, +listingId)

    return product
  }),
  {nullIsLoading: true},
)

export const proposalDetailsAtom = atomWithSwr(
  atom(async get => {
    const services = get(rootServiceAtom)
    const quantityValue = get(quantityAtom)
    const rounding = get(matchingModeAtom)
    const party = get(partyAtom)
    const interval = get(intervalAtom)
    const listingId = get(listingIdAtom)
    const quantity = quantityValue ? quantityValue : QuantityKind.MAX_ALLOWED

    if (listingId === null) return null

    const data = await services.v2ContentService.getCertificateProposalDetails(
      party.id,
      +listingId,
      rounding,
      quantity,
      interval,
    )

    return data
  }),
  {nullIsLoading: true, invalidateOn: listingIdAtom},
)

export const offerAtom = loadable(
  atomWithRefresh(async get => {
    const services: RootService = get(rootServiceAtom)
    const party = get(partyAtom)
    const offerId = get(offerIdAtom)

    if (offerId === null) return null

    const offer = await services.v2ContentService.getCertificatePurchaseOffer(party.id, +offerId)

    return offer
  }),
  {nullIsLoading: true},
)

export const rawProposalDetailsAtom = loadable(
  atom(async get => {
    const services = get(rootServiceAtom)
    const quantityValue = get(quantityAtom)
    const rounding = get(matchingModeAtom)
    const party = get(partyAtom)
    const listingId = get(listingIdAtom)
    const quantity = quantityValue ? quantityValue : QuantityKind.MAX_ALLOWED

    if (listingId === null) return null

    const data = await services.v2ContentService.getRawCertificateProposalDetails(
      party.id,
      +listingId,
      rounding,
      quantity,
    )

    return data
  }),
  {nullIsLoading: true},
)

export const resetDesignerState = atom(null, (_, set) => {
  set(listingIdAtom, null)
  set(offerIdAtom, null)
})
