import {ContractState} from './IContract'
import IContractType from './IContractType'
import {OfferState} from './IOffer'
import {CurrencyCode} from './IPrice'
import {ICertificateProduct} from './IProduct'
import {IProposalTimeseries, RoundingType} from './IProposalDetails'

export interface ICertOfferContract {
  id: number
  state: ContractState
  name: string
  contractType?: IContractType
}

export enum CertSchemeType {
  EECS = 'EECS',
  OFGEM = 'OFGEM',
}

export interface ICertificateOfferMatchedCert {
  timestamp: string
  cert: {
    start: string
    end: string
    certScheme: CertSchemeType
  }
}

export interface ICertOfferCreateBody {
  availableQuantitySnapshot: number
  listingId: number
  matchedQuantitySnapshot: number
  shortfallQuantitySnapshot: number
  requestedQuantity: number
  rounding: RoundingType
}

export default interface ICertificateOffer {
  id: number
  listingId: number
  name: string
  state: OfferState
  party: {
    id: number
    name: string
  }
  numMeters: number
  rounding: RoundingType
  requestedQuantitySnap: number
  availableQuantitySnap: number
  matchedQuantitySnap: number
  purchaseInterval: {
    year: number
    month: string
  }
  estimatedVolume: number
  contract: {
    id: number
    name: string
    state: ContractState
  }
  matchedCerts: ICertificateOfferMatchedCert[]
  averagePrice: string
  totalPrice: string
  currency: CurrencyCode
  product: ICertificateProduct
  greenCoverage: {
    hourlyPercent: number
    monthlyPercent: number
    yearlyPercent: number
  }
  timeseries: IProposalTimeseries
}
