import React from 'react'
import Select from 'components/Select'
import {getChangeEventValue} from 'helpers/misc'
import useTheme from 'hooks/useTheme'
import {partyMembersAtom, selectedPartyMemberAtom} from 'atoms/party'
import {useAtomValue} from 'jotai'
import usePartyActions from 'hooks/usePartyActions'

const PartySelect: React.FC<React.PropsWithChildren> = () => {
  const theme = useTheme()
  const {switchParty} = usePartyActions()
  const selectedPartyMember = useAtomValue(selectedPartyMemberAtom)
  const partyMembers = useAtomValue(partyMembersAtom)

  if (partyMembers.length === 1) {
    return null
  }

  const handlePartyMemberClick = e => {
    const partyMemberId = getChangeEventValue(e).toString()
    const partyMember = partyMembers.find(partyMember => partyMember.id === +partyMemberId)
    switchParty(partyMember.party.id)
  }

  return (
    <Select
      data-cy="party-select"
      margin={{right: 2}}
      style={{border: `1px solid ${theme.colors.secondary}`, minWidth: 210}}
      value={selectedPartyMember?.id || undefined}
      options={
        partyMembers.length > 0
          ? partyMembers.map(partyMember => ({
              label: partyMember.party?.name,
              value: partyMember.id,
            }))
          : []
      }
      onChange={e => handlePartyMemberClick(e)}
    />
  )
}

export default PartySelect
