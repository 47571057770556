import styled from 'styled-components'
import {Link} from 'react-router-dom'
import ITheme from '../theme/ITheme'
import Box from './Box'
import useTheme from '../hooks/useTheme'
import Text from './Text'
import React from 'react'
import TechnologyIconAndName from './Platform/TechnologyIconAndName'
import Flag from 'react-world-flags'

const Container = styled(Link)<{$minWidth?: string}>`
  min-width: ${props => props.$minWidth};
  background-color: ${props => (props.theme as ITheme).colors.common.white};
  box-shadow: 1px 1px 8px 0 rgba(0, 0, 0, 0.1);
  text-decoration: none;
  display: block;
  border-radius: 8px;

  &:hover,
  &.hover {
    box-shadow: 1px 1px 8px 0 rgba(0, 0, 0, 0.2);
  }
`

const Cover = styled.div<{bgColor?: string; hasImage?: boolean; coverHeigh?: number; noRound?: boolean}>`
  position: relative;
  height: ${props => (props.coverHeigh ? `${props.coverHeigh}` : '140')}px;
  box-sizing: border-box;
  padding: 10px;
  background-color: ${props => props.bgColor || (props.theme as ITheme).colors.light2};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: ${props => (props.noRound ? '0px' : '8px 8px 0 0')};

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-image: ${props => `linear-gradient(180deg, hsla(0, 0%, 100%, 0), ${props.hasImage ? '#000' : '#0003'})`};
  }

  & > img {
    border-radius: ${props => (props.noRound ? '0px' : '8px 8px 0 0')};
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  & > *:not(img) {
    z-index: 1;
  }
`

export const Badge = styled.div<{variant?: string}>`
  padding-right: 10px;
  padding-left: 10px;
  border-radius: 20px;
  background-color: ${props => (props.theme as ITheme).colors.common.white};
  color: ${props => (props.theme as ITheme).colors.grey7};
  font-size: 12px;
  line-height: 24px;
  margin-right: 8px;
  display: inline-block;
  text-align: center;

  ${props =>
    props.variant === 'highlighted' &&
    `
    background-color: ${(props.theme as ITheme).colors.primary};
    color: ${(props.theme as ITheme).colors.common.black};
    font-weight: 500;
  `}
  ${props =>
    props.variant === 'success' &&
    `
      background-color: #21a15c;
      color: #fff;
      border: 1px solid #fff;
      box-shadow: 0 0 12px 0 hsla(0, 0%, 100%, 0.75);
      font-weight: 500;
  `}
    ${props =>
    props.variant === 'error' &&
    `
    background-color: ${(props.theme as ITheme).colors.error};
      color: ${(props.theme as ITheme).colors.common.white};
      font-weight: 500;
      text-transform: uppercase;
  `}
`

const CoverText = styled.div`
  color: ${props => (props.theme as ITheme).colors.common.white};
  text-shadow: rgb(0, 0, 0) 1px 1px 3px;
`

const Panel = styled.div`
  padding: 10px;
`

const PanelHead = styled(Box).attrs({align: 'center'})`
  height: 48px;
  box-sizing: border-box;
  white-space: nowrap;
`

const PanelRow = styled(Box).attrs({align: 'center', justify: 'space-between'})`
  height: 36px;
`

const Spacer = styled.div`
  flex-grow: 1;
`

export interface IFeature {
  name: string
  value: any
  small?: boolean
}

const Feature = (props: IFeature) => {
  const theme = useTheme()

  return (
    <PanelRow>
      <Text color={theme.colors.grey7} uppercase size="small" margin={{right: 1}}>
        {props.name}
      </Text>
      <Text bold {...(props.small ? {uppercase: true, size: 'small'} : {})}>
        {props.value}
      </Text>
    </PanelRow>
  )
}

export interface IProps extends React.PropsWithChildren {
  to?: string
  title: string
  description?: string
  country?: string
  coverContent?: any
  coverImage?: string
  coverHeight?: number
  features?: IFeature[]
  technology?: any
  hasMouseOver?: boolean
  onMouseOver?: () => void
  onMouseOut?: () => void
  onClick?: (e: any) => void
  noHover?: boolean
  noPanel?: boolean
  noRound?: boolean
  defaultCoverColor?: string
  minWidth?: string
}

const ListItem: React.FC<IProps> = props => {
  const firstFeature = props.features && props.features[0]
  const hasFeatures = props.features && props.features.length > 0
  const {onClick} = props

  return (
    <Container
      data-cy="ListItem"
      to={props.to}
      className={props.hasMouseOver && 'hover'}
      onMouseOver={props.onMouseOver}
      onMouseOut={props.onMouseOut}
      onClick={e => onClick && onClick(e)}
      style={{cursor: props.noHover && 'default'}}
      $minWidth={props.minWidth}
    >
      <Cover
        bgColor={props.defaultCoverColor}
        hasImage={!!props.coverImage}
        coverHeigh={props.coverHeight}
        noRound={props.noRound}
      >
        {props.coverImage && <img src={props.coverImage} alt="" />}
        <Box justify="space-between" width="100%">
          {props.coverContent}
        </Box>
        <Box justify="space-between" width="100%" align="center">
          <CoverText>
            <Text>{props.title}</Text>
            <Text size="small" uppercase>
              {props.description}
            </Text>
          </CoverText>
          {props?.country && <Flag width={40} code={props.country} />}
        </Box>
      </Cover>
      {props.noPanel ? null : (
        <Panel>
          {(props.technology || firstFeature) && (
            <PanelHead>
              {props.technology ? <TechnologyIconAndName technology={props.technology} /> : <div />}
              <Spacer />
              {firstFeature && <Feature {...firstFeature} />}
            </PanelHead>
          )}
          {(hasFeatures || props.children) && (
            <>
              {hasFeatures &&
                props.features
                  .slice(1)
                  .filter(Boolean)
                  .map(feature => <Feature key={feature.name} {...feature} />)}
              {props.children}
            </>
          )}
        </Panel>
      )}
    </Container>
  )
}

export default ListItem
