import React, {useEffect} from 'react'
import SignInForm from 'components/Platform/Onboarding/SignInForm'
import Page from 'components/Platform/Onboarding/Page'
import {getPlatformUrl} from 'helpers/party'
import {useHistory} from 'react-router-dom'
import useProfile from 'hooks/useProfile'

const SignIn = () => {
  const {party, user} = useProfile()
  const history = useHistory()

  useEffect(() => {
    if (user) {
      if (party?.partyRole) {
        history.push(getPlatformUrl(party))
      } else {
        history.push('/register')
      }
    }
  }, [])

  return (
    <Page>
      <SignInForm />
    </Page>
  )
}

export default SignIn
