import React from 'react'
import styled from 'styled-components'
import spacing, {ISpacingProps} from './styleProps/spacing'
import ITheme from '../theme/ITheme'
import round, {IRoundProps} from './styleProps/round'

export interface IProps
  extends React.PropsWithChildren,
    ISpacingProps,
    IRoundProps,
    React.ButtonHTMLAttributes<HTMLButtonElement> {
  fullWidth?: boolean
  height?: number
  expired?: boolean
  large?: boolean
  small?: boolean
  contrast?: boolean
}

const BaseButton: React.FC<IProps> = ({round, margin, pad, fullWidth, expired, large, small, contrast, ...props}) => {
  return <button {...props} />
}

const StyledBaseButton = styled(BaseButton)<IProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: 12px;
  font-size: ${props => props.large && '13px'};
  font-size: ${props => props.small && '11px'};
  min-width: 92px;
  height: 40px;
  height: ${props => props.large && '48px'};
  height: ${props => props.small && '32px'};
  font-family: ${props => (props.theme as ITheme).font.secondaryFont}, sans-serif;
  font-weight: ${props => (props.large ? '500' : '300')};
  color: ${props => (props.theme as ITheme).colors.secondary};
  text-align: center;
  line-height: ${props => (props.theme as ITheme).tileSize * props.height - 2}px;
  ${spacing}
  ${round}
  border-radius: ${props => props.large && '24px'};
  ${({fullWidth}: IProps) => fullWidth && 'width: 100%;'}

  &:not(:disabled) {
    cursor: pointer;
  }
`

export const PrimaryButton = styled(StyledBaseButton)<IProps>`
  border: none;
  background: ${props => (props.theme as ITheme).colors.primary};
  &:not(:disabled) {
    cursor: pointer;

    &:hover {
      background: ${props => (props.theme as ITheme).colors.primaryDark};
    }
  }

  &:disabled {
    opacity: 0.8;
  }
`

export const LinkButton = styled(StyledBaseButton)<IProps>`
  border: 0;
  background: inherit;
  color: ${props => (props.theme as ITheme).colors.primaryDark};

  &:not(:disabled):hover {
    text-decoration: underline;
  }
`

export const SecondaryButton = styled(StyledBaseButton)<IProps>`
  border: 1px solid;
  border-color: ${props => (props.theme as ITheme).colors.accent};
  background: ${props => (props.theme as ITheme).colors.common.white};
  color: ${props => (props.theme as ITheme).colors.accent};

  &:not(:disabled):hover {
    background: ${props => (props.theme as ITheme).colors.outline};
    color: ${props => (props.theme as ITheme).colors.secondary};
    filter: none;
  }
`

export const OutlinedButton = styled(StyledBaseButton)<IProps>`
  border: 1px solid ${props => (props.theme as ITheme).colors.secondary};
  background: none;
  color: ${props => (props.theme as ITheme).colors.secondary};

  &:not(:disabled):hover {
    border-color: ${props => (props.contrast ? props.theme.colors.secondary : (props.theme as ITheme).colors.primary)};
    background: ${props =>
      props.contrast ? props.theme.colors.contrastBackground : (props.theme as ITheme).colors.primaryLight};
    filter: none;
  }
`

export const ClearButton = styled(StyledBaseButton)<IProps>`
  border: 0;
  background: ${props => (props.theme as ITheme).colors.common.white};
  color: ${props => (props.theme as ITheme).colors.secondary};

  &:not(:disabled):hover {
    background: rgba(255, 255, 255, 0.5);
    filter: none;
  }
`

const Button: React.FC<IProps & {variant?: 'primary' | 'secondary' | 'clear' | 'link' | 'outlined'}> = ({
  variant = 'primary',
  pad = {horizontal: 2},
  round = 2.5,
  height = 5,
  ...props
}) => {
  switch (variant) {
    case 'secondary':
      return <SecondaryButton {...props} pad={pad} round={round} height={height} />
    case 'clear':
      return <ClearButton {...props} pad={pad} round={round} height={height} />
    case 'link':
      return <LinkButton {...props} pad={pad} round={round} height={height} />
    case 'outlined':
      return <OutlinedButton {...props} pad={pad} round={round} height={height} />
    case 'primary':
    default:
      return <PrimaryButton {...props} pad={pad} round={round} height={height} />
  }
}

export default Button
