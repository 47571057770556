import React, {useEffect, useState} from 'react'
import InnerScroll from 'components/InnerScroll'
import useLocalization from 'hooks/useLocalization'
import Box from 'components/Box'
import ActionBox from 'components/Platform/ActionBox'
import ProposalDetailsInfo from 'components/Platform/Buy/CertificateDesigner/ProposalDetailsInfo'
import ModalLink from 'components/Modal/ModalLink'
import {ModalType} from 'components/Modal/IModal'
import Button from 'components/Button'
import Text from 'components/Text'
import {OfferState} from 'domain/IOffer'
import OfferProduct from 'components/Platform/Portfolio/Offer/OfferDesignerCertificate/OfferProduct'
import PreviewModal from 'components/Platform/Buy/CertificateDesigner/PreviewModal/PreviewModal'
import {ContractState} from 'domain/IContract'
import {Alert, Chip, LinearProgress, Skeleton} from '@mui/material'
import {useSetAtom, useAtom, useAtomValue} from 'jotai'
import {rootServiceAtom} from 'atoms/general'
import {
  listingIdAtom,
  matchingModeAtom,
  offerAtom,
  offerIdAtom,
  proposalDetailsAtom,
  quantityAtom,
  resetDesignerState,
} from 'atoms/certificateDesigner'
import {partyAtom} from 'atoms/party'
import PageBase from 'components/Platform/Buy/CertificateDesigner/PageBase'
import {buyerProposalsAtom} from 'atoms/proposals'
import useAlerts from 'hooks/useAlerts'
import ConfirmationDialog from 'components/ConfirmationDialog'
import GeneralFilter from './GeneralFilter'
import {MatchStatus} from 'domain/IProposalDetails'
import CertDesignerChart from './CertDesignerChart'
import ErrorInfo from 'components/ErrorInfo'

// TODO: Implement editable logic later on, preferably different component when editable
const CertDesignerOffer: React.FC<{id: string}> = ({id}) => {
  const {translate} = useLocalization()
  const {addSuccess, addError} = useAlerts()

  const [requestDialogOpen, setRequestDialogOpen] = useState<boolean>(false)
  const setOfferId = useSetAtom(offerIdAtom)
  const setListingId = useSetAtom(listingIdAtom)
  const resetState = useSetAtom(resetDesignerState)
  const setQuantity = useSetAtom(quantityAtom)
  const setMatchingMode = useSetAtom(matchingModeAtom)
  const {v2ContentService} = useAtomValue(rootServiceAtom)
  const party = useAtomValue(partyAtom)
  const [offerData, refetchOffer] = useAtom(offerAtom)
  const refetchBuyerProposals = useSetAtom(buyerProposalsAtom)
  const {data: proposalDetails, loading: detailsLoading} = useAtomValue(proposalDetailsAtom) || {}

  const {data: offer, error: errorPayload, loading: offerLoading} = offerData || {}
  const editable = false
  const declined = offer?.state === OfferState.SELLER_DECLINED_CONFIG
  const signable = offer?.state === OfferState.CONTRACT_SENT && offer?.contract?.state === ContractState.OFFER
  const sentForReview = offer?.state === OfferState.CONFIG_SENT_FOR_SELLER_REVIEW
  const invalidOffer = errorPayload?.error?.kind === 'INVALID_SNAP_QUANTITIES_FOR_OFFER' // Temporary
  const loading = detailsLoading || offerLoading

  useEffect(() => {
    if (offer) {
      setQuantity(offer.requestedQuantitySnap)
      setMatchingMode(offer.rounding)
      setListingId(`${offer.listingId}`)
    }
  }, [offer])

  useEffect(() => {
    return () => {
      resetState()
    }
  }, [])

  useEffect(() => {
    if (id) {
      setOfferId(id)
    }
  }, [id])

  const handleRequest = async () => {
    try {
      await v2ContentService.sendToSellerReview(party.id, id)
      refetchOffer()
      refetchBuyerProposals()

      addSuccess(translate('Successfully sent request'))
    } catch (e) {
      addError(translate('Failed to send request'), e?.correlationId, e?.message)
    }
  }

  //   const handleEdit = async () => {
  //     try {
  //       //TODO: Will be added once api is done
  //       // if (result) {
  //       //   addSuccess(translate('Successfully edited %s', ''))
  //       // }
  //     } catch (e) {
  //       addError(translate('Failed to edit %s', ''), e?.correlationId, e?.message)
  //     }
  //   }

  if (offerData?.error) {
    return (
      <PageBase
        error={
          <ErrorInfo
            error={offerData?.error}
            title={
              invalidOffer
                ? translate('This offer is no longer valid, please create a new one')
                : translate('Failed to load offer')
            }
          />
        }
      />
    )
  }

  return (
    <PageBase
      description={
        declined ? (
          <Chip label={translate('Declined')} color="error" />
        ) : (
          translate('Review certificate mix, key terms and confirm the solution')
        )
      }
      descriptionAside={declined}
      corner={
        <Box gap={2}>
          <ModalLink modal={ModalType.CERTIFICATE_PREVIEW}>
            <Button variant="secondary" type="button">
              {translate('Preview')}
            </Button>
          </ModalLink>

          {/* {editable && (
            <Button onClick={handleEdit} variant="secondary">
              {translate('Edit')}
            </Button>
          )} */}

          {/* {editable && (
            <Button type="button" onClick={() => setRequestDialogOpen(true)}>
              {translate('Request')}
            </Button>
          )} */}
          {signable && (
            <ModalLink modal={ModalType.CERTIFICATE_PREVIEW}>
              <Button type="button">{translate('Sign')}</Button>
            </ModalLink>
          )}
        </Box>
      }
    >
      <ConfirmationDialog
        title={translate('Send purchase configuration for review?')}
        acceptText={translate('Send')}
        declineText={translate('Cancel')}
        onAccept={handleRequest}
        open={requestDialogOpen}
        setOpen={setRequestDialogOpen}
      />
      {offer && <PreviewModal offer={offer} />}
      <Box>
        {sentForReview && !loading && (
          <Alert severity="info" variant="outlined" sx={{mb: 2}}>
            {translate('Offer is currently in review by the seller. You will be notified once they approve or decline')}
          </Alert>
        )}
      </Box>
      <InnerScroll noRightPad>
        <Box direction="column">
          <Box direction="row" gap={2}>
            <Box
              width="23%"
              border
              round={!editable}
              style={{borderRadius: editable ? '8px 8px 0 0' : null, borderBottom: editable ? 'none' : null}}
            >
              <ActionBox title={translate('General')} fullWidth noBorder>
                <GeneralFilter
                  editable={editable}
                  status={MatchStatus.OK}
                  initialLoading={loading}
                  maxQuantity={proposalDetails?.maxAllowedToBuy}
                  shortfall={proposalDetails?.shortfallQuantity}
                  matchedQuantity={proposalDetails?.matchedQuantity}
                  skippedQuantity={proposalDetails?.skippedQuantity}
                  fullCoverExcessMwh={proposalDetails?.fullCoverExcessMwh}
                  newShortfallQuantity={proposalDetails?.newShortfallQuantity}
                  month={offer?.purchaseInterval?.month}
                  year={offer?.purchaseInterval?.year}
                />
              </ActionBox>
            </Box>
            <Box
              width="77%"
              border
              round={!editable}
              style={{
                minHeight: 454,
                borderRadius: editable ? '8px 8px 0 0' : null,
                borderBottom: editable ? 'none' : null,
              }}
            >
              <ActionBox
                title={
                  <Box direction="row" justify="space-between" gap={2} $maxHeight="28px">
                    <Text size="mlarge" nowrap>
                      {translate('Offer details')}
                    </Text>
                  </Box>
                }
                fullWidth
                noMargin
                noBorder
              >
                <Box direction="column" gap={1.5}>
                  <ProposalDetailsInfo
                    draft={editable}
                    averagePrice={+proposalDetails?.eacPrice}
                    totalPrice={+proposalDetails?.totalPrice}
                    currency={proposalDetails?.currency}
                    greenCoverage={proposalDetails?.greenCoverage}
                    loading={loading}
                  />
                  <Box>
                    <CertDesignerChart
                      technology={offer?.product?.technology}
                      timeseries={proposalDetails?.timeseries}
                      loading={loading}
                      productName={proposalDetails?.product.name}
                    />
                  </Box>
                </Box>
                <Box height={0.5}>{loading && <LinearProgress />}</Box>
              </ActionBox>
            </Box>
          </Box>

          <Box width="100%" direction="row" style={{minHeight: editable ? 295 : null}} gap={2}>
            {/* {editable && (
              <Box width="23%" title={translate('Add products')} border style={{borderRadius: '0px 0px 8px 8px'}}>
                <ProductMap products={mapProduct} />
              </Box>
            )}
            {editable && (
              <Box width="77%" border style={{borderRadius: '0 0 8px 8px'}}>
                <InnerScroll noRightPad>
                  <Box>
                    <ProductDetails product={product} quantity={filter.quantity} />
                  </Box>
                </InnerScroll>
              </Box>
            )} */}
            {!editable && (
              <Box width="100%" margin={{top: 2}}>
                {loading ? (
                  <Skeleton variant="rectangular" height={160} />
                ) : (
                  <OfferProduct
                    product={offer?.product}
                    currency={offer?.currency}
                    quantity={offer?.matchedQuantitySnap}
                    averagePrice={offer?.averagePrice}
                  />
                )}
              </Box>
            )}
          </Box>
        </Box>
      </InnerScroll>
    </PageBase>
  )
}

export default CertDesignerOffer
