import {consumerJoyrideStartAtom} from 'atoms/consumerJoyride'
import {rootServiceAtom} from 'atoms/general'
import {partyAtom, userAtom} from 'atoms/party'
import {greenCertificatesAtom, overviewDateFilter, overviewDateFiltermAtom} from 'atoms/portfolioOverview'
import Box from 'components/Box'
import Heading from 'components/Heading'
import InnerScroll from 'components/InnerScroll'
import {ModalType} from 'components/Modal/IModal'
import PeriodSelect from 'components/PeriodSelect'
import Text from 'components/Text'
import TrophyIcon from '@mui/icons-material/EmojiEvents'
import CertificatesChart from 'components/Platform/Portfolio/GreenCertificate/CertificatesChart'
import CertificateSummaryItem from 'components/Platform/Portfolio/GreenCertificate/CertificateSummaryItem'
import ExcessModal from 'components/Platform/Portfolio/GreenCertificate/ExcessModal'
import LoadingSkeleton from 'components/Platform/Portfolio/GreenCertificate/LoadingSkeleton'
import ResourceSummary from 'components/Platform/Portfolio/Summary/ResourceSummary'
import ResourceSummaryItem from 'components/Platform/Portfolio/Summary/ResourceSummaryItem'
import {SellExcessPayload} from 'domain/INewTimeseriesItem'
import {EnergyUnit} from 'helpers/conversion'
import {getPlatformUrl} from 'helpers/party'
import {isAdmin} from 'helpers/user'
import useAlerts from 'hooks/useAlerts'
import useLocalization from 'hooks/useLocalization'
import useTheme from 'hooks/useTheme'
import {useAtomValue, useSetAtom} from 'jotai'
import React, {useEffect} from 'react'

const GreenCertificates: React.FC<React.PropsWithChildren> = () => {
  const theme = useTheme()
  const {data: certData, loading, error} = useAtomValue(greenCertificatesAtom)
  const party = useAtomValue(partyAtom)
  const {translate} = useLocalization()
  const {addSuccess} = useAlerts()
  const {excess, missing} = certData?.summary || {}
  const {fullCoverageExcessMwh, ppaExcessMwh, sellable} = excess || {}
  const hasData = certData?.consumption?.some(item => item.value > 0)
  const totalMissing = certData?.missing?.reduce((acc, item) => acc + item.value, 0)
  const user = useAtomValue(userAtom)
  const rootStore = useAtomValue(rootServiceAtom)
  const {v2ContentService} = rootStore
  const setDateFilter = useSetAtom(overviewDateFilter)
  const setOverviewDateFilter = useSetAtom(overviewDateFiltermAtom)
  const setConsumerJoyrideStart = useSetAtom(consumerJoyrideStartAtom)

  const handleExcessSell = async (payload: SellExcessPayload) => {
    await v2ContentService.sellCertExcessRequest(party.id, payload)
    addSuccess(translate('Successfully sent sell request'))
  }

  useEffect(() => {
    if (user && !user.buyerGuideCompletedOn) {
      setConsumerJoyrideStart(true)
    }
  }, [user])

  if (loading) {
    return <LoadingSkeleton />
  }

  return (
    <Box pad={{bottom: 2}}>
      <ExcessModal onSell={handleExcessSell} excess={sellable} />
      <PeriodSelect
        hideCustom
        hideQuarter
        hideWholeYear
        setValue={setDateFilter}
        onMonthChange={(month, year) => setOverviewDateFilter({month, year})}
      />
      <ResourceSummary noPad>
        <ResourceSummaryItem
          title={translate('Consumption')}
          tooltipText={translate('Sum of consumption')}
          value={certData.consumption.reduce((acc, item) => acc + item.value, 0)}
          color={theme.colors.blue7}
          showFilledProgress
          unit={translate(EnergyUnit.MWh)}
        />
        <ResourceSummaryItem
          title={translate('PPA')}
          tooltipText={translate('Sum of contracted ppa')}
          value={certData.deliveredPpaProduction.reduce((acc, item) => acc + item.value, 0)}
          color={theme.colors.green2}
          showFilledProgress
          unit={translate('EACs')}
        />
        <ResourceSummaryItem
          title={translate('EAC')}
          tooltipText={translate('Sum of purchased eacs')}
          value={certData.reservedCertProduction.reduce((acc, item) => acc + item.value, 0)}
          color={theme.colors.green1}
          showFilledProgress
          unit={translate('EACs')}
        />
        <ResourceSummaryItem
          title={translate('PPA & EAC')}
          tooltipText={translate('Sum of total')}
          value={certData.totalProduction.reduce((acc, item) => acc + item.value, 0)}
          color={theme.colors.green7}
          showFilledProgress
          unit={translate('EACs')}
        />
        <ResourceSummaryItem
          title={translate('Shortfall')}
          tooltipText={translate('Sum of shortfall')}
          value={totalMissing}
          unit={translate('EACs')}
          showFilledProgress
          color={theme.colors.grey2}
        />
        <ResourceSummaryItem
          title={translate('Excess')}
          tooltipText={translate('Sum of excess')}
          value={certData.excess.reduce((acc, item) => acc + item.value, 0)}
          unit={translate('EACs')}
          showFilledProgress
          color={theme.colors.yellow2}
        />
      </ResourceSummary>

      <InnerScroll noRightPad>
        <Box pad={{top: 2}}></Box>
        {error && (
          <Box justify="center">
            <Heading margin={{top: 4}}>{translate('Failed to load Certificates chart')}</Heading>
          </Box>
        )}
        {!hasData ? (
          <Box justify="center">
            <Heading margin={{top: 4}}>{translate('No data for this interval')}</Heading>
          </Box>
        ) : (
          <>
            <CertificatesChart certData={certData} />
            <Heading margin={2} size="large" style={{textAlign: 'center'}}>
              {translate('Summary')}
            </Heading>

            {!missing && sellable.length === 0 && (
              <Box direction="row" gap={1} align="center" justify="center" margin={{bottom: 2}}>
                <Text size="xlarge">{translate('Congratulations on joining 100% club!')}</Text>
                <TrophyIcon sx={{color: theme.colors.limeGreen, fontSize: 60}} />
              </Box>
            )}

            <Box direction="row" gap={1} justify="center" wrap>
              {sellable?.map(({certificateQuantity, technologyKind}, index) => (
                <CertificateSummaryItem
                  title={translate('Excess certificates')}
                  key={index}
                  unit="EAC"
                  action={isAdmin(user) ? translate('Offer to market') : undefined}
                  quantity={certificateQuantity}
                  tecnology={technologyKind}
                  modal={ModalType.EXCESS_CERTIFICATES}
                  modalValue={technologyKind}
                />
              ))}
              {ppaExcessMwh > 0 && (
                <CertificateSummaryItem
                  title={translate('Excess certificates from Delivered PPA')}
                  unit="EAC"
                  quantity={ppaExcessMwh}
                  helperText={translate('Note you cannot offer excess from PPA to the market')}
                />
              )}
              {fullCoverageExcessMwh > 0 && (
                <CertificateSummaryItem
                  title={translate('Excess certificates from Full coverage')}
                  unit="EAC"
                  quantity={fullCoverageExcessMwh}
                  helperText={translate('Note you cannot offer excess from Full coverage to the market')}
                />
              )}

              {missing && (
                <CertificateSummaryItem
                  title={translate('Shortfall of energy attribute certificates')}
                  unit="EAC"
                  action={translate('Go to Renewabl Trade')}
                  quantity={totalMissing}
                  to={`${getPlatformUrl(party)}/buy/marketplace/request`}
                  confirmDialogText={translate('You are leaving Renewabl Track to Renewabl Trade')}
                />
              )}
            </Box>
          </>
        )}
      </InnerScroll>
    </Box>
  )
}

export default GreenCertificates
