import React from 'react'
import styled from 'styled-components'
import ITheme from '../theme/ITheme'

export interface IProps extends React.PropsWithChildren {
  size?: 'xsmall' | 'small' | 'medium' | 'large'
  value?: number
  color?: string
  containerColor?: string
  max?: number
  opacity?: number
}

const height = {
  xsmall: 8,
  small: 12,
  medium: 16,
  large: 24,
}

const radius = {
  xsmall: 8,
  small: 20,
  medium: 24,
  large: 32,
}

export const ProgressFill = styled.div.attrs<IProps>(props => ({
  style: {
    width: typeof props.value === 'number' ? `${(props.value / (props.max || 100)) * 100}%` : null,
  },
}))<IProps>`
  height: 100%;
  ${props =>
    props.color?.includes('gradient') ? `background-image: ${props.color};` : `background-color: ${props.color};`}
  color: ${props => (props.theme as ITheme).colors.common.white};
  flex-shrink: 0;
  flex-grow: 0;
  ${props => props.opacity && `opacity: ${props.opacity};`}

  &:last-child {
    border-top-right-radius: 32px;
    border-bottom-right-radius: 32px;
  }
`

export const ProgressContainer = styled.div<IProps>`
  height: ${props => height[props.size || 'medium']}px;
  border-radius: ${props => radius[props.size || 'medium']}px;
  background-color: ${props => props.containerColor || (props.theme as ITheme).colors.outline};
  position: relative;
  text-align: right;
  font-size: 12px;
  line-height: 24px;
  overflow: hidden;
  display: flex;
`

const Progressbar: React.FC<IProps> = props => (
  <ProgressContainer {...props}>
    <ProgressFill {...props} />
  </ProgressContainer>
)

export default Progressbar
