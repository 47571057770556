import React from 'react'
import {rootServiceAtom} from 'atoms/general'
import {managedUsersAtom, pageAtom, pageSizeAtom} from 'atoms/managedUsers'
import Box from 'components/Box'
import Button from 'components/Button'
import InnerScroll from 'components/InnerScroll'
import Page from 'components/Page'
import {PaginateWithSelection} from 'components/Paginate/Paginate'
import TableSkeleton from 'components/TableSkeleton'
import {getPlatformUrl} from 'helpers/party'
import useAlerts from 'hooks/useAlerts'
import useLocalization from 'hooks/useLocalization'
import {useAtom, useAtomValue, useSetAtom} from 'jotai'
import {Link, Redirect} from 'react-router-dom'
import ManagePartyMembersTable from 'components/Platform/Account/ManagePartyMembersTable'
import {partyAtom} from 'atoms/party'

const RESULT_PER_PAGE_OPTIONS = [25, 50, 100]

const ManageUsers: React.FC<React.PropsWithChildren> = () => {
  const {translate} = useLocalization()
  const {addError, addSuccess} = useAlerts()
  const party = useAtomValue(partyAtom)
  const urlPrefix = getPlatformUrl(party)
  const {data: managedUsers, loading} = useAtomValue(managedUsersAtom)
  const [page, setPage] = useAtom(pageAtom)
  const [pageSize, setPageSize] = useAtom(pageSizeAtom)
  const rootService = useAtomValue(rootServiceAtom)
  const {v2ContentService} = rootService
  const refetchManagedUsers = useSetAtom(managedUsersAtom)

  const handleDisableUser = async (asPartyId: number, managedPersonId: number) => {
    try {
      await v2ContentService.disablePartyMember(asPartyId, managedPersonId)
      refetchManagedUsers()

      addSuccess(translate('User suspended') + '.')
    } catch (err) {
      addError(translate('Failed to suspend user') + '.', err?.correlationId, err?.message)
    }
  }

  const handleResendInvite = async (asPartyId: number, managedPersonId: number) => {
    try {
      await v2ContentService.resendUserInvite(asPartyId, managedPersonId)
      addSuccess(translate('Invite resent') + '.')
    } catch (err) {
      addError(translate('Failed to resend invite') + '.', err?.correlationId, err?.message)
    }
  }

  const handleEnableUser = async (asPartyId: number, managedPersonId: number) => {
    try {
      await v2ContentService.enablePartyMember(asPartyId, managedPersonId)
      refetchManagedUsers()

      addSuccess(translate('User activated') + '.')
    } catch (err) {
      addError(translate('Failed to activate user') + '.', err?.correlationId, err?.message)
    }
  }

  if (party && !party?.partyRole) {
    return <Redirect to={getPlatformUrl(party)} />
  }

  if (loading) {
    return (
      <Page title={`${party?.name} users`} description={translate('Manage your company users')}>
        <TableSkeleton />
      </Page>
    )
  }

  return (
    <>
      <Page
        corner={
          <Link to={`${urlPrefix}/company/members/add`}>
            <Button margin={{top: 1}}>{translate('Add')}</Button>
          </Link>
        }
        title={`${party?.name} users`}
        description={translate('Manage your company users')}
      >
        <InnerScroll>
          <ManagePartyMembersTable
            urlPrefix={urlPrefix}
            partyMembers={managedUsers?.data || []}
            handleResendInvite={handleResendInvite}
            handleEnableUser={handleEnableUser}
            handleDisableUser={handleDisableUser}
          />
        </InnerScroll>
        <Box align="flex-end" justify="center">
          <PaginateWithSelection
            activePage={page}
            resultsPerPage={pageSize}
            resultsPerPageOptions={RESULT_PER_PAGE_OPTIONS}
            totalPages={managedUsers?.totalPages || 1}
            handlePageChange={value => setPage(value)}
            handleResultsPerPageChange={value => {
              setPageSize(value)
              setPage(1)
            }}
          />
        </Box>
      </Page>
    </>
  )
}

export default ManageUsers
