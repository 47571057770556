import {contractedVolumesAtom, overviewDateFiltermAtom, overviewDateFilter} from 'atoms/portfolioOverview'
import Box from 'components/Box'
import Heading from 'components/Heading'
import PeriodSelect from 'components/PeriodSelect'
import OverviewSkeleton from 'components/Platform/Chart/OverviewSkeleton'
import ContractedVolumesChart from 'components/Platform/Portfolio/Overview/ContractedVolumesChart'
import ResourceSummaryItem from 'components/Platform/Portfolio/Summary/ResourceSummaryItem'
import {IContractedVolumeContract} from 'domain/Portfolio'
import {DateFormat, formatDate} from 'helpers/date'
import {useAtom, useAtomValue, useSetAtom} from 'jotai'
import {isEmpty} from 'lodash-es'
import React from 'react'
import EnergySummaryItem from 'components/Platform/Portfolio/Summary/EnergySummaryItem'
import ResourceSummary from 'components/Platform/Portfolio/Summary/ResourceSummary'
import Table from 'components/Table'
import TextLink from 'components/TextLink'
import useLocalization from 'hooks/useLocalization'
import useTheme from 'hooks/useTheme'
import {getPlatformUrl} from 'helpers/party'
import {partyAtom} from 'atoms/party'

interface IContractedVolumeContractWithExternal extends IContractedVolumeContract {
  external?: boolean
}

const ContractedVolume: React.FC<React.PropsWithChildren> = () => {
  const {translate} = useLocalization()
  const party = useAtomValue(partyAtom)
  const [dateFilter, setDateFilter] = useAtom(overviewDateFilter)
  const setContractedVolumesDateFilter = useSetAtom(overviewDateFiltermAtom)
  const [{data: contractedVolumes, loading, error}] = useAtom(contractedVolumesAtom)
  const theme = useTheme()
  const {
    contracts = [],
    externalContracts,
    summary,
    contractsTimeseries = [],
    consumption,
    externalContractsTimeseries,
  } = contractedVolumes || {}

  const extContractsWithFlag = externalContracts?.map(contract => ({...contract, external: true})) || []
  const allContracts = [...contracts, ...extContractsWithFlag]

  const extTimeseriesWithFlag = externalContractsTimeseries?.map(item => ({...item, external: true})) || []
  const allTimeseries = [...contractsTimeseries, ...extTimeseriesWithFlag]

  const noData = isEmpty(allContracts)

  const consumptionIsZero = summary?.consumptionMwh === 0
  const ppaIsZero = summary?.electricityMwh === 0
  const eacIsZero = summary?.certificatesQuantity === 0

  const hideViews = consumptionIsZero && ppaIsZero && eacIsZero

  if (loading) {
    return <OverviewSkeleton></OverviewSkeleton>
  }

  if (error) {
    return (
      <Box justify="center">
        <Heading margin={{top: 4}}>{translate('Failed to load contracted volumes chart')}</Heading>
      </Box>
    )
  }

  return (
    <>
      <PeriodSelect
        hideCustom
        hideQuarter
        hideWholeYear
        setValue={setDateFilter}
        onMonthChange={(month, year) => setContractedVolumesDateFilter({month, year})}
      />
      {!hideViews && (
        <Box pad={{bottom: 2}}>
          <ResourceSummary noPad>
            <EnergySummaryItem
              title={translate('Consumption in selected period')}
              value={summary.consumptionMwh}
              color={theme.colors.blue7}
              showFilledProgress
            />
            <EnergySummaryItem
              title={translate('Direct purchase volume')}
              value={summary.electricityMwh}
              color={theme.colors.yellow3}
              showFilledProgress
            />
            <ResourceSummaryItem
              title={translate('EAC quantity')}
              value={summary.certificatesQuantity}
              unit={translate('EACs')}
              color={theme.colors.green2}
              showFilledProgress
            />
          </ResourceSummary>
        </Box>
      )}
      {noData ? (
        <Box justify="center">
          <Heading margin={{top: 4}}>{translate('No data for this interval')}</Heading>
        </Box>
      ) : (
        <>
          {!hideViews ? (
            <ContractedVolumesChart consumption={consumption} contractsTimeseries={allTimeseries} />
          ) : (
            <Box justify="center">
              <Heading margin={{top: 5, bottom: 2}}>
                {translate('No external contract data present or EAC purchases have occurred in the selected period')}
              </Heading>
            </Box>
          )}
          <Table
            data={allContracts}
            isLoading={loading}
            columns={[
              {
                title: translate('Contract'),
                accessor: (item: IContractedVolumeContractWithExternal) => item,
                render: (item: IContractedVolumeContractWithExternal) =>
                  item.external ? (
                    <TextLink to={`${getPlatformUrl(party)}/portfolio/external-contracts/${item.id}`}>
                      EXTERNAL-#{item.id}
                    </TextLink>
                  ) : (
                    <TextLink to={`${getPlatformUrl(party)}/portfolio/current-contracts/${item.id}`}>
                      #{item.id}
                    </TextLink>
                  ),
              },
              {
                title: translate('Start'),
                accessor: (item: IContractedVolumeContractWithExternal) =>
                  `${item.validFrom ? formatDate(item.validFrom, DateFormat.DAY_MONTH_YEAR) : ''}`,
              },
              {
                title: translate('End'),
                accessor: (item: IContractedVolumeContractWithExternal) =>
                  `${item.validTo ? formatDate(item.validTo, DateFormat.DAY_MONTH_YEAR) : ''}`,
              },
              {
                title: translate('Renewable energy volume'),
                accessor: (item: IContractedVolumeContractWithExternal) => {
                  return `${item.volumeMwh} ${translate('EACs')}`
                },
              },
              {
                title: '',
                accessor: (contract: IContractedVolumeContractWithExternal) => contract,
                render: (contract: IContractedVolumeContractWithExternal) =>
                  contract.volumeMwh === 0 && (
                    <TextLink
                      to={`${getPlatformUrl(party)}/portfolio/external-contracts/${contract.id}?tab=upload&year=${
                        dateFilter.startYear
                      }&month=${dateFilter.startMonth}`}
                    >
                      {translate('Upload')}
                    </TextLink>
                  ),
              },
            ]}
          />
        </>
      )}
    </>
  )
}

export default ContractedVolume
