import React from 'react'
import Box from 'components/Box'
import Text from 'components/Text'
import CircularProgress, {getColorByValue} from 'components/CircularProgress'
import InverseKeyFeature from 'components/Platform/InverseKeyFeature'
import useLocalization from 'hooks/useLocalization'
import useTheme from 'hooks/useTheme'
import {Skeleton, Tooltip} from '@mui/material'
import {formatDecimal} from 'helpers/format'
import {getCurrencySymbol} from 'helpers/price'
import {CurrencyCode} from 'domain/IPrice'

const getValue = value => {
  if (value || value === 0) {
    return `${value.toFixed(0)}`
  }

  return '-'
}

const CircularMatch = ({value, draft, loading}) => {
  const theme = useTheme()
  const tooltipText = draft
    ? 'Your hourly matching scores after signing this contract'
    : 'Your hourly matching scores at the time of signing this contract'

  return (
    <Tooltip title={tooltipText} arrow>
      <div>
        <Box margin={{bottom: 1}} justify="center">
          <CircularProgress
            trailColor={theme.colors.light1}
            value={loading ? 0 : value}
            color={getColorByValue(value, theme)}
            circleRatio={1}
          >
            {loading ? (
              <Skeleton variant="circular" width={40} height={40} />
            ) : (
              <Text
                size="xlarge"
                semibold
                color={getColorByValue(value, theme)}
                font={theme.font.numbers}
                pad={{top: 0.5}}
              >
                {getValue(value)}
              </Text>
            )}
          </CircularProgress>
        </Box>
      </div>
    </Tooltip>
  )
}

interface IProps extends React.PropsWithChildren {
  totalPrice: number
  averagePrice: number
  currency: CurrencyCode
  greenCoverage?: {
    hourlyPercent: number
    monthlyPercent: number
    yearlyPercent: number
  }
  draft?: boolean
  loading?: boolean
  noGreenCoverage?: boolean
}

const ProposalDetailsInfo: React.FC<IProps> = ({
  draft,
  loading,
  totalPrice,
  averagePrice,
  currency,
  greenCoverage,
  noGreenCoverage,
}) => {
  const {hourlyPercent, yearlyPercent, monthlyPercent} = greenCoverage || {}
  const {translate} = useLocalization()

  return (
    <Box direction="row" round>
      <Box pad={1} direction="row" align="end" width="100%" justify="space-between" height="124px">
        <Box direction="row" width="350px" gap={5}>
          <InverseKeyFeature
            dataCy="price-per-certificate"
            title={translate('Price/Certificate')}
            value={averagePrice}
            formatFn={v => formatDecimal(v)}
            fallbackValueText={'-'}
            nowrap
            currency={getCurrencySymbol(currency)}
            loading={loading}
          />

          <InverseKeyFeature
            dataCy="total-price"
            title={translate('Total price')}
            value={totalPrice}
            fallbackValueText={'-'}
            nowrap
            formatFn={v => formatDecimal(v)}
            currency={getCurrencySymbol(currency)}
            loading={loading}
          />
        </Box>

        {!noGreenCoverage && (
          <Box direction="row" gap={5}>
            <InverseKeyFeature title={translate('Hourly match')}>
              <CircularMatch value={hourlyPercent} draft={draft} loading={loading} />
            </InverseKeyFeature>

            <InverseKeyFeature title={translate('Monthly match')}>
              <CircularMatch draft={draft} value={monthlyPercent} loading={loading} />
            </InverseKeyFeature>
            <InverseKeyFeature title={translate('Annual est.match')}>
              <CircularMatch draft={draft} value={yearlyPercent} loading={loading} />
            </InverseKeyFeature>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default ProposalDetailsInfo
