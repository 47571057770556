import {atom, PrimitiveAtom} from 'jotai'
import IParty from 'domain/IParty'
import {IUserProfile} from 'domain/IUserProfile'
import IPartyMember from 'domain/IPartyMember'

export const appLoadedAtom = atom(false)
export const partyAtom = atom<IParty>(null) as PrimitiveAtom<IParty>
export const userAtom = atom<IUserProfile>(null) as PrimitiveAtom<IUserProfile>
export const partyMembersAtom = atom<IPartyMember[]>([]) as PrimitiveAtom<IPartyMember[]>
export const transitionAtom = atom(false)

export const selectedPartyMemberAtom = atom(null, (get, set, update: IPartyMember) => {
  set(selectedPartyMemberAtom, update)
  set(partyAtom, update?.party)
}) as PrimitiveAtom<IPartyMember>

export const phoneVerifiedModalAtom = atom(true)
