import React from 'react'
import ResourceGateway from 'components/ResourceGateway'
import SalesContractListPage from 'components/Platform/Portfolio/SalesContract/SalesContractListPage'
import SignedAgreements from 'components/Platform/Seller/Portfolio/SignedContracts/SignedAgreements'

const SalesContracts: React.FC<React.PropsWithChildren> = () => {
  return (
    <ResourceGateway
      single={routeProps => <SignedAgreements id={routeProps.match.params.id} />}
      list={() => <SalesContractListPage />}
    />
  )
}

export default SalesContracts
