import {atomWithCompare} from 'atoms/utils'
import dayjs from 'dayjs'
import {generateYears, getCurrentYear, getMonths, getPrevMonth, getPrevMonthYear} from 'helpers/date'
import {atom} from 'jotai'

export enum PeriodType {
  YEAR = 'YEAR',
  QUARTER = 'QUARTER',
  MONTH = 'MONTH',
  CUSTOM = 'CUSTOM',
  QUICK_LINK = 'QUICK_LINK',
}

export enum PeriodValue {
  WHOLE_YEAR = 'Whole Year',
  CUSTOM_PERIOD = 'Custom Period',
}

export enum QuickLinkValue {
  YESTERDAY = 'YESTERDAY',
  CUSTOM_DAY = 'Custom Day',
  YTD = 'YTD',
}

export interface IValue {
  startMonth: string
  startYear: number
  endMonth: string
  endYear: number
}

export interface IExtenderValue extends IValue {
  startDay?: number
  endDay?: number
}

export interface IPeriod {
  period: PeriodType
  value: string
}

export const MONTHS = getMonths()
export const QUARTERS = ['Q1', 'Q2', 'Q3', 'Q4']
export const YEARS = generateYears(getCurrentYear(), 5).reverse()

export const periodAtom = atom<IPeriod>({
  period: PeriodType.MONTH,
  value: getPrevMonth(),
})

const DEFAULT_YEAR = getPrevMonthYear()

const defaultPeriod = {
  startMonth: getPrevMonth(),
  startYear: DEFAULT_YEAR,
  endMonth: getPrevMonth(),
  endYear: DEFAULT_YEAR,
}

const defaultDate = dayjs()
const defaultYesterday = dayjs().subtract(1, 'day')

export const yesterdayValue = {
  startMonth: defaultYesterday.format('MMMM'),
  startYear: defaultYesterday.year(),
  startDay: defaultYesterday.date(),
  endMonth: defaultYesterday.format('MMMM'),
  endYear: defaultYesterday.year(),
  endDay: defaultYesterday.date(),
}

export const ytdValue = {
  startMonth: MONTHS[0],
  startYear: defaultDate.year(),
  endMonth: getPrevMonth(),
  endYear: defaultDate.year(),
}

export const customPeriodAtom = atomWithCompare(defaultPeriod)

export const yearAtom = atom(DEFAULT_YEAR)

export const resetPeriodAtom = atom(null, (get, set) => {
  set(periodAtom, {period: PeriodType.MONTH, value: getPrevMonth()})
  set(yearAtom, DEFAULT_YEAR)
})
