import {Environments, initializePaddle} from '@paddle/paddle-js'
import {atomWithRefresh} from 'jotai/utils'
import {rootServiceAtom} from './general'
import {partyAtom} from './party'
import {atomWithCleanup, loadable} from './utils'
import env from '@beam-australia/react-env'
import {atom} from 'jotai'

export const paddleAtom = loadable(
  atomWithRefresh(async get => {
    const services = get(rootServiceAtom)
    const party = get(partyAtom)

    const paddleData = await services.v2ContentService.getPaddlePricing(party?.id)

    const paddleInstance = await initializePaddle({
      environment: env('PADDLE_ENVIRONMENT') as Environments,
      token: paddleData.paddleClientSideToken,
    })

    const monthList = [
      {
        quantity: 1,
        priceId: paddleData.proPlanMonthlyPriceId,
      },
      {
        quantity: 1,
        priceId: paddleData.enterprisePlanMonthlyPriceId,
      },
    ]

    const yearList = [
      {
        quantity: 1,
        priceId: paddleData.proPlanAnnualPriceId,
      },
      {
        quantity: 1,
        priceId: paddleData.enterprisePlanAnnualPriceId,
      },
    ]

    return {
      paddleInstance,
      monthList,
      yearList,
    }
  }),
)

export const billingCycleAtom = atomWithCleanup('month', true)

export enum products {
  PRO = 0,
  ENTERPRISE = 1,
}

export const paddlePricingAtom = loadable(
  atom(async get => {
    const paddle = get(paddleAtom)

    const {data} = paddle
    const {paddleInstance, monthList, yearList} = data

    if (!data?.paddleInstance) {
      return
    }

    const cycle = get(billingCycleAtom)

    const itemsList = cycle === 'month' ? monthList : yearList
    const request = {
      items: itemsList,
    }

    let proPrice: string | null = null
    let enterprisePrice: string | null = null

    const result = await paddleInstance?.PricePreview(request)
    const items = result?.data?.details?.lineItems
    for (const item of items) {
      if (item.price.id === itemsList[products.PRO].priceId) {
        proPrice = item.formattedTotals.subtotal
      } else if (item.price.id === itemsList[products.ENTERPRISE].priceId) {
        enterprisePrice = item.formattedTotals.subtotal
      }
    }

    return {
      proPrice: proPrice?.replace('.00', ''),
      enterprisePrice: enterprisePrice?.replace('.00', ''),
    }
  }),
)
