import {IPaginatedExtendedResult} from './IPaginateResult'
import {PriceUnit} from './IPrice'
import {ContractState} from './IContract'

export interface IOfferMeta {
  pricingParameterGroupId: number
}

export enum OfferState {
  CONTRACT_SENT = 'CONTRACT_SENT',
  CONTRACT_SIGNED = 'CONTRACT_SIGNED',
  DELETED = 'DELETED',
  CONFIG_SENT_FOR_SELLER_REVIEW = 'CONFIG_SENT_FOR_SELLER_REVIEW',
  CONFIG_IN_PROGRESS = 'CONFIG_IN_PROGRESS',
  SELLER_DECLINED_CONFIG = 'SELLER_DECLINED_CONFIG',
}

export enum OfferKind {
  CERTIFICATE_TRADE = 'CERTIFICATE_TRADE',
  ENERGY = 'ENERGY',
}

export interface IShortOffer {
  id: number
  kind: OfferKind
  state: OfferState
  name: string
}

export interface INewOffer {
  id: number
  certPrice: number
  contract: {
    id: number
    name: string
    validFrom: string
    validTo: string
    state: ContractState
    signingDate: string
    volumeMwh: number
    contractType: {
      id: number
      name: string
    }
  }
  startDate: string
  endDate: string
  kind: OfferKind
  name: string
  priceUnit: PriceUnit
  purchaseInterval: {
    year: number
    month: string
  }
  seller?: {
    id: number
    name: string
  }
  customer?: {
    id: number
    name: string
  }
  state: OfferState
  totalPrice: number
  validFrom: string
  validTo: string
}

export interface INewSellerOfferPayload extends IPaginatedExtendedResult<INewOffer> {
  summary?: {
    avgVolumeMwh: number
    pricePerMwh: number
    totalVolumeMwh: number
    priceUnit?: PriceUnit
  }
}
