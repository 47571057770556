import Box from 'components/Box'
import Button from 'components/Button'
import Loader from 'components/Loader'
import Logo from 'components/Logo'
import Modal from 'components/Modal'
import ModalHeader from 'components/Modal/ModalHeader'
import Text from 'components/Text'
import useLocalization from 'hooks/useLocalization'
import useProfile from 'hooks/useProfile'
import useServices from 'hooks/useServices'
import {isNil} from 'lodash-es'
import React, {useState} from 'react'
import {LederhosenApiErrorType} from 'services/LederhosenApiErrorMapper'
import PhoneNumberForm from './PhoneNumberForm'
import SmsVerificationForm from './PhoneVerificationForm'
import GreenEnergy from 'components/Icons/GreenEnergy'
import useAlerts from 'hooks/useAlerts'
import {rootServiceAtom} from 'atoms/general'
import {useAtom, useAtomValue, useSetAtom} from 'jotai'
import {IUserProfile} from 'domain/IUserProfile'
import {phoneVerifiedModalAtom, userAtom} from 'atoms/party'
import {isEmpty} from 'lodash'

enum VerificationStep {
  ENTER_PHONE_NUMBER = 'ENTER_PHONE_NUMBER',
  CONFIRM_SENDING_SMS = 'CONFIRM_SENDING_SMS',
  SEND_SMS_LOADING = 'SEND_SMS_LOADING',
  SMS_RECEIVED = 'SMS_RECEIVED',
  PHONE_VERIFIED = 'PHONE_VERIFIED',
  SEND_SMS_FAILED = 'SEND_SMS_FAILED',
  VERIFICATION_SUCCESS = 'VERIFICATION_SUCCESS',
  VERIFICATION_ERROR = 'VERIFICATION_ERROR',
  VERIFICATION_FAILED = 'VERIFICATION_FAILED',
  PHONE_ALREADY_VERIFIED = 'PHONE_ALREADY_VERIFIED',
  ATTEMPTS_LIMIT_EXCEEDED = 'ATTEMPTS_LIMIT_EXCEEDED',
}

const getInitialPhoneVerificationStep = (user: IUserProfile): VerificationStep => {
  if (isNil(user.phoneNumber) || isEmpty(user.phoneNumber)) {
    return VerificationStep.ENTER_PHONE_NUMBER
  }

  if (user.phoneNumberVerified) {
    return VerificationStep.PHONE_ALREADY_VERIFIED
  }

  return VerificationStep.CONFIRM_SENDING_SMS
}

// TODO: this should come from the API
const REMAINING_MINUTES = 15

interface IProps extends React.PropsWithChildren {
  setShowPhoneVerificationModal: (show: boolean) => void
}

const PhoneNumberVerificationModal: React.FC<IProps> = ({setShowPhoneVerificationModal}) => {
  const {translate} = useLocalization()
  const [user, setUser] = useAtom(userAtom)
  const {addSuccess} = useAlerts()
  const rootService = useAtomValue(rootServiceAtom)
  const setPhoneVerifiedModal = useSetAtom(phoneVerifiedModalAtom)
  const {contentService} = useServices()
  const initialVerificationStep = getInitialPhoneVerificationStep(user)
  const [verificationStep, setVerificationStep] = useState<VerificationStep>(initialVerificationStep)
  const phoneNumber = user?.phoneNumber
  const [errorMsg, setErrorMsg] = useState<string>(null)

  const handleClose = () => {
    setShowPhoneVerificationModal(false)
  }

  const handleSavePhone = async formData => {
    const phoneNumber = formData.phoneNumber

    await rootService.v2ContentService.updateUserProfile({...(user as IUserProfile), phoneNumber})
    setUser({...user, phoneNumber})
    setVerificationStep(VerificationStep.CONFIRM_SENDING_SMS)
  }

  const handleConfirmVerification = async form => {
    try {
      await contentService.confirmUserPhoneNumber(form.pin)

      setPhoneVerifiedModal(false)
      setUser({...user, phoneNumberVerified: true})
      setVerificationStep(VerificationStep.VERIFICATION_SUCCESS)

      handleClose()
      addSuccess(translate('Phone number verified successfully') + '.')
    } catch (ex) {
      if (ex?.errorType === LederhosenApiErrorType.VERIFICATION_CODE_INVALID) {
        setErrorMsg(ex.message)
      } else {
        setVerificationStep(VerificationStep.VERIFICATION_ERROR)
      }
    }
  }

  const handleCancel = () => {
    handleClose()
    setVerificationStep(VerificationStep.CONFIRM_SENDING_SMS)
  }

  const handleSendSMS = async () => {
    setVerificationStep(VerificationStep.SEND_SMS_LOADING)
    try {
      const prefixedPhoneNum = user.phoneNumber.startsWith('+') ? user.phoneNumber : '+' + user.phoneNumber

      await contentService.sendUserPhoneVerification(prefixedPhoneNum)
      setVerificationStep(VerificationStep.SMS_RECEIVED)
    } catch (ex) {
      if (ex?.errorType === LederhosenApiErrorType.VERIFICATIONS_LIMIT_EXCEEDED) {
        setVerificationStep(VerificationStep.ATTEMPTS_LIMIT_EXCEEDED)
      } else {
        setVerificationStep(VerificationStep.SEND_SMS_FAILED)
      }
    }
  }

  if (!user) {
    return null
  }

  return (
    <Modal onClose={handleClose}>
      <ModalHeader title={translate('Confirm your phone number')} />
      <Box style={{minWidth: '500px'}}>
        {verificationStep === VerificationStep.ENTER_PHONE_NUMBER && (
          <PhoneNumberForm onSubmit={handleSavePhone}></PhoneNumberForm>
        )}
        {verificationStep === VerificationStep.SEND_SMS_LOADING && (
          <Box justify="center" direction="column">
            <Box justify="center">
              <Logo />
            </Box>
            <Text margin={{top: 3, bottom: 3}} size="large">
              Sending your verification code to {phoneNumber}, please wait.
            </Text>
            <Box margin={{bottom: 2}} justify="center">
              <Loader size={50}></Loader>
            </Box>
          </Box>
        )}
        {verificationStep === VerificationStep.CONFIRM_SENDING_SMS && (
          <Box>
            <Text size="large" margin={{top: 2}}>
              We will send you a one-time code via SMS message to {phoneNumber}.
            </Text>

            <Box justify="center">
              <Button onClick={handleSendSMS} margin={{top: 6}}>
                Send SMS
              </Button>
            </Box>
          </Box>
        )}
        {verificationStep === VerificationStep.SMS_RECEIVED && (
          <SmsVerificationForm
            remainingMinutes={REMAINING_MINUTES}
            phoneNumber={user.phoneNumber}
            handleSubmit={handleConfirmVerification}
            handleCancel={handleCancel}
            resendCode={handleSendSMS}
            onChange={() => setErrorMsg(null)}
            errorMessage={errorMsg}
          ></SmsVerificationForm>
        )}

        {verificationStep === VerificationStep.SEND_SMS_FAILED && (
          <Box>
            <GreenEnergy />
            <Text size="xlarge">Failed to send SMS to {phoneNumber}.</Text>
            <Button onClick={handleSendSMS}>Try again</Button>
          </Box>
        )}

        {verificationStep === VerificationStep.VERIFICATION_FAILED && (
          <Box>
            <GreenEnergy />
            <Text>Verification Failed</Text>
          </Box>
        )}

        {verificationStep === VerificationStep.ATTEMPTS_LIMIT_EXCEEDED && (
          <Box justify="center" direction="column">
            <GreenEnergy />
            <Text size="xxlarge" align="center">
              Verification attempts limit exceeded
            </Text>
            <Text>You&apos;ve made too many verification attempts. Please try again later or contact an admin.</Text>
          </Box>
        )}

        {verificationStep === VerificationStep.PHONE_ALREADY_VERIFIED && (
          <Box>
            <Text>Your phone number is already verified.</Text>
          </Box>
        )}
      </Box>
    </Modal>
  )
}

export default PhoneNumberVerificationModal
