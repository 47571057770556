import {atom, Atom, getDefaultStore, Getter, PrimitiveAtom, WritableAtom} from 'jotai'
import {loadable as jotaiLoadable, unwrap, RESET} from 'jotai/utils'
import {atomWithReducer} from 'jotai/utils'
import {partyAtom, transitionAtom} from './party'
import {isEqual} from 'lodash-es'
import V2ApiError from 'exceptions/V2ApiError'

const defaultStore = getDefaultStore()

function isWritableAtom(object: any): object is WritableAtom<any, [], null> {
  return 'write' in object
}

enum AccessLevel {
  PRO,
  ENTERPRISE,
}

interface IOptions {
  bypassParty?: boolean
  nullIsLoading?: boolean
  acessLevel?: AccessLevel
  invalidateOn?: Atom<any>
}

export const loadable = <Value>(anAtom: Atom<Value> | WritableAtom<Value, any, null>, options: IOptions = {}) => {
  return atom(
    get => {
      const party = get(partyAtom)
      const isTransition = get(transitionAtom)

      if (!party && !options.bypassParty) {
        return {loading: true, data: null}
      }

      if (isTransition) {
        return {loading: true, data: null}
      }

      const result = get(jotaiLoadable(anAtom))

      if (options.nullIsLoading && result.state === 'hasData' && result.data === null) {
        return {loading: true, data: null}
      }

      if (result.state === 'hasData') {
        return {loading: false, data: result.data}
      }

      if (result.state === 'hasError') {
        return {loading: false, error: result.error as V2ApiError}
      }

      return {loading: true, data: null}
    },
    (_get, set) => {
      if (isWritableAtom(anAtom)) {
        set(anAtom)
      }
    },
  )
}

export const atomWithCompare = <Value>(initialValue: Value) => {
  return atomWithReducer(initialValue, (prev: Value, next: Value) => {
    if (isEqual(prev, next)) {
      return prev
    }

    return next
  })
}

export const atomWithSwr = <T>(baseAtom: Atom<T>, options: IOptions = {}) => {
  const unwrappedAtom = unwrap(
    atom(async get => {
      try {
        const data = await get(baseAtom)
        const cache = options.invalidateOn ? get(options.invalidateOn) : null

        return {
          value: data,
          loading: data || !options.nullIsLoading ? false : true,
          cache: cache, // whenever cacheKey changes, it means the prev data is not valid anymore and we should just load new data
        }
      } catch (e) {
        return {value: null, loading: false, error: e as V2ApiError}
      }
    }),
    prev => {
      if (!prev) {
        // There is no prev only on initial load
        return {value: null, loading: true}
      }

      // If there is was error, we should just load new data and not give prev error
      if (prev.error) {
        return {value: null, loading: true}
      }

      return {value: prev?.value, loading: true, cache: prev?.cache, error: prev?.error} // whenever there is prev, it means it is currently trying to load new data
    },
  )

  return atom(get => {
    const party = get(partyAtom)
    const isTransition = get(transitionAtom)

    if (isTransition) {
      return {loading: true, data: null}
    }

    if (!party && !options.bypassParty) {
      return {loading: true, data: null}
    }

    const cache = options.invalidateOn ? get(options.invalidateOn) : null

    const baseValue = get(unwrappedAtom)

    if (options.invalidateOn && baseValue?.cache && cache !== baseValue?.cache) {
      return {loading: true, data: null}
    }

    return {loading: baseValue?.loading, data: baseValue?.value, error: baseValue?.error}
  })
}

export const atomWithCleanup = <Value>(value: Value, valueAsDefault?: boolean) => {
  const defaultAtom = atom(value) as PrimitiveAtom<Value>

  defaultAtom.onMount = setAtom => {
    return () => {
      setAtom(valueAsDefault ? value : null)
    }
  }

  return defaultAtom
}
