import {offerAtom, offerIdAtom, resetDesignerState} from 'atoms/certificateDesigner'
import {rootServiceAtom} from 'atoms/general'
import {partyAtom} from 'atoms/party'
import Box from 'components/Box'
import Button from 'components/Button'
import InnerScroll from 'components/InnerScroll'
import Page from 'components/Page'
import ActionBox from 'components/Platform/ActionBox'
import ProposalDetailsChart from 'components/Platform/Buy/CertificateDesigner/ProposalDetailsChart'
import ProposalDetailsInfo from 'components/Platform/Buy/CertificateDesigner/ProposalDetailsInfo'
import Text from 'components/Text'
import {OfferState} from 'domain/IOffer'
import useLocalization from 'hooks/useLocalization'
import {useAtomValue, useSetAtom} from 'jotai'
import React, {useEffect, useState} from 'react'
import OfferProduct from './OfferDesignerCertificate/OfferProduct'
import OfferProposalDetails from './OfferDesignerCertificate/OfferProposalDetails'
import useAlerts from 'hooks/useAlerts'
import {sellerProposalsAtom} from 'atoms/proposals'
import ErrorInfo from 'components/ErrorInfo'

interface IProps extends React.PropsWithChildren {
  id: string
}

const OfferDesignerCertificatePage: React.FC<IProps> = ({id}) => {
  const {translate} = useLocalization()
  const {addError, addSuccess} = useAlerts()
  const [actionPerformed, setActionPerformed] = useState(false)
  const setOfferId = useSetAtom(offerIdAtom)
  const {data: certOffer, loading, error} = useAtomValue(offerAtom)
  const {totalPrice, averagePrice, greenCoverage, timeseries, product, currency, matchedQuantitySnap} = certOffer || {}
  const showActions = certOffer?.state === OfferState.CONFIG_SENT_FOR_SELLER_REVIEW && !actionPerformed
  const party = useAtomValue(partyAtom)
  const refreshSellerProposals = useSetAtom(sellerProposalsAtom)
  const {v2ContentService} = useAtomValue(rootServiceAtom)
  const resetState = useSetAtom(resetDesignerState)
  const invalidOffer = error?.error?.kind === 'INVALID_SNAP_QUANTITIES_FOR_OFFER' // Temporary

  useEffect(() => {
    if (id) {
      setOfferId(id)
    }
  }, [id])

  useEffect(() => {
    return () => resetState()
  }, [])

  const handleApprove = async () => {
    try {
      await v2ContentService.approveOffer(party?.id, certOffer?.id)

      addSuccess(translate('Successfully approved'))
      setActionPerformed(true)
      refreshSellerProposals()
    } catch (e) {
      addError(translate('Failed to approve %s', ''), e?.correlationId, e?.message)
    }
  }

  const handleDecline = async () => {
    try {
      await v2ContentService.declineOffer(party?.id, certOffer?.id)

      addSuccess(translate('Successfully declined'))
      setActionPerformed(true)
      refreshSellerProposals()
    } catch (e) {
      addError(translate('Failed to approve %s', ''), e?.correlationId, e?.message)
    }
  }

  if (error) {
    return (
      <Page
        title={translate('Certificate purchase request')}
        description={translate('Review purchase request')}
        error={
          <ErrorInfo
            error={error}
            title={invalidOffer ? translate('This offer is no longer valid') : translate('Failed to load offer')}
          />
        }
      />
    )
  }

  return (
    <Page
      title={translate('Certificate purchase request')}
      description={translate('Review purchase request and add discounts')}
      noDivider
      corner={
        <>
          {showActions && (
            <Box gap={2}>
              <Button onClick={handleDecline} variant="secondary">
                {translate('Decline')}
              </Button>
              <Button onClick={handleApprove} type="button">
                {translate('Approve')}
              </Button>
            </Box>
          )}
          {certOffer?.state === OfferState.CONFIG_IN_PROGRESS && (
            <Box height={'100%'} align="center">
              <Text bold>
                {translate('Once this request is sent for review, you will be able to approve/decline it')}
              </Text>
            </Box>
          )}
          {certOffer?.state === OfferState.CONTRACT_SENT && (
            <Box height={'100%'} align="center">
              <Text bold>
                {translate('Offer is currently in review by the buyer. You will be notified once they sign or decline')}
              </Text>
            </Box>
          )}
        </>
      }
      isLoading={loading}
    >
      <InnerScroll>
        <Box direction="column" gap={2}>
          <Box direction="row" gap={2}>
            <Box width={60} round border>
              <ActionBox title={translate('Offer summary')} fullWidth noBorder>
                <OfferProposalDetails offer={certOffer || null} />
              </ActionBox>
            </Box>

            <Box width="100%" round border>
              <ActionBox title={translate('Offer details')} fullWidth noBorder>
                <Box direction="column" gap={3}>
                  <ProposalDetailsInfo
                    noGreenCoverage
                    totalPrice={+totalPrice}
                    averagePrice={+averagePrice}
                    currency={currency}
                    draft
                  />
                  <ProposalDetailsChart
                    technology={product?.technology}
                    title={product?.name}
                    volumes={timeseries?.product?.timeseries}
                  />
                </Box>
              </ActionBox>
            </Box>
          </Box>
          <Box>
            <OfferProduct
              key={product?.name}
              product={product}
              currency={currency}
              quantity={matchedQuantitySnap}
              averagePrice={averagePrice}
            />
          </Box>
        </Box>
      </InnerScroll>
    </Page>
  )
}

export default OfferDesignerCertificatePage
