import {atom} from 'jotai'
import {atomWithCompare, loadable} from './utils'
import {periodAtom, PeriodType} from 'components/PeriodSelect/periodSelect'
import {partyAtom} from './party'
import {intervalAtom, rootServiceAtom} from './general'
import {getMonthNumber, getPrevMonth, getPrevMonthYear, lastDayOfMonth} from 'helpers/date'
import {atomWithRefresh} from 'jotai/utils'
import {ISubscriptionPlan} from 'domain/IParty'
import {isEqual} from 'lodash-es'

export const overviewDateFiltermAtom = atom(
  {
    month: getPrevMonth(),
    year: getPrevMonthYear(),
  },
  (get, set, update: {month: string; year: number}) => {
    const prevValue = get(overviewDateFiltermAtom)
    if (isEqual(prevValue, update)) {
      return
    }

    set(overviewDateFiltermAtom, update)
    set(periodAtom, {period: PeriodType.MONTH, value: update?.month})
  },
)

overviewDateFiltermAtom.onMount = setAtom => {
  return () => {
    setAtom({
      month: getPrevMonth(),
      year: getPrevMonthYear(),
    })
  }
}

export const resetOverviewDateFilterAtom = atom(null, (get, set) => {
  set(overviewDateFiltermAtom, {
    month: getPrevMonth(),
    year: getPrevMonthYear(),
  })
})

export const overviewDateFilter = atomWithCompare({
  startMonth: getPrevMonth(),
  startYear: getPrevMonthYear(),
  endMonth: getPrevMonth(),
  endYear: getPrevMonthYear(),
})

export const greenCertificatesAtom = loadable(
  atom(async get => {
    const services = get(rootServiceAtom)
    const party = get(partyAtom)
    const dateFilter = get(overviewDateFiltermAtom)
    const interval = get(intervalAtom)

    const data = await services.v2ContentService.getGreenCertificatesData(
      party?.id,
      dateFilter.year,
      dateFilter.month,
      interval,
    )

    return data
  }),
)

export const contractedVolumesAtom = loadable(
  atom(async get => {
    const services = get(rootServiceAtom)
    const interval = get(intervalAtom)
    const party = get(partyAtom)
    const dateFilter = get(overviewDateFiltermAtom)

    const data = await services.v2ContentService.getPorfolioContractedVolumes(
      party?.id,
      interval,
      dateFilter.year,
      dateFilter.month,
    )

    return data
  }),
)

export const coverageOverviewAtom = loadable(
  atom(async get => {
    const services = get(rootServiceAtom)
    const interval = get(intervalAtom)
    const party = get(partyAtom)

    const data = await services.v2ContentService.getCoverageOverview(party?.id, interval)

    return data
  }),
)

export const performanceAtom = loadable(
  atom(async get => {
    const services = get(rootServiceAtom)
    const interval = get(intervalAtom)
    const party = get(partyAtom)
    const filter = get(overviewDateFilter)
    const startDate = `${filter.startYear}-${getMonthNumber(filter.startMonth) + 1}-01`
    const endDate = `${filter.endYear}-${getMonthNumber(filter.endMonth) + 1}-${lastDayOfMonth(
      getMonthNumber(filter.endMonth) + 1,
      filter.endYear,
    )}`

    const data = await services.v2ContentService.getFinancialPerformance(party?.id, startDate, endDate, interval)

    return data
  }),
)

export const kpiAtom = loadable(
  atomWithRefresh(async get => {
    const services = get(rootServiceAtom)
    const interval = get(overviewDateFiltermAtom)
    const party = get(partyAtom)

    const data =
      party?.subscriptionPlan === ISubscriptionPlan.BASIC
        ? await services.v2ContentService.getBuyerPartialKPIs(party?.id, interval.month, interval.year)
        : await services.v2ContentService.getBuyerKPIs(party?.id, interval.month, interval.year)

    return data
  }),
)

export const kpiTargetsAtom = loadable(
  atomWithRefresh(async get => {
    const services = get(rootServiceAtom)
    const party = get(partyAtom)

    const data = await services.v2ContentService.getKPITargets(party?.id)

    return data
  }),
)
