import React from 'react'
import {buildStyles, CircularProgressbarWithChildren} from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import Box from './Box'
import useTheme from '../hooks/useTheme'
import ITheme from 'theme/ITheme'

interface IProps extends React.PropsWithChildren {
  value: number
  width?: number
  height?: number
  color?: string
  trailColor?: string
  circleRatio?: number
  noAnimation?: boolean
}

export const getColorByValue = (value: number, theme: ITheme) => {
  if (!value && value !== 0) {
    return theme.colors.light1
  }

  if (value < 15) {
    return theme.colors.error
  } else if (value >= 75) {
    return theme.colors.limeGreen
  }

  return theme.colors.yellow3
}

const CircularProgress: React.FC<IProps> = ({
  value,
  width = 8,
  height = 8,
  color,
  trailColor,
  circleRatio = 1,
  noAnimation,
  children,
}) => {
  const theme = useTheme()

  return (
    <Box width={width} height={height} style={{minWidth: width * 8}}>
      <CircularProgressbarWithChildren
        value={value}
        styles={buildStyles({
          strokeLinecap: 'butt',
          pathColor: color || theme.colors.yellow3,
          trailColor: trailColor || theme.colors.light4,
          pathTransition: noAnimation ? 'none' : undefined,
          pathTransitionDuration: 1.5,
        })}
        strokeWidth={10}
        circleRatio={circleRatio}
      >
        {children}
      </CircularProgressbarWithChildren>
    </Box>
  )
}

export default CircularProgress
