import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Button from './Button'
import Text from 'components/Text'
import useTheme from 'hooks/useTheme'
import Box from 'components/Box'
import {useAtomValue} from 'jotai'
import {userAtom} from 'atoms/party'
import {isAdmin} from 'helpers/user'

interface IProps {
  setOpen: (open: boolean) => void
  open: boolean
  title: string
  content?: React.ReactNode
  text?: string
  acceptText: string
  declineText: string
  onAccept: () => void
  adminText?: string
  onAdminClick?: () => void
}

const ConfirmationDialog: React.FC<IProps> = ({
  setOpen,
  open,
  title,
  text,
  acceptText,
  declineText,
  onAccept,
  content,
  adminText,
  onAdminClick,
}) => {
  const theme = useTheme()
  const user = useAtomValue(userAtom)
  const showAdminButton = isAdmin(user) && adminText

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <React.Fragment>
      <Dialog PaperProps={{elevation: 6, sx: {borderRadius: 2}}} open={open} onClose={handleClose}>
        <DialogTitle>
          <Text size="mlarge" font={theme.font.headings}>
            {title}
          </Text>
        </DialogTitle>
        <DialogContent>
          {text && (
            <Text size="medium" font={theme.font.secondaryFont} color={theme.colors.accent}>
              {text}
            </Text>
          )}
          {content}
        </DialogContent>
        <DialogActions sx={{p: 2, justifyContent: showAdminButton ? 'space-between' : 'flex-end'}}>
          {showAdminButton && (
            <Button variant="outlined" onClick={onAdminClick} data-cy="modal-admin">
              {adminText}
            </Button>
          )}
          <Box direction="row" gap={1}>
            <Button variant="secondary" onClick={handleClose} data-cy="modal-decline">
              {declineText}
            </Button>
            <Button data-cy="modal-accept" onClick={onAccept}>
              {acceptText}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default ConfirmationDialog
