import React, {useRef, useCallback, useEffect, useState} from 'react'
import Box from 'components/Box'
import Text from 'components/Text'
import Label from 'components/Label'
import useLocalization from 'hooks/useLocalization'
import {getChangeEventValue} from 'helpers/misc'
import Range from 'components/Range'
import {useAtom, useSetAtom} from 'jotai'
import ValueWithUnit from 'components/ValueWithUnit'
import {matchingModeAtom, quantityAtom} from 'atoms/certificateDesigner'
import {FormControlLabel, RadioGroup, Skeleton, Tooltip} from '@mui/material'
import HelpIcon from '@mui/icons-material/HelpOutlineOutlined'
import theme from 'theme'
import {debounce} from 'lodash-es'
import {formatDecimal, formatNumber} from 'helpers/format'
import Radio from 'components/Radio'
import {MatchStatus, RoundingType} from 'domain/IProposalDetails'
import {transitionAtom} from 'atoms/party'

export const ALL_TECHNOLOGIES_SLUG = 'ALL'

export interface IFilter {
  dayOfMonth?: number
  quantity: number
}

interface IGeneralFilter {
  editable: boolean
  maxQuantity: number
  matchedQuantity: number
  skippedQuantity: number
  fullCoverExcessMwh: number
  newShortfallQuantity: number
  loading?: boolean
  initialLoading: boolean
  status: MatchStatus
  shortfall: number
  month: string
  year: number
}

export const EMPTY_WEEKDAY = 'None'
export const MIN_CONSUMPTION = 50

const NewGeneralFilter: React.FC<IGeneralFilter> = ({
  editable,
  maxQuantity,
  status,
  loading,
  matchedQuantity,
  initialLoading,
  shortfall,
  month,
  year,
  skippedQuantity,
  fullCoverExcessMwh,
  newShortfallQuantity,
}) => {
  const {translate} = useLocalization()
  const [dirty, setDirty] = useState(false)
  const [apiQuantity, setApiQuantity] = useAtom(quantityAtom)
  const setTransition = useSetAtom(transitionAtom)
  const [matchingMode, setMatchingMode] = useAtom(matchingModeAtom)
  const rangeRef = useRef<HTMLInputElement>(null)
  const [quantity, setQuantity] = React.useState<number>()
  const roundDown = matchingMode === RoundingType.DOWN

  const handleQuantityChange = value => {
    setDirty(true)
    const formattedValue = +getChangeEventValue(value)

    setQuantity(formattedValue)
    debounceFn(formattedValue)
  }

  const handleDebounceFn = quantity => {
    setApiQuantity(quantity)
    setDirty(false)
  }
  const debounceFn = useCallback(debounce(handleDebounceFn, 300), [])

  const onMatchingModeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTransition(true)
    setApiQuantity(null)
    setMatchingMode(getChangeEventValue(e))
    setQuantity(null)
    setTransition(false)
  }

  useEffect(() => {
    if (!quantity && !loading) {
      setQuantity(apiQuantity || maxQuantity)
    }
  }, [loading])

  if (initialLoading) {
    return (
      <Box gap={4} direction="column">
        {Array.from({length: 4}).map((_, index) => (
          <Skeleton variant="rounded" height={40} key={index} />
        ))}
      </Box>
    )
  }

  return (
    <>
      <Box pad={{bottom: 2}}>
        <Label text={translate('Shortfall for month')}>
          <ValueWithUnit size="mlarge" value={formatDecimal(shortfall, 0)} unit={'CERTIFICATES'} />
        </Label>
      </Box>
      <Label text={translate('Purchase interval')}>
        <Box direction="row" gap={1} pad={{bottom: 2}}>
          <ValueWithUnit value={month} size="mlarge" />
          <ValueWithUnit value={year} size="mlarge" />
        </Box>
      </Label>

      <Label
        text={
          <Box direction="row" gap={1}>
            <Text
              size="small"
              color={status === MatchStatus.RoundDownMaxAllowedTooLow ? theme.colors.error : undefined}
              bold={status === MatchStatus.RoundDownMaxAllowedTooLow}
            >
              {translate('Matching mode')}
            </Text>
            <Tooltip
              title={translate(
                'Conservative rounds your consumption down, ignoring the fractional part. For example if your consumption is 3.2 mWh, 3 certificates will be matched, and the remaining 0.2 mWh will not be covered. If you want to cover the last 0.2 mWh, choose Full cover, which will match 4 certificates.',
              )}
            >
              <HelpIcon
                sx={{
                  fontSize: '16px',
                  color: status === MatchStatus.RoundDownMaxAllowedTooLow ? theme.colors.error : undefined,
                }}
              />
            </Tooltip>
          </Box>
        }
      >
        <RadioGroup row value={matchingMode} onChange={onMatchingModeChange}>
          <FormControlLabel
            value={RoundingType.DOWN}
            control={<Radio />}
            label={translate('Efficient')}
            disabled={!editable}
          />
          <FormControlLabel
            value={RoundingType.UP}
            control={<Radio />}
            label={translate('Full Coverage')}
            disabled={!editable}
          />
        </RadioGroup>
      </Label>
      <Label
        text={translate('Change volume')}
        bold={status === MatchStatus.MatchedQuantityTooLow}
        color={status === MatchStatus.MatchedQuantityTooLow ? theme.colors.error : undefined}
      >
        <Range
          disabled={!editable}
          ref={rangeRef}
          min={50}
          value={quantity || maxQuantity}
          max={formatNumber(maxQuantity, 0)}
          step={1}
          onChange={quantity => handleQuantityChange(quantity)}
        />
      </Label>
      <Box justify="space-between" margin={{top: 1, bottom: 2}} align="end">
        <Text size="small" uppercase>
          {translate('Min')} <strong>{`${MIN_CONSUMPTION} certificates`}</strong>
        </Text>
        <Box direction="row" align="end" gap={0.3}>
          <Text size="small" uppercase>
            {translate('Max')} <strong>{`${maxQuantity?.toFixed(0)} certificates`}</strong>
          </Text>
        </Box>
      </Box>
      <Box margin={{top: 1.5}} gap={1} direction="column">
        <Label text={'Volume'}>
          <ValueWithUnit size="mlarge" value={quantity ? formatDecimal(quantity, 0) : null} unit={'CERTIFICATES'} />
        </Label>
        <Label text={'Matched volume'}>
          {dirty || loading ? (
            <Skeleton variant="rounded" height={24} />
          ) : (
            <ValueWithUnit size="mlarge" value={formatDecimal(matchedQuantity, 0)} unit={'CERTIFICATES'} />
          )}
        </Label>
        <Label text={'New shortfall'}>
          {dirty || loading ? (
            <Skeleton variant="rounded" height={24} />
          ) : (
            <ValueWithUnit size="mlarge" value={formatDecimal(newShortfallQuantity, 0)} unit={'CERTIFICATES'} />
          )}
        </Label>
        <Label text={`${roundDown ? 'Skipped' : 'Full cover excess'} volume`}>
          {dirty || loading ? (
            <Skeleton variant="rounded" height={24} />
          ) : (
            <ValueWithUnit
              size="mlarge"
              value={roundDown ? skippedQuantity : fullCoverExcessMwh}
              unit={'CERTIFICATES'}
            />
          )}
        </Label>
      </Box>
    </>
  )
}

export default NewGeneralFilter
