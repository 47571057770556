import {TextDocumentType} from 'domain/IDocumentType'
import IMatchingScore from 'domain/IMatchingScore'
import {ISignedContractFile} from 'domain/ISignedContractFile'
import ISitesDashboard from 'domain/ISitesDashboard'
import ITextDocument from 'domain/ITextDocument'
import {IContractedVolume, ICoverageOverview} from 'domain/Portfolio'
import IAttachment from 'domain/IAttachment'
import IOfferTextGroup from 'domain/IOfferTextGroup'
import IParty from 'domain/IParty'
import IPartyMember from 'domain/IPartyMember'
import {IPasswordFromInvite} from 'domain/IPasswordFromInvite'
import IProduct from 'domain/IProduct'
import ISite from 'domain/ISite'
import ITimeseriesItem from 'domain/ITimeseriesItem'
import IContentService, {Interval} from './IContentService'
import LocalStorageDataService from './LocalStorageDataService'
import LocalStorageRelationalDataService from './LocalStorageRelationalDataService'
import {IUserProfile} from 'domain/IUserProfile'
import {INewOffer} from 'domain/IOffer'

export default class LocalContentService implements IContentService {
  private readonly parties: LocalStorageDataService<IParty>
  private readonly timeseries: LocalStorageDataService<ITimeseriesItem>

  public constructor() {
    const attachments = new LocalStorageRelationalDataService<IAttachment>('attachments')
    const products = new LocalStorageRelationalDataService<IProduct>('products')
    const sites = new LocalStorageRelationalDataService<ISite>('sites')
    const parties = new LocalStorageRelationalDataService<IParty>('parties')
    const timeseries = new LocalStorageRelationalDataService<ITimeseriesItem>('timeseries')

    products.addManyToOneRelation<IAttachment>(attachments, 'image')
    products.addManyToOneRelation<ISite>(sites, 'site', 'products')

    this.parties = parties
    this.timeseries = timeseries
  }
  getPorfolioContractedVolumes(partyId: any, interval: Interval): Promise<IContractedVolume> {
    throw new Error('Method not implemented.')
  }

  getCoverageOverview(partyId: any, interval: Interval): Promise<ICoverageOverview> {
    throw new Error('Method not implemented.')
  }

  getPortfolioSitesDashboard(partyId: any, interval: Interval): Promise<ISitesDashboard> {
    throw new Error('Method not implemented.')
  }

  sendContractVerificationCode(contractId: number) {
    throw new Error('Method not implemented.')
  }

  updateProfile(updatedUser: any) {
    throw new Error('Method not implemented.')
  }

  public async getConsumptionData(partyId: any, interval: Interval): Promise<ITimeseriesItem[]> {
    return this.timeseries.getItemsWithMeta<any>(meta => meta.partyId === partyId && meta.interval === interval)
  }

  public async getContractHtml(contractId: any): Promise<string> {
    return `<h1>contract ${contractId}</h1>`
  }

  public async getContractContractTemplatePdf(contractId: any, templateId: any, partyId: any): Promise<Blob> {
    throw new Error('Not implemented')
  }

  public async getContractContractTemplates(contractId: any, partyId: any): Promise<any> {
    return []
  }

  public async getPortfolioMatchingScores(partyId: any): Promise<IMatchingScore> {
    throw new Error('Method not implemented.')
  }

  public async confirmUserPhoneNumber(pin: string): Promise<void> {
    //TODO
  }

  public async confirmContractVerificationCode(contractId: number, code: string): Promise<void> {
    //TODO
  }

  public async sendUserPhoneVerification(phone: string): Promise<void> {
    //TODO
  }

  public async getManagedParty(partyId: any): Promise<IParty> {
    return this.parties.getItem(partyId)
  }

  public async getPartyMembers(partyId: any): Promise<IPartyMember[]> {
    // TODO
    throw new Error('Not implemented')
  }

  public async getUserFromInvite(partyId: any): Promise<IUserProfile> {
    throw new Error('Not implemented')
  }

  public async resendUserInvite(asPartyId: number, partyMemberId: number): Promise<void> {
    // TODO
    throw new Error('Not implemented')
  }

  public async setPasswordFromInvite(accessCode: string, password: IPasswordFromInvite): Promise<void> {
    // TODO
    throw new Error('Not implemented')
  }

  public async getOfferTextGroups(partyId: any, offerId: any): Promise<IOfferTextGroup[]> {
    return []
  }

  public async getTextDocument(documentType: TextDocumentType, locale: string): Promise<ITextDocument> {
    return {content: `DOCUMENT FOR ${documentType} with locale ${locale}`} as ITextDocument
  }
  public getAttachmentURL(attachment: IAttachment): string {
    return attachment ? attachment.fileName : ''
  }

  public getSignedContractFileURL(file: ISignedContractFile): string {
    return file ? file.fileName : ''
  }
}
