import React from 'react'
import Page from 'components/Page'
import useLocalization from 'hooks/useLocalization'

interface IProps extends React.PropsWithChildren {
  corner?: any
  description?: any
  loading?: boolean
  descriptionAside?: boolean
  error?: React.ReactNode
}

const PageBase: React.FC<IProps> = ({description, corner, loading, children, descriptionAside, error}) => {
  const {translate} = useLocalization()

  return (
    <Page
      title={translate('Certificate procurement designer')}
      error={error}
      description={description || translate('Review certificate mix, key terms and confirm the solution')}
      descriptionAside={descriptionAside}
      gridTemplateRowStyle="min-content min-content auto"
      corner={corner}
      isLoading={loading}
      noDivider
    >
      {children}
    </Page>
  )
}

export default PageBase
