import React from 'react'
import styled from 'styled-components'
import ITheme from '../theme/ITheme'
import closeSvg from '../assets/icons/close.svg'

const Button = styled.button`
  padding: ${props => (props.theme as ITheme).tileSize * 0.5}px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  border-radius: 50%;
  border: 0;
  background: transparent;

  &:hover {
    background-color: ${props => (props.theme as ITheme).colors.light3};
  }
`
type IProps = React.ButtonHTMLAttributes<HTMLButtonElement>

const CloseButton: React.FC<IProps> = props => (
  <Button data-c="CloseButton" {...props} type="button">
    <img src={closeSvg} alt="x" />
  </Button>
)

export default CloseButton
