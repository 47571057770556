import {consumerJoyrideStartAtom, consumerJoyrideStepAtom} from 'atoms/consumerJoyride'
import {rootServiceAtom} from 'atoms/general'
import {partyAtom, userAtom} from 'atoms/party'
import {getPlatformUrl} from 'helpers/party'
import useAlerts from 'hooks/useAlerts'
import usePartyActions from 'hooks/usePartyActions'
import useTheme from 'hooks/useTheme'
import {useAtom, useAtomValue} from 'jotai'
import React, {useEffect, useState} from 'react'
import Joyride, {CallBackProps, EVENTS, Placement, STATUS} from 'react-joyride'
import {useHistory} from 'react-router'

const ConsumerJoyride: React.FC<React.PropsWithChildren> = () => {
  const party = useAtomValue(partyAtom)
  const {refreshParties} = usePartyActions()
  const rootStore = useAtomValue(rootServiceAtom)
  const {v2ContentService} = rootStore
  const user = useAtomValue(userAtom)
  const {addError} = useAlerts()
  const theme = useTheme()
  const history = useHistory()
  const [consumerJoyrideStart, setConsumerJoyrideStart] = useAtom(consumerJoyrideStartAtom)
  const [consumerJoyrideStep, setConsumerJoyrideStep] = useAtom(consumerJoyrideStepAtom)

  const steps = [
    {
      title: 'Welcome',
      target: `a[href="${getPlatformUrl(party)}/portfolio/external-contracts"]`,
      content: `Let's start with setting your Energy supply contract or PPA`,
      disableBeacon: true,
      spotlightPadding: 10,
      data: {
        next: `/consumer/${party?.id}/portfolio/overview`,
        previous: `/consumer/${party?.id}/portfolio/overview`,
      },
    },
    {
      target: '[data-cy="kpi-goals"]',
      content: `Once your External agreement is set, lets set your renewable energy targets for this year`,
      disableBeacon: true,
      spotlightPadding: 10,
      placement: 'left',
      data: {
        next: `/consumer/${party?.id}/portfolio/market-intelligence`,
        previous: `/consumer/${party?.id}/portfolio/overview`,
      },
    },
    {
      target: `a[href="${getPlatformUrl(party)}/portfolio/market-intelligence"]`,
      content: 'Explore Energy market data to optimise your purchase decisions',
      disableBeacon: true,
      spotlightPadding: 10,
      placement: 'left',
      data: {
        next: `/consumer/${party?.id}/portfolio/market-intelligence?tab.overview=green-tariff`,
        previous: `/consumer/${party?.id}/portfolio/overview`,
      },
    },
    {
      target: `a[href="${getPlatformUrl(party)}/portfolio/market-intelligence?tab.overview=green-tariff"]`,
      content: 'Explore The cost of Sport market energy prices in combination with Hourly GO’Procurement',
      disableBeacon: true,
      spotlightPadding: 10,
      placement: 'left',
      data: {
        next: `/consumer/${party?.id}/portfolio/sites`,
        previous: `/consumer/${party?.id}/portfolio/market-intelligence`,
      },
    },
    {
      target: `a[href="${getPlatformUrl(party)}/portfolio/sites"]`,
      content: 'Dive in to energy consumption meter by meter',
      disableBeacon: true,
      spotlightPadding: 10,
      placement: 'left',
      data: {
        next: `/consumer/${party?.id}/portfolio/sites`,
        previous: `/consumer/${party?.id}/portfolio/market-intelligence?tab.overview=green-tariff`,
      },
    },
    {
      target: `[data-cy="select-timescale"]`,
      content: 'You can look up different timescales',
      disableBeacon: true,
      spotlightPadding: 10,
      placement: 'left',
      data: {
        next: `/consumer/${party?.id}/portfolio/sites`,
        previous: `/consumer/${party?.id}/portfolio/sites`,
      },
    },
    {
      target: `[data-cy="period-select"]`,
      content: 'And different time periods and views that suit your analysis goals',
      disableBeacon: true,
      spotlightPadding: 10,
      placement: 'left',
      data: {
        next: `/consumer/${party?.id}/portfolio/overview`,
        previous: `/consumer/${party?.id}/portfolio/sites`,
      },
    },
    {
      target: `a[href="${getPlatformUrl(party)}/buy/marketplace/request"]`,
      content: `Congratulations, your initial set up done, now lets achieve your goals together`,
      disableBeacon: true,
      spotlightPadding: 10,
      placement: 'left',
      data: {
        next: `/consumer/${party?.id}/portfolio/overview`,
        previous: `/consumer/${party?.id}/portfolio/sites`,
      },
    },
  ]

  const handleJoyrideCallback = async (data: CallBackProps) => {
    const {status, action, index, type} = data
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED]

    const step = steps[index]
    const next = step?.data?.next
    const previous = step?.data?.previous
    const isPreviousAction = action === 'prev'

    if (type === EVENTS.STEP_AFTER) {
      setConsumerJoyrideStart(false)

      if (isPreviousAction && previous) {
        history.push(previous)
      } else if (next) {
        history.push(next)
      }

      setConsumerJoyrideStep(isPreviousAction ? index - 1 : index + 1)

      setTimeout(() => {
        setConsumerJoyrideStart(true)
      }, 300)
    }

    if (finishedStatuses.includes(status)) {
      try {
        await v2ContentService.completeBuyerGuide(user.id, user.email)
        await refreshParties()
      } catch (ex) {
        addError('Failed to complete onboarding', ex?.correlationId, ex?.message)
      } finally {
        setConsumerJoyrideStart(false)
      }
    }
  }

  if (user.buyerGuideCompletedOn) {
    return null
  }

  return (
    <Joyride
      steps={steps.map(step => ({
        ...step,
        placement: (step.placement as Placement) || 'auto',
      }))}
      run={consumerJoyrideStart}
      stepIndex={consumerJoyrideStep}
      continuous
      showSkipButton
      locale={{
        last: 'Finish',
      }}
      styles={{
        options: {
          primaryColor: theme.colors.primary,
          zIndex: 10000,
        },
      }}
      callback={handleJoyrideCallback}
    />
  )
}

export default ConsumerJoyride
