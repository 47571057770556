import React, {useEffect, useState} from 'react'
import {Map, useMap} from '@vis.gl/react-google-maps'
import theme from 'theme'
import {isFiniteNumber, isInRange} from 'helpers/misc'

interface IMapOptions {
  streetViewControl?: boolean
  scaleControl?: boolean
  mapTypeControl?: boolean
  panControl?: boolean
  zoomControl?: boolean
  rotateControl?: boolean
  fullscreenControl?: boolean
}

const createMapOptions = (overrides: Partial<IMapOptions> = {}): IMapOptions => {
  return {
    streetViewControl: false,
    scaleControl: false,
    mapTypeControl: false,
    panControl: false,
    zoomControl: true,
    rotateControl: false,
    fullscreenControl: false,
    ...overrides,
  }
}

export interface IProps extends React.PropsWithChildren, IMapOptions {
  defaultCenter?: [number, number]
  defaultZoom?: number
  maxZoom?: number
  setLoaded?: (loaded: boolean) => void
  mapId?: string
}

const LATITUDE_MIN_VAL = -90
const LATITUDE_MAX_VAL = 90

const LONGTITUDE_MIN_VAL = -180
const LONGTITUDE_MAX_VAL = 180

export const GoogleMap: React.FC<IProps> = ({
  children,
  setLoaded,
  defaultCenter = theme.map.defaultCenter,
  defaultZoom = theme.map.defaultZoom,
  maxZoom = theme.map.maxZoom,
  mapId = 'e25d586484475ee2',
  ...options
}) => {
  const map = useMap()
  const [tilesLoaded, setTilesLoaded] = useState(false)
  const center = defaultCenter || theme.map.defaultCenter

  useEffect(() => {
    if (map) {
      const markerPositions: Array<{lat: number; lng: number}> = (
        React.Children.map(children, (child: any) => ({
          lat: child?.props?.lat,
          lng: child?.props?.lng,
        })) || []
      ).filter(
        ({lat, lng}) =>
          isFiniteNumber(lat) &&
          isFiniteNumber(lng) &&
          isInRange(lat, LATITUDE_MIN_VAL, LATITUDE_MAX_VAL) &&
          isInRange(lng, LONGTITUDE_MIN_VAL, LONGTITUDE_MAX_VAL),
      )

      const googleApi = window.google
      const bounds = new googleApi.maps.LatLngBounds()

      if (markerPositions.length) {
        markerPositions.forEach(position => bounds.extend(position))

        googleApi.maps.event.addListenerOnce(map, 'bounds_changed', () => {
          map.setZoom(Math.min(maxZoom, map.getZoom()))
        })

        map.fitBounds(bounds)
      }
    }
  }, [map, children, maxZoom])

  return (
    <Map
      defaultCenter={{lat: center?.[0], lng: center?.[1]}}
      defaultZoom={defaultZoom}
      mapId={mapId}
      {...createMapOptions(options)}
      onTilesLoaded={e => {
        setLoaded && setLoaded(true)
        setTilesLoaded(true)
      }}
    >
      {tilesLoaded ? children : null}
    </Map>
  )
}

export default GoogleMap
