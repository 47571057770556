import React, {useState} from 'react'
import Box from 'components/Box'
import {Tooltip, IconButton, Menu, MenuItem, ListItemIcon} from '@mui/material'
import PersonIcon from '@mui/icons-material/Person'
import logoutIcon from 'assets/icons/logout.svg'
import useProfile from 'hooks/useProfile'
import {getFullName} from 'helpers/user'
import CustomAvatar from 'components/Avatar'
import useLocalization from 'hooks/useLocalization'
import Link from 'components/Link'
import {getPlatformUrl} from 'helpers/party'

const UserMenu = () => {
  const {user, party} = useProfile()
  const {translate} = useLocalization()
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null)

  const handleOpenUserMenu = e => {
    setAnchorElUser(e.currentTarget)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  return (
    <Box>
      <Tooltip title="Open settings">
        <IconButton onClick={handleOpenUserMenu} data-cy="user-icon">
          <CustomAvatar colored name={user ? getFullName(user) : '-'} />
        </IconButton>
      </Tooltip>
      <Menu
        color="dark"
        sx={{mt: '45px'}}
        id="user-menu"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <Link to={`${getPlatformUrl(party)}/company/personal-profile`}>
          <MenuItem onClick={handleCloseUserMenu}>
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            {translate('Profile')}
          </MenuItem>
        </Link>
        <Link to="/logout" data-cy="logout">
          <MenuItem onClick={handleCloseUserMenu}>
            <ListItemIcon>
              <img src={logoutIcon} />
            </ListItemIcon>
            {translate('Logout')}
          </MenuItem>
        </Link>
      </Menu>
    </Box>
  )
}

export default UserMenu
