import React from 'react'
import Box from 'components/Box'
import Text from 'components/Text'
import ConnectedHoverable from 'components/ConnectedHoverable'
import CircleMarker from 'components/Map/CircleMarker'
import useTheme from 'hooks/useTheme'
import {AdvancedMarker} from '@vis.gl/react-google-maps'

interface IPriceCircleProps {
  lat?: number // Those are not used, but required by the Map component
  lng?: number // Those are not used, but required by the Map component
  price: string
  color: string
  name?: string
  unit?: string
}

const PriceCircle: React.FC<IPriceCircleProps> = ({price, color, name, unit, lat, lng}) => {
  const theme = useTheme()

  return (
    <ConnectedHoverable hoverKey={name}>
      <AdvancedMarker position={{lat, lng}}>
        <CircleMarker color={color}>
          <Box direction="column" align="center" justify="center" height="100%" gap={0.5}>
            <Text size="small" font={theme.font.numbers} style={{lineHeight: '100%'}} nowrap>
              {price}
            </Text>
            <Text style={{fontSize: 9, lineHeight: '100%'}} font={theme.font.secondaryFont} semibold>
              {unit}
            </Text>
          </Box>
        </CircleMarker>
      </AdvancedMarker>
    </ConnectedHoverable>
  )
}

export default PriceCircle
