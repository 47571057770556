import React, {useEffect, useMemo} from 'react'
import Table, {TableValue} from 'components/Table'
import Text from 'components/Text'
import Box from 'components/Box'
import useLocalization from 'hooks/useLocalization'
import useTheme from 'hooks/useTheme'
import {IAggrGreenTarifInterval, IAggrSpotPriceInterval} from 'domain/IMarketIntelligence'
import {getMonthName} from 'helpers/date'
import {getHumanReadableCountry} from 'helpers/location'
import Flag from 'react-world-flags'
import {getPriceUnitSymbol, getSlashEnergyUnit} from 'helpers/price'
import {useAtomValue, useSetAtom} from 'jotai'
import {aggrGreenTarifAtom, aggrSpotPricesAtom, mapDataAtom} from 'atoms/marketIntelligence'
import {Country} from 'domain/ILocation'
import {periodAtom, PeriodType} from 'components/PeriodSelect/periodSelect'
import Rain from 'components/Icons/Rain'
import {calculateAggrAvgPricesByCountry} from './GreenTarif'
import {Tooltip} from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

const AggrGreenTarifTable = ({showSpotPrices, showEacPrices}: {showSpotPrices: boolean; showEacPrices: boolean}) => {
  const {translate} = useLocalization()
  const theme = useTheme()
  const setMapData = useSetAtom(mapDataAtom)
  const period = useAtomValue(periodAtom)
  const {data, loading} = useAtomValue(aggrGreenTarifAtom)
  const {intervals, uiError} = data || {}
  const countriesWithPriceUnit =
    useMemo(
      () =>
        intervals?.[0].prices.reduce((acc, priceObj) => {
          acc[priceObj.country] = priceObj?.greenTariff?.priceUnit
          return acc
        }, {}),
      [intervals],
    ) || {}
  const allCountries = useMemo(() => {
    const availableCountries = Object.keys(countriesWithPriceUnit) || []
    const missingCountries = intervals?.failedCountries || []
    return [...availableCountries, ...missingCountries]
  }, [countriesWithPriceUnit, intervals?.failedCountries])
  const avgPricesByCountry = useMemo(() => calculateAggrAvgPricesByCountry(intervals || []), [intervals])
  const isMonthly = period.period === PeriodType.YEAR

  useEffect(() => {
    if (!loading) {
      setMapData(
        Object.entries(avgPricesByCountry).map(([country, price]) => ({
          price,
          country,
          priceUnit: countriesWithPriceUnit[country],
        })),
      )
    }
  }, [intervals, loading])

  if (uiError) {
    return (
      <Box align="center" direction="column">
        <Rain />
        <Text size="mlarge">{translate(uiError)}</Text>
      </Box>
    )
  }

  return (
    <Table<IAggrGreenTarifInterval>
      tableName={translate('Green Tarif')}
      highlightOnHover
      isLoading={loading}
      data={intervals}
      columns={[
        {
          title: translate(isMonthly ? 'Month' : 'Day of month'),
          accessor: interval => interval.interval,
          render: (date: number) => (
            <Text size="medium" nowrap font={theme.font.numbers}>
              {isMonthly ? getMonthName(date - 1) : date}
            </Text>
          ),
        },
        ...allCountries.map((country: Country) => ({
          sortable: false,
          title: (
            <Box direction="row" align="center" gap={1}>
              <Flag height={12} width={20} code={country} />
              <Text style={{lineHeight: '100%'}} size="msmall" font={theme.font.headings} margin={{top: 0.5}}>
                {getHumanReadableCountry(country, translate)}
              </Text>
              {intervals?.failedCountries?.includes(country) && (
                <Tooltip title={translate('No data available for selected timeperiod')}>
                  <div>
                    <Box align="center">
                      <InfoOutlinedIcon color="error" sx={{height: '16px', width: '16px'}} />
                    </Box>
                  </div>
                </Tooltip>
              )}
            </Box>
          ),
          accessor: interval => interval.prices.find(p => p.country === country),
          render: item => {
            return (
              <Box direction="column" gap={1} margin={{vertical: 1}} style={{minWidth: '120px'}}>
                <TableValue
                  bold={showEacPrices || showSpotPrices}
                  nowrap
                  prefix={item.greenTariff.price ? `${getPriceUnitSymbol(item.greenTariff.priceUnit)}` : ''}
                  value={item.greenTariff.price}
                  unit={item.greenTariff.priceUnit ? getSlashEnergyUnit(item.greenTariff.priceUnit) : ''}
                />
                {showSpotPrices && (
                  <TableValue
                    nowrap
                    prefix={item.spotPrice.price ? `${getPriceUnitSymbol(item.spotPrice.priceUnit)}` : ''}
                    value={item.spotPrice.price}
                    unit={`${item.spotPrice.priceUnit ? getSlashEnergyUnit(item.spotPrice.priceUnit) : ''} SPOT`}
                  />
                )}
                {showEacPrices && (
                  <TableValue
                    nowrap
                    prefix={item.certPrice.price ? `${getPriceUnitSymbol(item.certPrice.priceUnit)}` : ''}
                    value={item.certPrice.price}
                    unit={`EAC`}
                  />
                )}
              </Box>
            )
          },
        })),
      ]}
    />
  )
}

export default AggrGreenTarifTable
