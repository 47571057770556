import React, {useState} from 'react'
import Chart, {ChartType, getMaxValue, getMinValue, ILine, StackType} from 'components/Chart'
import {autoCeilNumber, autoFloorNumber} from 'helpers/misc'
import {IOfferedVolumesTimeseries} from 'domain/IProposalDetails'
import useTheme from 'hooks/useTheme'
import useNewChartColors from 'hooks/useNewChartColors'
import Box from 'components/Box'
import Checkbox from 'components/Checkbox'
import {Skeleton} from '@mui/material'

interface IProps extends React.PropsWithChildren {
  timeseries: IOfferedVolumesTimeseries
  technology?: string
  loading?: boolean
  productName: string
}

const CertDesignerChart: React.FC<IProps> = ({timeseries, technology, loading, productName}) => {
  const chartColors = useNewChartColors()
  const [showProductAvailable, setShowProductAvailable] = useState(false)
  const theme = useTheme()

  if (loading) {
    return (
      <Box direction="column" gap={3}>
        <Box direction="row" height="256px" gap={3}>
          <Box width="100%">
            <Skeleton height={256} variant="rectangular"></Skeleton>
          </Box>
        </Box>
        <Box width="100%" gap={1} pad={{bottom: 1.5}}>
          <Skeleton height={40} width={120} variant="rectangular"></Skeleton>
          <Skeleton height={40} width={160} variant="rectangular"></Skeleton>
        </Box>
      </Box>
    )
  }

  const labels = timeseries?.missing?.map(({name}) => name) || []
  const {purchase, missing, productAvailableNoOverlap} = timeseries || {}
  const productColor = technology && theme.colors.technology[technology]

  const lines: ILine[] = []
  const lineKeys = {
    missing: {
      data: missing?.map(({value}) => value),
      color: theme.colors.blue7,
      label: 'Shortfall',
    },
  }

  if (showProductAvailable) {
    lineKeys['productAvailableNoOverlap'] = {
      data: productAvailableNoOverlap?.map(({value}) => value),
      color: theme.colors.accent,
      label: 'Product Available',
      dashed: true,
    }
  }

  lines.push(
    ...Object.keys(lineKeys).map((key, index) => ({
      key: key,
      label: lineKeys[key].label,
      color: lineKeys[key].color,
      data: lineKeys[key].data,
      type: ChartType.LINE,
      stackType: StackType.SECONDARY,
      fill: false,
      dashed: lineKeys[key].dashed,
      showPoint: !lineKeys[key].dashed,
    })),
  )

  lines.push({
    key: productName,
    label: productName,
    color: productColor || chartColors.getNext(),
    data: purchase?.map(({value}) => value),
    type: ChartType.LINE,
    stackType: StackType.STACKED,
    fill: true,
  })

  return (
    <Chart
      toolbar={{
        vertical: false,
        timescale: {hideYearly: true, hideMonthly: true},
        customToolbar: (
          <Box
            width="212px"
            align="center"
            margin={{top: 2.625, left: 1}}
            pad={{left: 1, right: 1}}
            round="4px"
            border
            height="40px"
          >
            <Checkbox
              onChange={() => setShowProductAvailable(!showProductAvailable)}
              label="Show Product Available"
              checked={showProductAvailable}
              labelSize="msmall"
              labelColor={theme.colors.accent}
            />
          </Box>
        ),
      }}
      labels={labels}
      lines={lines}
      dark
      maxValue={autoCeilNumber(getMaxValue(lines))}
      minValue={autoFloorNumber(getMinValue(lines))}
    />
  )
}

export default CertDesignerChart
