import {CurrencyCode, PriceUnit} from './IPrice'
import {OfferKind, OfferState} from './IOffer'
import IParty from './IParty'
import {ResourceId} from '../types'
import {Consumption} from './INewTimeseriesItem'
import ISite, {SiteProductionTechnology} from './ISite'
import IAttachment from './IAttachment'
import {ICertificateOfferMatchedCert} from './ICertificateOffer'

export enum ContractState {
  DRAFT = 'DRAFT',
  OFFER = 'OFFER',
  SIGNED = 'SIGNED',
  ACTIVE = 'ACTIVE',
}

export enum ContractVolumeProfile {
  LOAD_FOLLOWING = 'LOAD_FOLLOWING',
  GENERATION_FOLLOWING = 'GENERATION_FOLLOWING',
  FLAT = 'FLAT',
}

interface IProductTypePrice {
  name: string
  value: number
  productTypeId: ResourceId
}

export interface IBaseContract {
  id: number
  name: string
  validFrom: string
  validTo: string
  state: ContractState
}

export interface IContractSummary {
  volumeMwh: number
  price: {
    average: number
    added: number
    subtracted: number
    total: number
    unit: PriceUnit
  }
  pricesByProductType: IProductTypePrice[]
}

export interface IBuyerContract {
  id: number
  name: string
  startDate: string
  endDate: string
  certPrice: number
  priceUnit: PriceUnit
  totalPrice: number
  purchaseInterval: {
    year: number
    month: string
  }
  state: string
  kind: string
  validFrom: string
  validTo: string
  contract: {
    id: number
    name: string
    validFrom: string
    validTo: string
    state: ContractState
    volumeMwh: number
    signingDate: string
  }
  seller: IParty
}

export interface ISellerSignedContract {
  id: number
  customer: {
    id: number
    name: string
  }
  name: string
  offer: {
    id: number
    name: string
    certPrice: number
    startDate: string
    endDate: string
    state: OfferState
    priceUnit: PriceUnit
    kind: OfferKind
    purchaseInterval: {
      year: number
      month: string
    }
    totalPrice: string
    validFrom: string
    validTo: string
  }
  signingDate: string
  site: ISite
  state: ContractState
  validFrom: string
  validTo: string
  volumeMwh: number
}

export interface ISellerSnapshottedContract {
  id: number
  listingId: number
  buyer: {
    id: number
    name: string
  }
  name: string
  offer: {
    id: number
    currency: CurrencyCode
    eacPrice: string
    name: string
    state: OfferState
    totalPrice: string
  }
  product: {
    certificatePrice: string
    description: string
    id: number
    image: IAttachment
    name: string
    quantity: number
    technology: SiteProductionTechnology
  }
  purchaseInterval: {
    month: string
    year: number
  }
  purchaseTimeseries: Consumption[]
  purchaseVolume: number
  state: ContractState
  matchedCerts: ICertificateOfferMatchedCert[]
}

export interface IPerformanceContract {
  id: number
  name: string
  price: number
  volumeMwh: number
  totalPrice: number
  averageMarketPrice: number
  totalProfit: number
  averageProfit: number
}

export interface IPerformanceContractTimeseries {
  contractId: number
  timeseries: {
    currency: CurrencyCode
    series: Consumption[]
  }
}
