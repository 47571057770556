import Box from 'components/Box'
import InnerScroll from 'components/InnerScroll'
import PeriodSelect from 'components/PeriodSelect'
import {useAtom, useSetAtom} from 'jotai'
import React from 'react'
import {generateYearsToCurrent} from 'helpers/date'
import ExternalContractChart from './ExternalContractChart'
import {IContractedVolumeTimeseries} from 'domain/Portfolio'
import {dateFilterAtom} from 'atoms/externalContracts'

interface IProps extends React.PropsWithChildren {
  contractsTimeseries: IContractedVolumeTimeseries
}

const ExternalContractProfile: React.FC<IProps> = ({contractsTimeseries}) => {
  const [dateFilter, setDateFilter] = useAtom(dateFilterAtom)

  return (
    <Box>
      <InnerScroll noRightPad>
        <PeriodSelect
          withDaySelect
          withYtd
          setValue={setDateFilter}
          setQuickLinkValue={setDateFilter}
          quickLinkValue={dateFilter}
          customYears={generateYearsToCurrent(2024)}
        />
        <Box pad={{top: 2}}>
          <ExternalContractChart contractsTimeseries={contractsTimeseries} />
        </Box>
      </InnerScroll>
    </Box>
  )
}

export default ExternalContractProfile
