import {IUnit} from './IPrice'

export enum CarbonStatus {
  OK = 'OK',
  BUYER_NO_SITES = 'BUYER_NO_SITES',
  MISSING_COUNTRY_CO2_DATA = 'MISSING_COUNTRY_CO2_DATA',
}

export const getCarbonErrorMessage = (status: CarbonStatus) => {
  switch (status) {
    case CarbonStatus.BUYER_NO_SITES:
      return 'No sites added'
    case CarbonStatus.MISSING_COUNTRY_CO2_DATA:
      return 'Missing country CO2 data'
    default:
      return null
  }
}

export interface IKPI {
  carbon?: {
    unit: keyof typeof IUnit
    yearEmissions: number
    yearSavedEmissions: number
    monthEmissions: number
    monthSavedEmissions: number
  }
  coverage: {
    hourlyPercent: number
    monthlyPercent: number
    yearlyPercent: number
  }
  carbonStatus: CarbonStatus
  targetAchievingRates?: {
    yearly: number
    monthly: number
    hourly: number
  }
  annualConsumptionMwh: number
}

export interface IKPITargets {
  hourlyPercent: number
  monthlyPercent: number
  yearlyPercent: number
}

export interface IKPITargetsPayload {
  targets: IKPITargets
}

export default IKPI
