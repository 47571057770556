import React from 'react'
import Chart, {ChartType, getMaxValue, getMinValue, ILine, StackType} from 'components/Chart'
import INewTimeseriesItem from 'domain/INewTimeseriesItem'
import {autoCeilNumber, autoFloorNumber} from 'helpers/misc'
import useTheme from 'hooks/useTheme'
import {capitalize} from 'lodash-es'

interface IProps extends React.PropsWithChildren {
  certData: INewTimeseriesItem
}

const getLineLabel = (key: string) => {
  if ('missing' === key) {
    return 'Shortfall'
  }

  if ('excess' === key) {
    return 'Excess'
  }

  return capitalize(key)
}

const CertificatesChart: React.FC<IProps> = ({certData}) => {
  const theme = useTheme()
  const {missing, excess, consumption, totalProduction} = certData || {}

  if (!certData) {
    return null
  }

  const covered = totalProduction.map((item, index) => ({
    name: item.name,
    value: item.value - (excess[index]?.value || 0),
  }))
  const excessAndCovered = totalProduction
  const shortfallAndCovered = covered.map((item, index) => ({
    name: item.name,
    value: item.value + (missing[index]?.value || 0),
  }))

  const dataInOrder = {
    covered: {value: covered, color: theme.colors.green0},
    missing: {value: shortfallAndCovered, color: theme.colors.grey0},
    excess: {
      value: excessAndCovered,
      color: theme.colors.yellow2,
    },
  }

  const handleTooltipLabel = (tooltipItem: any, data: any) => {
    const {yLabel: value, datasetIndex, index} = tooltipItem || {}
    const {datasets} = data || {}
    const {label: datasetLabel} = datasets[datasetIndex] || {}

    if (datasetLabel === 'Excess') {
      const calculatedValue = excess[index]?.value || 0

      return `${datasetLabel}: ${calculatedValue}`
    }

    if (datasetLabel === 'Shortfall') {
      const calculatedValue = missing[index]?.value || 0

      return `${datasetLabel}: ${calculatedValue}`
    }

    return `${datasetLabel}: ${value}`
  }

  const labels = consumption.map(({name}) => name) || []

  const lines: ILine[] = []

  lines.push({
    key: 'total',
    label: 'PPA & EAC',
    color: theme.colors.green7,
    data: totalProduction.map(({value}) => value),
    type: ChartType.LINE,
    stackType: StackType.SECONDARY,
    showPoint: true,
    fill: false,
  })

  lines.push({
    key: 'consumption',
    label: 'Consumption',
    color: theme.colors.blue7,
    data: consumption.map(({value}) => value),
    type: ChartType.LINE,
    stackType: StackType.SECONDARY,
    showPoint: true,
    fill: false,
  })

  lines.push(
    ...Object.keys(dataInOrder).map((key, index) => ({
      key: key,
      label: getLineLabel(key),
      color: dataInOrder[key].color,
      data: dataInOrder[key].value.map(({value}) => value),
      type: ChartType.BAR,
      stackType: StackType.DEFAULT,
      order: index,
    })),
  )

  return (
    <Chart
      toolbar={{vertical: false, timescale: {hideYearly: true, hideMonthly: true}}}
      labels={labels}
      lines={lines}
      dark
      maxValue={autoCeilNumber(getMaxValue(lines))}
      minValue={autoFloorNumber(getMinValue(lines))}
      tooltipMode="index"
      tooltipLabelCallback={handleTooltipLabel}
    />
  )
}

export default CertificatesChart
