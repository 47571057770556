import {useEffect} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import useQueryObject from 'hooks/useQueryObject'
import {useAtom} from 'jotai'
import {periodAtom, PeriodType, yearAtom} from 'components/PeriodSelect/periodSelect'
import {getPrevMonthYear} from 'helpers/date'

const usePeriodUrlParams = (setValue: ({month, year}: {month: string; year: number}) => void) => {
  const queryObject = useQueryObject()
  const [selected, setSelected] = useAtom(periodAtom)
  const [year, setYear] = useAtom(yearAtom)
  const location = useLocation()
  const history = useHistory()
  const urlSearchParams = new URLSearchParams(location.search)

  useEffect(() => {
    if (queryObject?.period) {
      setSelected({period: queryObject?.period, value: queryObject?.value})
      setValue({month: queryObject?.value, year: +queryObject.year || getPrevMonthYear()})
    }
    if (queryObject?.year) {
      setYear(+queryObject.year)
    }
  }, [])

  useEffect(() => {
    if (selected.period === PeriodType.MONTH) {
      urlSearchParams.set('period', selected?.period)
      urlSearchParams.set('value', selected?.value)
      urlSearchParams.set('year', `${year}`)
    } else {
      urlSearchParams.delete('period')
      urlSearchParams.delete('value')
      urlSearchParams.delete('year')
    }

    history.push(`?${urlSearchParams.toString()}`)
  }, [selected])

  useEffect(() => {
    if (year) {
      urlSearchParams.set('year', `${year}`)
    } else {
      urlSearchParams.delete('year')
    }
    history.push(`?${urlSearchParams.toString()}`)
  }, [year])
}

export default usePeriodUrlParams
