import IParty, {OnboardingStatus, PartyRole, ISubscriptionPlan} from '../../domain/IParty'
import mockAttachments from './mockAttachments'
import mockLocations from './mockLocations'

export const mockBrokerParty: IParty = {
  id: 1,
  name: 'BROKER_PARTY_1',
  partyRole: PartyRole.BROKER,
  location: mockLocations[0],
  image: null,
  onboardingStatus: OnboardingStatus.COMPLETED,
  subscriptionPlan: ISubscriptionPlan.ENTERPRISE,
}

export const mockProducerParty: IParty = {
  id: 2,
  partyRole: PartyRole.PRODUCER,
  name: 'PRODUCER_PARTY_2',
  location: null,
  image: null,
  onboardingStatus: OnboardingStatus.COMPLETED,
  subscriptionPlan: ISubscriptionPlan.ENTERPRISE,
}

export const mockBuyerParty1: IParty = {
  id: 3,
  partyRole: PartyRole.BUYER,
  name: 'BUYER_PARTY_3',
  image: null,
  onboardingStatus: OnboardingStatus.COMPLETED,
  subscriptionPlan: ISubscriptionPlan.ENTERPRISE,
}

export const mockBuyerParty2: IParty = {
  id: 4,
  partyRole: PartyRole.BUYER,
  name: 'BUYER_PARTY_4',
  image: null,
  onboardingStatus: OnboardingStatus.COMPLETED,
  subscriptionPlan: ISubscriptionPlan.ENTERPRISE,
}

const mockParties: IParty[] = [mockBuyerParty1, mockBuyerParty2, mockBrokerParty, mockProducerParty]

export default mockParties
