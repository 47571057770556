import React from 'react'
import useLocalization from '../../hooks/useLocalization'
import Box from '../Box'
import useTheme from '../../hooks/useTheme'
import Grid from '../Grid'
import styled from 'styled-components'
import InnerScroll from '../InnerScroll'
import RouterModal from '../Modal/RouterModal'
import ModalHeader from '../Modal/ModalHeader'
import {ModalType} from '../Modal/IModal'
import Heading from 'components/Heading'
import Text from 'components/Text'

const StyledGrid = styled(Grid)`
  overflow: hidden;
`

const CookiesModal: React.FC<React.PropsWithChildren> = () => {
  const {translate} = useLocalization()
  const theme = useTheme()

  return (
    <RouterModal name={ModalType.COOKIES} full>
      <ModalHeader title={translate('Cookie policy')} description={translate('Overview of cookie policy')} />
      <StyledGrid>
        <InnerScroll noRightPad>
          <Box pad={2} border round>
            <Heading size="large" pad={{bottom: 4}}>
              Renewabl Ltd Cookies Policy 2025
            </Heading>
            <Text align="left" size="mlarge">
              This policy explains how Renewabl Ltd uses cookies and other tracking technologies through our website{' '}
              <a target="_blank" rel="noreferrer" href={'https://www.renewabl.com'}>
                www.renewabl.com.
              </a>{' '}
              This policy should be read in conjunction with our Privacy Policy. If you do not accept our use of cookies
              please disable them following our guidance below. What are web cookies? Web cookies are small files that
              are placed on your computer or mobile device by a website when you visit it. They contain details of your
              browsing history on that website and distinguish you from other users. Cookies send data back to the
              originating website on each subsequent visit or allow another website to recognise the cookie. Cookies are
              useful because they allow a website to recognise a user’s device and, for instance, remember your
              preferences and generally improve your online user experience. Like most organisations, Renewabl Ltd uses
              cookies. Although this policy refers to the general term “cookie”, which is the main method used by this
              website to store information, the browser’s local storage space is also used for the same purpose and we
              may use other tracking technologies through this website such as web beacons, tags and scripts. As a
              result, the information included in this policy is likewise applicable to all such tracking technologies
              that we use. You can find out more about cookies at{' '}
              <a target="_blank" rel="noreferrer" href={'https://www.allaboutcookies.org'}>
                www.allaboutcookies.org.
              </a>
            </Text>
            <Heading size="medium" pad={{top: 4}}>
              Why do we use cookies?
            </Heading>
            <Text align="left" size="mlarge">
              <ul>
                <li>
                  To let you do things on our website - for example, cookies enable you to log in to secure areas of our
                  website.
                </li>
                <li>
                  To collect anonymous statistics - the information collected by cookies enables us to improve the
                  website through usage figures and patterns. For example, it is really useful to see which pages of the
                  website - and therefore which of our services - are the most popular and how users are interacting
                  with them.
                </li>
                <li>
                  To improve your experience of our website - for example, to prevent you having to re-enter details
                  when you have already done so, or by ensuring that users can find what they are looking for easily.
                </li>
              </ul>
              Below we provide more information about the main cookies we use and why.
            </Text>
            <Heading size="medium" pad={{top: 4, bottom: 2}}>
              Session Cookies
            </Heading>
            <Text align="left" size="mlarge">
              They allow us to link the actions of users during a single browser session to allow them to use the
              Website more efficiently. For example, session cookies enable a website to remember that a user has placed
              items in an online shopping basket.
            </Text>
            <Heading size="medium" pad={{top: 4, bottom: 2}}>
              Preference Cookies
            </Heading>
            <Text align="left" size="mlarge">
              They are used to recognise users when they return to the Website. They enable the personalisation of
              content, the recognition of users and remember user preferences (for example, their choice of language or
              region). They may also be used to track a user&apos;s browsing activity across different websites and to
              build up a detailed profile of their browsing behaviour for targeting advertising.
            </Text>
            <Heading size="medium" pad={{top: 4, bottom: 2}}>
              Turning cookies off
            </Heading>
            <Text align="left" size="mlarge">
              Internet browsers normally accept cookies by default. However, most browsers let your turn off either all
              or third-party cookies. What you are able to do depends on which browser you are using. If you do not
              accept our use of cookies as set out above, please set your browser to reject cookies. However, please be
              aware that this may impair or limit your ability to use our website. The option to do this is usually
              found in the options, settings or preferences menu of your browser or mobile device. You may opt-out of
              Google&apos;s analytics cookies by visiting Google&apos;s opt-out page -
              <a href="https://tools.google.com/dlpage/gaoptout"> https://tools.google.com/dlpage/gaoptout</a>. Click on
              the links below to find out how to change your cookie settings in: <br />
              <a
                target="_blank"
                rel="noreferrer"
                href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies"
              >
                Internet explorer
              </a>
              <br />
              <a
                target="_blank"
                rel="noreferrer"
                href="https://support.mozilla.org/en-US/products/firefox/basic-browsing-firefox"
              >
                Firefox
              </a>
              <br />
              <a target="_blank" rel="noreferrer" href="https://support.google.com/chrome/answer/95647?hl=en">
                Chrome
              </a>
              <br />
              <a target="_blank" rel="noreferrer" href="https://support.apple.com/kb/PH21411?locale=en_US">
                Safari
              </a>
              <br />
              <a target="_blank" rel="noreferrer" href="https://www.opera.com/o/ie-simple">
                Opera
              </a>
              <br />
              <a target="_blank" rel="noreferrer" href="https://support.apple.com/en-gb/HT201265">
                iPhone and iPad
              </a>
              <br />
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.samsung.com/uk/support/mobile-devices/what-are-cookies-and-how-do-i-enable-or-disable-them-on-my-samsung-galaxy-device/"
              >
                Samsung
              </a>
            </Text>
            <Heading size="medium" pad={{top: 4, bottom: 2}}>
              Updating our Cookies Policy
            </Heading>
            <Text align="left" size="mlarge">
              We may update our use of cookies from time to time and consequently we may update this policy. We
              therefore recommend that you check this policy regularly. If you have not already, we recommend you check
              with your developers as to the exact nature of the cookies used. This is the company who owns/controls the
              cookies used. For example, Google owns Google Analytics.
            </Text>
          </Box>
        </InnerScroll>
      </StyledGrid>
    </RouterModal>
  )
}

export default CookiesModal
