import React from 'react'
import NotFound from '../components/NotFound'
import Box from '../components/Box'
import Logo from '../components/Logo'
import LogoutButton from 'components/LogoutButton'
import PartySelect from 'components/Platform/Layout/PartySelect'
import {userAtom} from 'atoms/party'
import {useAtomValue} from 'jotai'
import useLocalization from 'hooks/useLocalization'

const PartyNotFound: React.FC<{partyId: string}> = ({partyId}) => {
  const {translate} = useLocalization()
  const user = useAtomValue(userAtom)

  return (
    <Box margin={4} align="center" direction="column">
      <Logo to="/" />
      <Box margin={{top: 4}}>
        <NotFound>{translate(`Company ${partyId} does not exist or you are not a member of it`)}</NotFound>
      </Box>
      {user && (
        <Box width="150px;" align="center" justify="center" direction="column" gap={2}>
          <PartySelect />
          <LogoutButton />
        </Box>
      )}
    </Box>
  )
}

export default PartyNotFound
