import {rootServiceAtom} from 'atoms/general'
import Box from 'components/Box'
import Button from 'components/Button'
import Input from 'components/Input'
import ModalHeader from 'components/Modal/ModalHeader'
import KeyFeature from 'components/Platform/KeyFeature'
import TechnologyIconAndName from 'components/Platform/TechnologyIconAndName'
import {SellableSummary, SellExcessPayload} from 'domain/INewTimeseriesItem'
import {PriceUnit} from 'domain/IPrice'
import {IRecommendedExcessPrice} from 'domain/ISellExcessRequest'
import {getChangeEventValue} from 'helpers/misc'
import {getPriceUnitSymbol} from 'helpers/price'
import useLocalization from 'hooks/useLocalization'
import useProfile from 'hooks/useProfile'
import {useAtomValue} from 'jotai'
import React, {useContext, useEffect, useState} from 'react'
import RouterModal, {RouterModalContext} from 'components/Modal/RouterModal'

export const MODAL_NAME = 'modal.excessCertificates'

interface IProps extends React.PropsWithChildren {
  onSell: (payload: SellExcessPayload) => void
  excess: SellableSummary[]
}

const ModalBody = ({onSell, excess}: IProps) => {
  const [price, setPrice] = useState()
  const [recommendedPrice, setRecommendedPrice] = useState<IRecommendedExcessPrice>()
  const {party} = useProfile()
  const {translate} = useLocalization()
  const {handleClose, value} = useContext(RouterModalContext)
  const currentExcess = excess?.find(item => item.technologyKind === value)
  const currency = recommendedPrice?.currency || 'GBP'
  const rootService = useAtomValue(rootServiceAtom)
  const {v2ContentService} = rootService

  const handleSell = () => {
    onSell({
      certPrice: price,
      quantity: currentExcess.certificateQuantity,
      technologyKind: currentExcess.technologyKind,
    })
    handleClose()
  }

  const handlePriceChange = event => {
    const value = getChangeEventValue(event)
    setPrice(value)
  }

  useEffect(() => {
    const getRecommendedPrice = async () => {
      const recommendedPrice = await v2ContentService.getPriceRecommendation(party.id, currentExcess.technologyKind)
      setRecommendedPrice(recommendedPrice)
    }

    if (currentExcess) {
      getRecommendedPrice()
    }
  }, [currentExcess])

  return (
    <Box direction="column">
      <ModalHeader title={translate('Sell excess certificates')} />
      <Box direction="column" gap={2}>
        <Box direction="row" gap={4}>
          <KeyFeature title={translate('Quantity')} unit={'EAC'} value={currentExcess?.certificateQuantity} />

          <KeyFeature
            title={translate('Technology')}
            value={
              <Box margin={0.8}>
                <TechnologyIconAndName technology={currentExcess?.technologyKind} />
              </Box>
            }
          />
        </Box>
        <KeyFeature
          title={translate('Suggested price')}
          value={recommendedPrice?.price}
          unit={'/ CERTIFICATE'}
          currency={getPriceUnitSymbol(PriceUnit[`${currency}_MWH`])}
        />
        <Input value={price} onChange={handlePriceChange} label={translate('Price')} suffix={'£ / CERTIFICATE'} />
        {price && (
          <KeyFeature
            title={translate('Total price')}
            value={price * currentExcess?.certificateQuantity}
            currency={getPriceUnitSymbol(PriceUnit[`${currency}_MWH`])}
          />
        )}

        <Button onClick={handleSell} fullWidth disabled={!price}>
          {translate('Sell')}
        </Button>
      </Box>
    </Box>
  )
}

const ExcessModal: React.FC<IProps> = ({onSell, excess}) => {
  return (
    <RouterModal style={{width: 600}} name={MODAL_NAME}>
      <ModalBody onSell={onSell} excess={excess} />
    </RouterModal>
  )
}

export default ExcessModal
