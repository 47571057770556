import React from 'react'
import useLocalization from '../../../hooks/useLocalization'
import ResourceGateway from 'components/ResourceGateway'
import ListingsPage from 'components/Platform/ListingsPage'

const Marketplace: React.FC<React.PropsWithChildren> = () => {
  const {translate} = useLocalization()

  return (
    <ResourceGateway
      list={() => (
        <ListingsPage title={translate('Marketplace')} description={translate('Browse the listings offered')} />
      )}
    />
  )
}

export default Marketplace
