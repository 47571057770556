import React, {useEffect, useState, forwardRef} from 'react'
import {useLocation} from 'react-router-dom'
import {useForm} from 'react-hook-form'
import ValidationError from '../../exceptions/ValidationError'
import FormSubmitted from './FormSubmitted'
import {queryStringToNestedObject} from '../../helpers/misc'
import {merge} from 'lodash-es'
import {FormContext, IMeta} from '../../hooks/useFormContext'
import ApiError from 'exceptions/ApiError'
import useLocalization from 'hooks/useLocalization'
import useAlerts from 'hooks/useAlerts'

interface IProps extends React.PropsWithChildren {
  onSubmit: (data: Record<string, any>, options: {meta: IMeta; setError}) => any | Promise<any>
  defaultValues?: Record<string, any>
  submissionFeedback?: React.ReactNode
  fillHeight?: boolean
  fillWidth?: boolean
  resetOnSubmit?: boolean
  validationMode?: 'onBlur' | 'onChange' | 'onSubmit'
}

const Form = forwardRef<HTMLFormElement, IProps>(
  (
    {
      children,
      onSubmit,
      defaultValues,
      submissionFeedback = <FormSubmitted />,
      fillHeight,
      fillWidth,
      resetOnSubmit,
      validationMode = 'onSubmit',
    },
    ref,
  ) => {
    const [meta, setMeta] = useState<IMeta>({})
    const {addError} = useAlerts()
    const {translate} = useLocalization()
    const [successfullySubmitted, setSuccessfullySubmitted] = useState<boolean>(false)

    // Include query string values as base default values
    const location = useLocation()
    const queryStringValues = location.search && queryStringToNestedObject(location.search)

    const methods = useForm({
      defaultValues: merge({}, queryStringValues, defaultValues),
      mode: validationMode,
    })
    const {handleSubmit, setError, reset} = methods

    useEffect(() => {
      reset(defaultValues)
    }, [reset, defaultValues])

    const handleFormSubmit = async data => {
      try {
        await onSubmit(data, {meta, setError})

        if (resetOnSubmit) {
          reset(defaultValues)
        }
        if (submissionFeedback) {
          setSuccessfullySubmitted(true)
        }
      } catch (error) {
        console.error(error)
        if (error instanceof ValidationError) {
          error.validation.forEach(({key, message}) => {
            setError(key, 'submitValidation', message)
          })
        } else if (error instanceof ApiError && error.message) {
          addError(error.message, error.correlationId)
        } else {
          addError(translate('Something went wrong'))
        }
      }
    }

    if (successfullySubmitted) {
      return <div>{submissionFeedback}</div>
    }

    return (
      <form
        ref={ref}
        onSubmit={handleSubmit(handleFormSubmit)}
        style={{
          height: fillHeight ? '100%' : null,
          width: fillWidth ? '100%' : null,
        }}
      >
        <FormContext.Provider value={{setMeta, meta, ...methods}}>{children}</FormContext.Provider>
      </form>
    )
  },
)

export default Form
