import {ILocationSearch} from 'components/Platform/Portfolio/Site/LocationSearch'
import {CurrencyCode} from './IPrice'
import {SiteProductionTechnology, SiteState, TimeseriesKind} from './ISite'
import {CertSchemeType} from './ICertificateOffer'

interface Certificates {
  start: string
  end: string
  certScheme: CertSchemeType
}

export enum MarketListingKind {
  NEW = 'NEW',
  EXISTING = 'EXISTING',
}

export interface INewMarketListing {
  product: QuickProduct
  listingDetails: IListingDetails
}

export interface IMarketListingTimeseries {
  kind: TimeseriesKind
  payload: {
    attachmentId?: number
    hourlyVolumes?: {
      hour: number
      volumeMwh: number
    }[]
  }
}

export type QuickProduct =
  | {kind: MarketListingKind.NEW; payload: INewQuickProduct}
  | {kind: MarketListingKind.EXISTING; payload: {id: number}}

export interface INewQuickProduct {
  name: string
  description: string

  imgAttachmentId: number
  site:
    | {kind: MarketListingKind.NEW; payload: INewQuickSite}
    | {kind: MarketListingKind.EXISTING; payload: {id: number}}
}

export interface INewQuickSite {
  name: string
  technology: SiteProductionTechnology
  state: SiteState
  prodCapacityMwac: number
  operationsStart: string
  lifetimeYears: number
  meterReference: string
  placeId: string
}

export interface IListingDetails {
  price: number
  firstCertificate: string
  lastCertificate: string
  timeseries: IMarketListingTimeseries
  interval: {
    year: number
    month: string
  }
  volumePercent: number
}

export interface IMarketListing {
  id: number
  productId: number
  productName: string
  certificateId: number
  startDate: string
  certPrice: number
  currency: CurrencyCode
  totalQuantity: number
  soldQuantity: number
  availableQuantity: number
}

export interface IMarketListingReport {
  hourly: {
    contracts: {
      id: number
      certs: Certificates
    }[]
    gap: Certificates
    total: Certificates
    timestamp: string
  }[]
}

export interface IMarketListingUpdatePayload {
  certPrice: number
}

//TODO replace old cert generation site payload with new one once api is ready
export interface IGenerateTestCertsPayload {
  product:
    | {
        kind: MarketListingKind.NEW
        payload: {
          site: CertSitePayload
        }
      }
    | {kind: MarketListingKind.EXISTING; payload: {id: number}}
  interval: {
    year: number
    month: string
  }
  timeseries: IMarketListingTimeseries
}

export type CertSitePayload =
  | {kind: MarketListingKind.NEW; payload: NewCertSite}
  | {kind: MarketListingKind.EXISTING; payload: IdPayload}

export interface IdPayload {
  id: number
}

export interface NewCertSite {
  technology: SiteProductionTechnology
  placeId: string
}

export interface ICertificateFormData {
  listing: {
    price: string
    firstCertificate: string
    lastCertificate: string
  }
}

export interface ISelectProductFormData {
  product: {
    id?: string
    name?: string
    description?: string
    imgAttachment?: {
      id: number
    }
  }
  site?: {
    id?: string
    name?: string
    technology?: SiteProductionTechnology
    state?: SiteState
    prodCapacityMwac?: number
    operationsStart?: string
    lifetimeYears?: number
    meterReference?: string
    placeId?: string
  }
  location?: ILocationSearch
}

export interface IUploadGenerationFormData {
  interval: {
    month: string
    year: string
  }
  generation: {
    builder?: {
      hourlyVolumes?: {
        hour: number
        volumeMwh: number
      }[]
    }
    profileAttachment?: {
      id: number
    }
    volumePercent: string
  }
}
