import {PrimitiveAtom, atom} from 'jotai'
import {atomWithCleanup, atomWithCompare, loadable} from './utils'
import {partyAtom} from './party'
import {intervalAtom, rootServiceAtom} from './general'
import {atomWithRefresh} from 'jotai/utils'
import {
  getCurrentMonth,
  getCurrentYear,
  getMonthNumber,
  getPrevMonth,
  getPrevMonthYear,
  lastDayOfMonth,
} from 'helpers/date'
import dayjs from 'dayjs'

const DEFAULT_PAGE_SIZE = 25
const DEFAULT_PAGE = 1

export const pageAtom = atom(DEFAULT_PAGE)
export const pageSizeAtom = atom(DEFAULT_PAGE_SIZE)

export const externalContractIdAtom = atomWithCleanup<string>(null) as PrimitiveAtom<string>

export const externalContractsDateFilter = {
  startMonth: getPrevMonth(),
  startYear: getPrevMonthYear(),
  startDay: null,
  endMonth: getPrevMonth(),
  endYear: getPrevMonthYear(),
  endDay: null,
}

export const dateFilterAtom = atomWithCompare(externalContractsDateFilter)

dateFilterAtom.onMount = setAtom => {
  return () => setAtom(externalContractsDateFilter)
}

export const externalContractsAtom = loadable(
  atomWithRefresh(async get => {
    const services = get(rootServiceAtom)
    const party = get(partyAtom)
    const page = get(pageAtom)
    const pageSize = get(pageSizeAtom)

    const data = await services.v2ContentService.getExternalContracts(party?.id, page, pageSize)

    return data
  }),
)

export const externalContractAtom = loadable(
  atom(async get => {
    const services = get(rootServiceAtom)
    const party = get(partyAtom)
    const contractId = get(externalContractIdAtom)

    if (!contractId) {
      return null
    }

    const data = await services.v2ContentService.getExternalContract(party?.id, contractId)

    return data
  }),
  {nullIsLoading: true},
)

export const externalContractTimeseriesAtom = loadable(
  atom(async get => {
    const services = get(rootServiceAtom)
    const party = get(partyAtom)
    const contractId = get(externalContractIdAtom)
    const interval = get(intervalAtom)

    if (!contractId) {
      return null
    }

    const filter = get(dateFilterAtom)
    const endMonthNr = getMonthNumber(filter.endMonth)
    const isCurrentMonth = endMonthNr === getCurrentMonth()
    const endDay =
      isCurrentMonth && filter.endYear === getCurrentYear()
        ? dayjs().date() - 1
        : lastDayOfMonth(endMonthNr + 1, filter.endYear)
    const startDate = `${filter.startYear}-${getMonthNumber(filter.startMonth) + 1}-${filter.startDay || '1'}T00:00:00`
    const endDate = `${filter.endYear}-${endMonthNr + 1}-${filter.endDay || endDay}T23:00:00`

    const data = await services.v2ContentService.getExternalContractTimeseries(
      party?.id,
      contractId,
      interval,
      startDate,
      endDate,
    )

    return data
  }),
  {
    nullIsLoading: true,
  },
)
